import BaseService from './BaseService';

export default class ScimService extends BaseService {
  async findSettings() {
    return await this.host.api.scim.findSettings();
  }

  async findAvailableSCIMFields() {
    return await this.host.api.scim.findAvailableSCIMFields();
  }
}

import _ from 'lodash';
import { decorator as reusePromise } from 'reuse-promise';
import * as errors from '../errors';
import { GroupType, SearchType } from '../models/enums';
import { SearchTypeFromServer } from '../models/enums/SearchType';
import SearchResult, { EntitySkeleton } from '../models/SearchResult';
import { PROFILE_ATTRIBUTES_PER_ORG } from '../models/User';
import { SearchParityResponse } from '../apis/SearchAPI';
import { Camelizer, arrayWrap, replaceKey } from '../utils';
import { isEmail } from '../utils/email';
import { formatCountryCodePhoneNumber, isPhone } from '../utils/phone';
import { VersionNumber } from '../apis/BaseAPI';
import BaseService from './BaseService';

const DEFAULT_RETURN_FIELDS = [
  'activation_time',
  'avatar',
  'conversation_id',
  'department',
  'description',
  'display_name',
  'dnd',
  'dnd_auto_forward_entities',
  'dnd_auto_forward_receiver',
  'dnd_text',
  'first_name',
  'group_token',
  'is_external',
  'is_public',
  'last_login_time',
  'last_name',
  'members',
  'name',
  'network',
  'num_members',
  'organization_key',
  'pagers',
  'patient_context_dob',
  'patient_context_first_name',
  'patient_context_gender',
  'patient_context_id',
  'patient_context_last_name',
  'patient_context_mrn',
  'phones',
  'presence_status',
  'status',
  'tags',
  'title',
  'token',
  'total_members',
  'username',
] as const;

const ORG_RETURN_FIELDS = [
  'billing',
  'created_date',
  'email_domains',
  'name',
  'org_type',
  'token',
  'total_members',
] as const;

const COLLABORATION_RETURN_FIELDS = ['color', 'color_value', 'role_count', 'team_count'] as const;

const ALL_RETURN_FIELDS = [
  ...DEFAULT_RETURN_FIELDS,
  ...ORG_RETURN_FIELDS,
  ...COLLABORATION_RETURN_FIELDS,
] as const;

type ReturnField = typeof ALL_RETURN_FIELDS[number];

const MAP_SEARCH_TYPE_TO_SERVER_TYPE = {
  careTeam: SearchType.toServer(SearchType.CARE_TEAM),
  distributionList: SearchType.toServer(SearchType.DISTRIBUTION_LIST),
  distributionListMember: SearchType.toServer(SearchType.DISTRIBUTION_LIST_MEMBER),
  distributionListShared: SearchType.toServer(SearchType.DISTRIBUTION_LIST_SHARED),
  forum: SearchType.toServer(SearchType.FORUM),
  group: SearchType.toServer(SearchType.GROUP),
  messageTemplate: SearchType.toServer(SearchType.MESSAGE_TEMPLATE),
  organization: SearchType.toServer(SearchType.ORGANIZATION),
  patient_account: SearchType.toServer(SearchType.PATIENT),
  patientDistributionList: SearchType.toServer(SearchType.PATIENT_DISTRIBUTION_LIST),
  scheduledMessage: SearchType.toServer(SearchType.SCHEDULED_MESSAGE),
  tag: SearchType.toServer(SearchType.TAG),
  team: SearchType.toServer(SearchType.TEAM),
  user: SearchType.toServer(SearchType.USER),
  individual: SearchType.toServer(SearchType.INDIVIDUAL),
  role: SearchType.toServer(SearchType.ROLE),
} as const;

type SearchType = keyof typeof MAP_SEARCH_TYPE_TO_SERVER_TYPE;

type Query = Record<string, string>;

type BoolQuery = { must?: Record<string, string>; must_not?: Record<string, string> };

type SearchRequestParams = {
  bool?: BoolQuery;
  color?: unknown[];
  continuation?: unknown;
  directory?: string[];
  enabled_capabilities?: unknown[];
  exclude?: Record<string, string>;
  feed_level?: string;
  filter_type?: unknown;
  include_disabled?: boolean;
  include_total?: unknown[];
  include_untagged?: unknown[];
  is_tagged?: string | boolean;
  name?: string;
  page_size?: string;
  pin_on_top?: Record<string, string>;
  render_metadata?: boolean;
  results_format: 'entities' | 'tokens';
  return_fields?: ReturnField[];
  search_text?: string;
  sort?: string[];
  tag_ids?: unknown[];
  type?: string[] | string;
};

type ServerQuery = Record<string, string>;

type ConversationSkeleton = {
  counterParty: EntitySkeleton;
  counterPartyType: string;
  metadata: SearchMetadata;
};
type OrganizationSkeleton = { id: string; conversations: ConversationSkeleton[] };

type SearchMetadata = {
  entityOrder?: string[];
  firstHit: number;
  totalHits: number;
  continuation?: string;
};

const VALID_SERVER_SEARCH_TYPES = Object.values(MAP_SEARCH_TYPE_TO_SERVER_TYPE);

type BaseQueryOptions = {
  bool?: BoolQuery;
  colors?: unknown[];
  continuation?: string | null;
  enabledCapabilities?: unknown[];
  excludeEmptyLists?: boolean;
  excludeIds?: string[];
  excludeReturnFields?: boolean;
  excludeSelf?: boolean;
  feedLevel?: string;
  filterType?: unknown;
  followContinuations?: boolean;
  includeDisabled?: boolean;
  includeTotal?: unknown[];
  includeUntagged?: unknown[];
  isNewAdminSearch?: boolean;
  isShareBLSearch?: boolean;
  isTagged?: string | boolean;
  name?: string;
  network?: 'provider' | 'patient';
  organizationId?: string | null;
  organizationIds?: string[];
  pageSize?: string;
  pinOnTop?: {
    patient_context_ids?: string;
  };
  query?: Query;
  resultsFormat?: 'entities' | 'ids' | 'tokens';
  returnFields?: ReturnField[];
  sort?: string[];
  tags?: unknown[];
  types?: string[];
};

type LegacyQueryOptions = {
  version?: 'LEGACY';
} & BaseQueryOptions;

type SearchParityOptions = {
  version: 'SEARCH_PARITY';
  searchText?: string;
} & BaseQueryOptions;

export type SearchQueryOptions = LegacyQueryOptions | SearchParityOptions;

export default class SearchService extends BaseService {
  @reusePromise()
  async query<Result>(
    options: SearchQueryOptions
  ): Promise<{ results: Result[]; metadata: SearchMetadata }> {
    const {
      bool,
      colors = [],
      continuation = null,
      enabledCapabilities = [],
      excludeEmptyLists = false,
      excludeReturnFields = false,
      excludeSelf = false,
      filterType = null,
      followContinuations = false,
      pinOnTop = {
        patient_context_ids: '',
      },
      includeDisabled = false,
      includeTotal = [],
      includeUntagged = [],
      isNewAdminSearch = false,
      isShareBLSearch = false,
      isTagged,
      name,
      network = 'provider',
      organizationId = null,
      pageSize,
      query = {},
      returnFields = DEFAULT_RETURN_FIELDS,
      sort = [],
      tags = [],
      version: versionType,
    } = options;
    let { excludeIds = [], organizationIds = [], resultsFormat = 'entities', types = [] } = options;

    const versionNumber = versionType === 'SEARCH_PARITY' ? 'VERSION_EIGHT' : undefined;

    if (versionNumber !== 'VERSION_EIGHT') {
      types = types.filter((type) => type !== SearchTypeFromServer.care_team);
    }

    if (types.length === 0) {
      throw new errors.ValidationError('types', 'required', 'no types specified');
    }

    types = _.uniq(
      types.map((type) =>
        SearchType.isValid(type)
          ? SearchType.toServer(type)
          : MAP_SEARCH_TYPE_TO_SERVER_TYPE[type as SearchType]
      )
    );

    const invalidTypes = _.difference(types, VALID_SERVER_SEARCH_TYPES);

    if (invalidTypes.length > 0) {
      throw new errors.ValidationError(
        'types',
        'invalid',
        `invalid types: ${invalidTypes.join(', ')}`
      );
    }

    if (organizationId) organizationIds = [organizationId];
    if (organizationIds.length === 0) {
      throw new errors.ValidationError(
        'organizationIds',
        'invalid',
        'search must be done in a context of an organization'
      );
    }

    if (organizationIds.length === 1) {
      const organization = this.host.models.Organization.get(organizationId);
      if (organization && organization.isContacts) {
        const { results, metadata } = this._queryLocalOrganization({ organization, query, types });

        if (versionNumber === 'VERSION_EIGHT') {
          if (results) {
            const entityTypes = results.map((entity) => entity.entityType);
            metadata.entityOrder = Array.from(new Set(entityTypes));
          }
        }
        return { results: results as unknown as Result[], metadata };
      }
    }

    // TODO: Unused
    if (resultsFormat === 'ids') resultsFormat = 'tokens';
    excludeIds = arrayWrap(excludeIds);

    const params: SearchRequestParams = {
      directory: organizationIds,
      include_disabled: includeDisabled,
      render_metadata: true,
      results_format: resultsFormat,
      return_fields: returnFields as ReturnField[],
      type: types,
    };

    if (enabledCapabilities.length) {
      params['enabled_capabilities'] = enabledCapabilities;
    }

    if (pinOnTop.patient_context_ids?.length) {
      params['pin_on_top'] = pinOnTop;
    }

    if (excludeReturnFields) {
      delete params['return_fields'];
    }

    if (filterType) {
      params['filter_type'] = filterType;
    }

    if (name) {
      params['name'] = name;
    }

    if (sort && sort.length) {
      params['sort'] = sort.map((entry) => Camelizer.underscoreKey(entry));
    } else if (typeof sort === 'object') {
      params['sort'] = sort;
    }

    if (params.type?.includes('schedule_message') && options.feedLevel) {
      params['feed_level'] = options.feedLevel;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (!isNaN(parseInt(pageSize!))) {
      params['page_size'] = pageSize;
    }

    // exclude_tokens currently not working: https://tigertext.atlassian.net/browse/TS-1946
    // if (excludeIds.length) params['exclude_tokens'] = excludeIds

    let serverQuery: ServerQuery = {};
    if (query && Object.keys(query).length > 0) {
      for (const [key, value] of Object.entries(query)) {
        if (key.includes(',')) {
          const serverKeys = key.split(',').map(Camelizer.underscoreKey).join(',');
          serverQuery[serverKeys] = value;

          delete query[key];
        }
      }

      serverQuery = { ...serverQuery, ...Camelizer.underscoreObject(query) };
      params['bool'] = isNewAdminSearch ? bool : { must: serverQuery };
    }

    if (
      excludeEmptyLists &&
      (types.includes('distribution_list') || types.includes('patient_distribution_list'))
    ) {
      params['bool'] = Object.assign({}, params['bool'], {
        must_not: {
          total_members: '0',
        },
      });
    }

    if (isShareBLSearch) {
      params['bool'] = Object.assign({}, params['bool'], {
        must_not: {
          roles_in_org: 'admin OR provider_broadcast_list_admin OR tt_admin OR tt_super_admin',
        },
      });
    }

    if (colors.length) {
      params['color'] = colors;
    }

    if (tags.length) {
      params['tag_ids'] = tags;
    }

    if (!_.isUndefined(isTagged)) {
      params['is_tagged'] = isTagged;
    }

    if (includeTotal.length) {
      params['include_total'] = includeTotal;
    }

    if (includeUntagged.length) {
      params['include_untagged'] = includeUntagged;
    }

    if (versionType === 'SEARCH_PARITY') {
      params['search_text'] = options.searchText ?? options?.query?.name;
    }

    const queryOptions = {
      continuation,
      excludeIds,
      excludeSelf,
      followContinuations,
      network,
      params,
    };

    const { results: _results, metadata } = await this._query(queryOptions, versionNumber);
    const results = _.uniqBy(_results, 'entity') as unknown as Result[];

    return { results, metadata };
  }

  async queryOrgs(options: {
    version?: 'LEGACY' | 'SEARCH_PARITY';
    query?: string;
    continuation?: string;
  }) {
    const { query = '', version } = options;
    const __version__ = version === 'SEARCH_PARITY' ? 'VERSION_EIGHT' : undefined;
    const req: SearchRequestParams = {
      bool: { must: { name: query } },
      results_format: 'entities',
      return_fields: [
        'billing',
        'created_date',
        'email_domains',
        'name',
        'org_type',
        'token',
        'total_members',
      ],
      sort: ['name'],
      type: 'organization',
    };
    if (version !== 'SEARCH_PARITY') {
      req.continuation = options.continuation || '';
    }
    const res = await this.host.api.search.query(req, __version__);
    return Camelizer.camelizeObject(res);
  }

  _query = async (
    options: {
      continuation?: string | null;
      excludeIds: string[];
      excludeSelf: boolean;
      followContinuations: boolean;
      network: 'provider' | 'patient';
      params: SearchRequestParams;
    },
    versionNumber?: VersionNumber
  ): Promise<{ results: SearchResult[]; metadata: SearchMetadata }> => {
    const {
      continuation = null,
      excludeIds = [],
      excludeSelf = false,
      followContinuations = false,
      network = 'provider',
    } = options;
    let { params } = options;

    if (continuation) params = { ...params, continuation };

    let res;

    if (network === 'patient' || params.type?.includes('patient_account')) {
      res = await this.host.api.search.patientQuery(params, versionNumber);

      if (!res.metadata.continuation) {
        res.metadata.continuation = null;
      }
    } else {
      res = await this.host.api.search.query(params, versionNumber);
    }

    const processedResults = [];
    for (const result of res.results) {
      const type = result['type'];
      const entityAttrs = result['entity'];
      const organizationId = result['organization_id'];
      let conversationId;

      if (this.config.condensedReplays) {
        if (entityAttrs.conversation_id) {
          conversationId = entityAttrs.conversation_id;
          delete entityAttrs.conversation_id;
        } else {
          conversationId = result['conversation_id'];
        }
      } else {
        delete entityAttrs.conversation_id;
      }

      if (Object.keys(entityAttrs).length === 0) {
        continue;
      }

      // normalize fields that are different on search compared to most other endpoints
      if (versionNumber !== 'VERSION_EIGHT') {
        if (type === 'tigertext:entity:account') {
          replaceKey(entityAttrs, 'status', 'account_status');
        } else if (type === 'tigertext:entity:tag') {
          entityAttrs.token = `${organizationId}:${entityAttrs.token}`;
        }
      } else {
        if (type === 'individual') {
          replaceKey(entityAttrs, 'status', 'account_status');
        } else if (type === 'tag') {
          entityAttrs.token = `${organizationId}:${entityAttrs.token}`;
        }
      }

      const _metadata = entityAttrs.metadata;
      if (_metadata) {
        this.host.metadata.__injectMetadata(entityAttrs.token, organizationId, _metadata);
      }

      let entityType: string;
      if (versionNumber !== 'VERSION_EIGHT') {
        entityType = this.host.modelNameByTypeNS(type);
      } else {
        const qualifiedType = `tigertext:entity:${type}`;
        entityType = this.host.modelNameByTypeNS(qualifiedType);
      }

      const groupType =
        entityType === 'group' && this.host.groups.__extractGroupTypeFromAttrs(entityAttrs);

      if (groupType === GroupType.FORUM) {
        delete entityAttrs.members;
      }

      const metadataEntity = this.host.metadata.get(entityAttrs.token, organizationId);
      const metadata = metadataEntity ? metadataEntity.data : null;
      let entity = this.host.conversations.__injectCounterParty({
        conversationId,
        entityAttrs,
        entityType,
        groupType,
        hasCurrentUser: false,
        organizationId,
      });

      if (versionNumber !== 'VERSION_EIGHT') {
        if (entity.$entityType === 'user' && metadata && metadata['feature_service'] === 'role') {
          this.host.roles.__injectSearchResult({ entity, metadata, organizationId });
        }
      } else {
        if (type === 'role') {
          this.host.roles.__injectSearchResult({ entity, metadata, organizationId });
        }
      }

      if (excludeIds.length && excludeIds.includes(entity.id)) {
        continue;
      }

      if (excludeSelf && entity.id === this.host.currentUserId) {
        continue;
      }

      if (!entity) {
        // TODO: Due to un-typed BaseService
        // Property 'logger' does not exist on type 'SearchService'
        // @ts-expect-error
        this.logger.warn('no entity for ', entry);
        continue;
      }

      if (entity.isRoleBot && entity.botRole) entity = entity.botRole;
      const searchResult = new SearchResult(entity, organizationId, conversationId, metadata);

      processedResults.unshift(searchResult);
    }

    const {
      continuation: nextContinuation,
      first_hit: firstHit,
      total_hits: totalHits,
    } = res.metadata;
    const searchMetadata: SearchMetadata = { firstHit, totalHits };

    if (nextContinuation) {
      if (followContinuations) {
        searchMetadata.firstHit = 1;
      } else {
        searchMetadata.continuation = nextContinuation;
      }
    }
    if (versionNumber === 'VERSION_EIGHT') {
      const entityOrder = (res.metadata as SearchParityResponse['metadata']).entity_order;
      const mappedOrder = entityOrder?.map(
        (entity) => SearchTypeFromServer[entity as keyof typeof SearchTypeFromServer]
      );
      searchMetadata.entityOrder = mappedOrder;
    }

    const allResults = [];
    if (followContinuations && nextContinuation) {
      const { results: nextPage } = await this._query(
        { ...options, continuation: nextContinuation },
        versionNumber
      );
      allResults.push(...nextPage);
    }
    for (const result of processedResults) allResults.unshift(result);

    return { results: allResults, metadata: searchMetadata };
  };

  _queryLocalOrganization({
    organization,
    query,
    types,
  }: {
    organization: OrganizationSkeleton;
    query: Query;
    types: string[];
  }) {
    const organizationId = organization.id;
    const results = this._searchLocalOrganization({ organization, query, types });
    const metadata: SearchMetadata = { firstHit: 1, totalHits: results.length };
    const fields = Object.keys(query);

    if (results.length === 0 && fields.length > 0) {
      const displayNameField = fields.find((field) => field.includes('displayName'));
      const input = displayNameField ? query[displayNameField] : query[fields[0]];

      if (isEmail(input) || isPhone(input)) {
        const user = this.host.models.User.createInstance({
          displayName: isEmail(input) ? input : formatCountryCodePhoneNumber(input),
          id: isEmail(input) ? input : formatCountryCodePhoneNumber(input),
          messageAnyoneRecipient: true,
          organizationId,
        });

        // TODO: Incorrect order of constructor args?
        // @ts-expect-error
        const searchResult = new SearchResult(user, organizationId, metadata);

        return { results: [searchResult], metadata };
      }
    }

    return { results, metadata };
  }

  _searchLocalOrganization = ({
    organization,
    query,
    types = [],
  }: {
    organization: OrganizationSkeleton;
    query: Query;
    types: string[];
  }) => {
    const { conversations, id: organizationId } = organization;
    const results = [];

    for (const { counterParty, counterPartyType, metadata } of conversations) {
      const searchType = MAP_SEARCH_TYPE_TO_SERVER_TYPE[counterPartyType as SearchType];
      if (!types.includes(searchType)) continue;

      if (!query || Object.keys(query).length === 0) {
        // TODO: Incorrect order of constructor args?
        // @ts-expect-error
        results.push(new SearchResult(counterParty, organizationId, metadata));
        continue;
      }

      let andMatch = true;
      for (const [fields, term] of Object.entries(query)) {
        const _fields = fields.split(',');
        const _term = term.toLowerCase();
        let orMatch = false;

        for (const field of _fields) {
          const searchArea =
            counterPartyType === 'user' && PROFILE_ATTRIBUTES_PER_ORG.includes(field)
              ? counterParty.profileByOrganizationId[organizationId]
              : counterParty;

          if (!searchArea) continue;

          const fieldValue = searchArea[field];
          if (fieldValue !== undefined && fieldValue.toLowerCase().includes(_term)) {
            orMatch = true;
            break;
          }
        }

        if (!orMatch) {
          andMatch = false;
          break;
        }
      }

      if (andMatch) {
        // TODO: Incorrect order of constructor args?
        // @ts-expect-error
        results.push(new SearchResult(counterParty, organizationId, metadata));
      }
    }

    return results;
  };
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../bem';
import propTypes from '../../../propTypes';
import mobxInjectSelect from '../../../utils/mobxInjectSelect';
import { ReactComponent as PatientSvg } from '../../../images/default-avatar--patientCare.svg';
import { ReactComponent as OptOutSvg } from '../../../images/default-avatar--singleProviderSmsOptedOut.svg';
import { ReactComponent as PlusButtonSvg } from '../../../images/plus-lean.svg';
import { PatientAdminCardDetails, PatientAdminCardContactDetails } from './';
import { KEYMAP } from 'common/constants';

const classes = BEM.with('PatientAdminCard');

class PatientAdminCard extends Component {
  static propTypes = {
    contactNumbers: PropTypes.number.isRequired,
    currentOrganizationId: PropTypes.string.isRequired,
    entity: propTypes.user.isRequired,
    index: PropTypes.number.isRequired,
    patientList: PropTypes.object.isRequired,
    recomputeRowHeights: PropTypes.func.isRequired,
    showAddContactModal: PropTypes.func.isRequired,
    setSelectedPatientId: PropTypes.func.isRequired,
    accessibilityMode: PropTypes.bool,
  };

  state = {
    isAccessibleFocused: false,
  };

  constructor(props) {
    super(props);
    this.hoverRef = React.createRef();
    this.addContactButton = null;
  }

  addContactFocusOutHandler = () => {
    setTimeout(() => {
      const activeEl = document.activeElement;
      if (activeEl !== this.hoverRef?.current && !this.hoverRef?.current?.contains(activeEl)) {
        this.setState({ isAccessibleFocused: false });
      }
    }, 0);
  };

  focusInHandler = () => {
    if (this.state.isAccessibleFocused) return;
    this.setState({ isAccessibleFocused: true });
    this.addContactButton = this.hoverRef?.current?.querySelector(
      '.tc-PatientAdminCard__add-contact-row'
    );
    this.addContactButton?.addEventListener('focusout', this.addContactFocusOutHandler);
  };

  focusOutHandler = () => {
    if (!this.state.isAccessibleFocused) return;
    setTimeout(() => {
      if (
        this.hoverRef?.current?.getAttribute('tabindex') === '-1' ||
        !this.hoverRef?.current?.contains(document.activeElement)
      ) {
        this.setState({ isAccessibleFocused: false });
      }
    }, 0);
  };

  handleClickOutside = (event) => {
    if (!this.state.isAccessibleFocused) return;
    if (this.hoverRef?.current && !this.hoverRef?.current?.contains(event.target)) {
      this.setState({ isAccessibleFocused: false });
    }
  };

  componentDidMount() {
    this.hoverRef?.current?.addEventListener('focusin', this.focusInHandler);
    this.hoverRef?.current?.addEventListener('focusout', this.focusOutHandler);
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    this.hoverRef?.current?.removeEventListener('focusin', this.focusInHandler);
    this.hoverRef?.current?.removeEventListener('focusout', this.focusOutHandler);
    this.addContactButton?.removeEventListener('focusout', this.addContactFocusOutHandler);
    document.removeEventListener('click', this.handleClickOutside);
  }

  componentDidUpdate() {
    const { contactNumbers, index, patientList, recomputeRowHeights } = this.props;

    if (contactNumbers !== patientList[index].patient.contacts.length) {
      recomputeRowHeights(index);
    }
  }

  render() {
    const { currentOrganizationId, entity, index, setSelectedPatientId } = this.props;
    const { patient } = entity;
    const { smsOptedOut } = patient;

    const contacts = patient.contacts.filter(
      (contact) => contact.organizationId === currentOrganizationId
    );

    return (
      <div className={classes()}>
        <div className={classes('patient-admin-container')} ref={this.hoverRef}>
          <div className={classes('patient-contact-container')}>
            <div className={classes('avatar-col')}>
              <div className={classes('avatar-container', { smsOptedOut })}>
                {smsOptedOut ? (
                  <OptOutSvg className={classes('patient-icon')} />
                ) : (
                  <PatientSvg className={classes('patient-icon')} />
                )}
              </div>
            </div>
            <div className={classes('detail-col')}>
              <PatientAdminCardDetails
                key={patient.id}
                entity={entity}
                index={index}
                setSelectedPatientId={setSelectedPatientId}
                isAccessibleFocused={this.state.isAccessibleFocused && this.props.accessibilityMode}
              />
              {contacts &&
                contacts.map((contact) => (
                  <PatientAdminCardContactDetails
                    key={contact.id}
                    entity={entity}
                    contact={contact}
                    index={index}
                  />
                ))}
            </div>
          </div>
          {contacts.length < 2 && (
            <div
              role="button"
              className={classes('add-contact-row')}
              aria-label="Add contact"
              onClick={this._addContact}
              onKeyDown={(e) => {
                if (e.key === KEYMAP.SPACE || e.key === KEYMAP.ENTER) {
                  this._addContact();
                }
              }}
            >
              <div className={classes('add-contact-container')}>
                <div className={classes('icon')}>
                  <PlusButtonSvg className={classes('button-icon-path')} aria-hidden />
                </div>
                <div className={classes('add-contact-text')}>Add Contact</div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  _addContact = () => {
    const { entity, index, showAddContactModal } = this.props;

    document.activeElement.blur();
    showAddContactModal(entity, index);
  };
}

export default mobxInjectSelect({
  patientStore: ['showAddContactModal'],
  messengerStore: ['currentOrganizationId'],
})(PatientAdminCard);

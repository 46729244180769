import { useEffect, useState } from 'react';
import { ViewContainer } from '../../../../shared-components/View/ViewContainer/ViewContainer';
import { copyToClipboard, mobxInjectSelect } from '../../../../../common/utils';
import { Table, TableRow } from '../../../../shared-components/Table/Table';
import { TabProps } from '..';
import TCClient from '../../../../../client';
import AttributeRow from './AttributeRow';
import styles from './SCIM.module.css';
import { CtaButton } from 'admin/shared-components/CtaButton/CtaButton';
import { Input } from 'admin/shared-components/Input/Input';
import { Toast } from 'admin/shared-components/Toast/Toast';
import { Banner } from 'common/components';
import { Attribute } from 'types';
type MobxProps = {
  scimStatus: string;
  scimBaseUrl: string;
  loadConfiguration: () => Promise<Attribute[]>;
  attributes: Attribute[];
  isScimAdmin: boolean;
};

function SCIM({
  onClose,
  loadConfiguration,
  scimBaseUrl,
  scimStatus,
  isScimAdmin,
}: TabProps & MobxProps) {
  const [isSuccessToastOpen, setIsSuccessToastOpen] = useState(false);
  const [isFailureToastOpen, setIsFailureToastOpen] = useState(false);
  const [rows, setRows] = useState<Array<TableRow>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const hasScimBaseUrl = scimBaseUrl.length > 0;
  const columns = [
    {
      field: 'name',
      headerName: 'Attribute',
      customCellStyles: { width: '120px', verticalAlign: 'top' },
    },
    { field: 'description', headerName: 'Description' },
  ];

  useEffect(() => {
    async function getSCIMSettings() {
      if (isScimAdmin) {
        await loadConfiguration();
        const availableScimFields = await TCClient.scim.findAvailableSCIMFields();
        const attributes = (availableScimFields.attributes as Attribute[]) ?? [];
        const rows = attributes.map((attribute) => ({
          id: attribute.name,
          name: attribute.name,
          description: <AttributeRow attribute={attribute} />,
        }));
        setRows(rows);
      }
      setIsLoading(false);
    }
    getSCIMSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ViewContainer
      {...{
        header: 'SCIM Provisioning',
        onClose,
      }}
    >
      <>
        {isScimAdmin && (
          <div className={styles.scimProvisioning}>
            <div className={styles.section}>
              <div className={styles.settingRow}>
                <div className={styles.label}>Base Url: </div>
                <Input disabled value={scimBaseUrl} />
                <CtaButton
                  primary
                  disabled={!hasScimBaseUrl}
                  label="Copy"
                  onClick={async () => {
                    if (hasScimBaseUrl) {
                      copyToClipboard(scimBaseUrl);
                      setIsSuccessToastOpen(true);
                    } else {
                      setIsFailureToastOpen(true);
                    }
                  }}
                />
              </div>
              <div className={styles.settingRow}>
                <div className={styles.label}> Status: </div>
                <Banner type={scimStatus} />
              </div>
            </div>
            <Toast
              message={'Base url not available'}
              type={'FAILURE'}
              open={isFailureToastOpen}
              onClose={() => {
                setIsFailureToastOpen(false);
              }}
              autoHideDuration={2000}
            />
            <Toast
              type="SUCCESS"
              message="Url copied"
              open={isSuccessToastOpen}
              onClose={() => {
                setIsSuccessToastOpen(false);
              }}
              autoHideDuration={2000}
            />
            <div className={styles.section}>
              <div className={styles.header}>Available Attributes</div>
              <div>
                <Table rows={rows} columns={columns} isLoading={isLoading} />
              </div>
            </div>
          </div>
        )}
      </>
    </ViewContainer>
  );
}

export default mobxInjectSelect<TabProps, MobxProps>({
  scimStore: ['scimStatus', 'scimBaseUrl', 'loadConfiguration'],
  messengerStore: ['isScimAdmin'],
})(SCIM);

import React, { useCallback, useEffect, useState } from 'react';
import { get } from 'lodash';
import { Scrollbars } from 'tt-react-custom-scrollbars';
import { CallLogEntry } from './';
import { useAppSelector } from 'redux-stores';
import { UISliceState } from 'redux-stores/ui';
import BEM from 'common/bem';
import { useInfiniteSearchResults } from 'common/hooks';
import { mobxInjectSelect, date } from 'common/utils';
import { CallLog as CallLogType, CallLogEntity } from 'types';
import { AccessibleList } from 'common/components';

const classes = BEM.with('CallLog');

type CallLogProps = {};

type MobxProps = {
  currentLog: CallLogType;
  currentOrganizationId: string;
  fetchLog: (...args: unknown[]) => Promise<unknown>;
  isProviderNetwork: boolean;
  logContinuation?: string;
  setCurrentLog: (log: CallLogType | null) => void;
  setLogContinuation: (continuation: string) => void;
};

const patientDetails = (entity: CallLogEntity) => {
  const { metadata } = entity;
  const items: string[] = [];
  if (!metadata) return items;
  if (metadata.patientMrn) items.push(`MRN ${metadata.patientMrn}`);
  if (metadata.patientDob) items.push(`DOB ${date.formatDob(metadata.patientDob)}`);
  if (metadata.patientGender) items.push(metadata.patientGender);
  return items;
};

function CallLog({
  currentLog,
  currentOrganizationId,
  fetchLog,
  logContinuation,
  isProviderNetwork,
  setCurrentLog,
  setLogContinuation,
}: CallLogProps & MobxProps) {
  const { continuation, resetSearch, results, scrollContainerRef, updateOptions } =
    useInfiniteSearchResults(fetchLog, {
      network: isProviderNetwork ? 'provider' : 'patient',
      organizationId: currentOrganizationId,
    });
  const { accessibilityMode } = useAppSelector(({ ui }: { ui: UISliceState }) => ({
    accessibilityMode: ui.accessibilityMode,
  }));
  const [callLogs, setCallLogs] = useState<CallLogType[]>([]);
  const [isResetting, setIsResetting] = useState(false);

  useEffect(() => {
    setCurrentLog(null);
    updateOptions({}, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (results.length < 1) return;
    const logs: CallLogType[] = results.map((log: CallLogType) => ({
      ...log,
      patientDetails: patientDetails(get(log, 'target.entity')),
    }));
    if (isResetting && currentLog) {
      const newCurrentLog = logs.find((log) => log.id === currentLog.id);
      newCurrentLog && setCurrentLog(newCurrentLog);
    }
    if (!currentLog) setCurrentLog(logs[0]);
    setIsResetting(false);
    setCallLogs(logs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, currentLog]);

  useEffect(() => {
    setLogContinuation(continuation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [continuation]);

  useEffect(() => {
    if (logContinuation !== continuation) {
      setIsResetting(true);
      resetSearch();
      updateOptions({}, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logContinuation]);

  const setScrollBarRef = useCallback((element) => {
    if (!scrollContainerRef.current) {
      scrollContainerRef.current = element.view;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classes()}
      id="call-log"
      role="tabpanel"
      aria-labelledby="patient-sidebar-tab-picker-2"
    >
      <Scrollbars autoHide autoHideTimeout={750} ref={setScrollBarRef}>
        <AccessibleList
          focusableClasses=".tc-CallLog__call-log-entry"
          loop={true}
          focusableChildrenClasses=".tc-RoundButton__button"
          accessibilityMode={accessibilityMode}
        >
          {callLogs.map((log: CallLogType, index: number) => (
            <CallLogEntry key={log.id} log={log} />
          ))}
        </AccessibleList>
      </Scrollbars>
    </div>
  );
}

export default mobxInjectSelect<CallLogProps, MobxProps>({
  callStore: ['currentLog', 'fetchLog', 'logContinuation', 'setCurrentLog', 'setLogContinuation'],
  messengerStore: ['currentOrganizationId'],
  networkStore: ['isProviderNetwork'],
})(CallLog);

import React, { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import BEM from '../../../bem';
import { useInfiniteSearchResults } from '../../../hooks';
import { ReactComponent as DeleteButtonSvg } from '../../../images/delete_button.svg';
import { mobxInjectSelect } from '../../../utils';
import { DotsIndicator, CollapsingSearchBar } from '../../';

import RefreshButton from '../../RefreshButton';
import { TemplatesTable } from './';

const DEBOUNCE_TIMEOUT = 500;
const DEFAULT_SORT_ORDER = 'desc';

const classes = BEM.with('TemplatesView');

const TemplatesView = ({
  allowPatientDeliveryMethod,
  createTemplate,
  editTemplate,
  isAdmin,
  loadTemplates,
  openModal,
  repository,
  viewTemplate,
  setMessageTemplateAdminPageRefresher,
}) => {
  const { isLoading, results, resetSearch, scrollContainerRef, totalHits, updateOptions } =
    useInfiniteSearchResults(loadTemplates, {
      repository,
      ...(!allowPatientDeliveryMethod ? { isSmsCompatible: false } : null),
    });

  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState([]);
  const [sortBy, setSortBy] = useState('updated_on');
  const [sortOrder, setSortOrder] = useState(DEFAULT_SORT_ORDER);
  const setQueryDebounced = useRef(debounce(setQuery, DEBOUNCE_TIMEOUT));

  const handleQueryChange = (value) => {
    if (value !== query) {
      resetSearch();
      setQueryDebounced.current(value);
    }
  };

  const reloadSearch = (options) => {
    setSelected([]);
    resetSearch();
    updateOptions(options);
  };

  const handleBulkDelete = () => {
    openModal('deleteTemplate', {
      selected,
      reloadTemplates: reloadSearch,
    });
  };

  setMessageTemplateAdminPageRefresher(reloadSearch);

  const toggleSelected = (token) => {
    if (selected.includes(token)) {
      setSelected(selected.filter((id) => id !== token));
    } else {
      setSelected([...selected, token]);
    }
  };

  const toggleSort = (newSortBy) => {
    setSortOrder(sortBy !== newSortBy ? 'asc' : sortOrder === 'asc' ? 'desc' : 'asc');
    setSortBy(newSortBy);
  };

  useEffect(() => {
    setQuery('');
    setSortBy('updated_on');
    setSortOrder(DEFAULT_SORT_ORDER);
  }, [repository]);

  useEffect(() => {
    reloadSearch({
      query,
      repository,
      sortBy,
      sortOrder,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, repository, sortBy, sortOrder]);

  return (
    <div className={classes()}>
      <div className={classes('header')}>
        <div className={classes('title')}>
          {repository === 'aar_variables' ? 'Dynamic' : `${repository}`} Templates ({totalHits})
          <RefreshButton refreshList={reloadSearch} />
        </div>
      </div>
      <div className={classes('actions')}>
        <div className={classes('actions-left')}>
          {(repository === 'personal' || isAdmin) && (
            <button
              className={classes('create')}
              onClick={createTemplate}
              data-test-id={'create-template-button'}
            >
              Create Template
            </button>
          )}
          <div className={classes('filters')}>
            <CollapsingSearchBar handleQueryChange={handleQueryChange} query={query} />
          </div>
        </div>
        {selected.length > 0 && (
          <div className={classes('actions-right')}>
            <button onClick={handleBulkDelete} className={classes('actions-right-button')}>
              <DeleteButtonSvg className={classes('delete-icon')} />
              <span className={classes('delete-icon-text')}>Delete </span>
            </button>
          </div>
        )}
      </div>
      <TemplatesTable
        allowPatientDeliveryMethod={allowPatientDeliveryMethod}
        isAdmin={isAdmin}
        isLoadingMore={isLoading}
        onClick={repository === 'personal' || isAdmin ? editTemplate : viewTemplate}
        queryValue={query}
        repository={repository}
        results={results}
        scrollContainerRef={scrollContainerRef}
        selected={selected}
        sortBy={sortBy}
        sortOrder={sortOrder}
        toggleSelected={toggleSelected}
        toggleSort={toggleSort}
      />
      <div className={classes('loading-more', { isLoadingMore: isLoading })}>
        <DotsIndicator color={'#969696'} size={18} />
      </div>
    </div>
  );
};

TemplatesView.propTypes = {
  createTemplate: PropTypes.func.isRequired,
  editTemplate: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  loadTemplates: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  repository: PropTypes.string.isRequired,
  viewTemplate: PropTypes.func.isRequired,
};

export default mobxInjectSelect({
  modalStore: ['openModal'],
  patientAdminStore: ['allowPatientDeliveryMethod', 'loadTemplates'],
})(TemplatesView);

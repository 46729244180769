// @ts-nocheck
(() => {
  try {
    const p = require('stream-http').request().constructor.prototype;
    if (!p.setNoDelay) {
      p.setNoDelay = function () {};
    }
    if (!p.abort) {
      p.abort = function () {
        this.xhr && this.xhr.abort();
      };
    }
  } catch (ex) {
    // do nothing
  }
})();

import React from 'react';
import { observer, inject } from 'mobx-react';
import {
  Button as ButtonWCL,
  Card as CardWCL,
  Dropdown as DropdownWCL,
  Modal as ModalWCL,
  Switch as SwitchWCL,
  EntityDetail as EntityDetailWCL,
} from '@tigerconnect/web-component-library';

type MobxProps = {
  messengerStore?: {
    isDarkModeOn: boolean;
  };
};

const themed = <P extends object>(Component: React.ComponentType<P>) => {
  @inject('messengerStore')
  @observer
  class ThemedComponent extends React.Component<P & MobxProps> {
    render() {
      const { messengerStore, ...props } = this.props;
      const darkMode = messengerStore?.isDarkModeOn;
      return <Component mode={darkMode ? 'dark' : 'light'} {...(props as P)} />;
    }
  }
  return ThemedComponent;
};

export const Button = themed(ButtonWCL);
export const Card = themed(CardWCL);
export const Dropdown = themed(DropdownWCL);
export const Modal = themed(ModalWCL);
export const Switch = themed(SwitchWCL);
export const EntityDetail = themed(EntityDetailWCL);

export default themed;

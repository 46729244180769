import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import BEM from '../bem';
import propTypes from '../propTypes';
import { messageRecipientStatusToReadableLabel } from '../utils/readableLabels';

const classes = BEM.with('MessageRecipientStatus');

class SingleRecipientMessageRecipientStatus extends Component {
  static propTypes = {
    className: PropTypes.string,
    recipientStatus: propTypes.messageRecipientStatus.isRequired,
  };

  render() {
    const { className, recipientStatus } = this.props;
    const label = messageRecipientStatusToReadableLabel(recipientStatus);

    return (
      <span
        aria-label="Message Bubble Status"
        className={classNames(className, classes({ [recipientStatus]: true }))}
        title={label}
      >
        {label}
      </span>
    );
  }
}

export default observer(SingleRecipientMessageRecipientStatus);

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Switch } from '@tigerconnect/web-component-library';
import { ScheduleOption } from '../../../models/enums';
import {
  DeliveryMethod as DeliveryMethodType,
  DeliveryMethods,
} from '../../../models/enums/DeliveryMethods';
import SearchTypes from '../../../models/enums/SearchTypes';
import { FROZEN_EMPTY_ARRAY, mobxInjectSelect, searchPlaceholder } from '../../utils';
import scheduledMessageFormats from '../../utils/scheduledMessageFormats';
import BEM from '../../bem';
import { ReactComponent as PreferencesSvg } from '../../images/scheduled-message-preferences.svg';
import { ReactComponent as RepeatSvg } from '../../images/repeat.svg';
import { ReactComponent as ScheduleMessageHeaderSvg } from '../../images/schedule-message-header-icon.svg';
import { ReactComponent as ScheduleMessageSvg } from '../../images/schedule-message-content-icon.svg';
import { ReactComponent as ScheduleRecipientSvg } from '../../images/schedule-recipient-icon.svg';
import { ReactComponent as ScheduleSvg } from '../../images/schedule-icon.svg';
import { ReactComponent as DropDownChevronSvg } from '../../images/dropdown-chevron.svg';
import { ContextMenu } from '../ContextMenu';
import { DeliveryMethodIcon } from '../PatientSettings/DeliveryMethodIcon';
import DeliveryMethodScheduledMessageMenu, {
  DeliveryMethod,
} from '../ScheduleMessageModal/DeliveryMethodScheduledMessageMenu';
import { Modal, RecipientSearchPicker, Scrollbars } from '../';
import { MessageInputs, ScheduleInputs } from './';
import { useAppSelector } from 'redux-stores';

const { LINK, SMS } = DeliveryMethods;
const { NONE_SELECTED_TEMPLATE } = scheduledMessageFormats;

const classes = BEM.with('ScheduleMessageModal');

type Recipient = {
  $entityType: string;
  id: string;
};

type ScheduleMessageModalProps = {
  options: {
    onClose: () => void;
    feed?: string;
  };
};

type MobxProps = {
  allowPatientBroadcastLists: boolean;
  allowPatientDeliveryMethod: boolean;
  bulkUpdateScheduledMessage: (data: unknown) => void;
  closeModal: () => void;
  currentOrganization: Record<string, unknown>;
  isOpen: boolean;
  openModal: (
    text: string,
    options?: {
      reopenModal: string;
      onClose: () => void;
      feed?: string;
    }
  ) => void;
  scheduledMessage: {
    body: string;
    deliveryMethod: DeliveryMethodType;
    noReply: boolean;
    recipients: Recipient[];
    repeatEnabled: boolean;
    repeatSchedule: string;
    scheduleOption: string;
    selectedEndDate: string;
    selectedStartDate: string;
    selectedTime: string;
  };
  smsMessageLengthLimit: number;
  updateScheduledMessage: (title: string, editable: boolean | string | Recipient[]) => void;
  resetScheduledMessageState: () => void;
};

function ScheduleMessageModal({
  allowPatientBroadcastLists,
  allowPatientDeliveryMethod,
  bulkUpdateScheduledMessage,
  closeModal,
  currentOrganization,
  isOpen,
  options,
  openModal,
  resetScheduledMessageState,
  scheduledMessage,
  smsMessageLengthLimit,
  updateScheduledMessage,
}: MobxProps & ScheduleMessageModalProps) {
  const { noReply, recipients, repeatEnabled, scheduleOption, deliveryMethod } = scheduledMessage;
  const [messageDeliveryMethod, setMessageDeliveryMethod] = useState(deliveryMethod);
  const deliveryMethodRef = useRef<HTMLDivElement>(null);
  const previousMessageDeliveryMethod = useRef(deliveryMethod);
  const isPatientBroadcastList =
    recipients.length > 0 && recipients[0].$entityType === 'distributionList';
  const { accessibilityMode } = useAppSelector(({ ui }) => ({
    accessibilityMode: ui.accessibilityMode,
  }));

  const searchTypes = [SearchTypes.PATIENT];
  if (allowPatientBroadcastLists) {
    searchTypes.push(SearchTypes.PATIENT_DISTRIBUTION_LIST);
  }

  const _canScheduleMessage = useCallback(() => {
    const {
      body,
      recipients,
      repeatEnabled,
      repeatSchedule,
      scheduleOption,
      selectedEndDate,
      selectedStartDate,
      selectedTime,
    } = scheduledMessage;

    if (
      recipients.length < 1 ||
      body.trim().length === 0 ||
      (messageDeliveryMethod === 'sms' && body.length > smsMessageLengthLimit)
    )
      return false;
    if (scheduleOption === ScheduleOption.NOT_SELECTED) return false;
    if (scheduleOption === ScheduleOption.SET_DATE_TIME && (!selectedStartDate || !selectedTime))
      return false;
    if (repeatEnabled && (!repeatSchedule || !selectedEndDate)) return false;

    return true;
  }, [messageDeliveryMethod, scheduledMessage, smsMessageLengthLimit]);

  useEffect(() => {
    if (
      previousMessageDeliveryMethod.current !== messageDeliveryMethod &&
      messageDeliveryMethod === SMS
    ) {
      bulkUpdateScheduledMessage({
        attachments: FROZEN_EMPTY_ARRAY,
        body: '',
        noReply: true,
        recipient: null,
        recipients: FROZEN_EMPTY_ARRAY,
        selectedTemplate: NONE_SELECTED_TEMPLATE,
      });
      window.setTimeout(() => focusRecipientPicker(true), 50);
    }

    previousMessageDeliveryMethod.current = messageDeliveryMethod;
  }, [messageDeliveryMethod]); //eslint-disable-line react-hooks/exhaustive-deps

  const _closeModal = () => {
    resetScheduledMessageState();
    setMessageDeliveryMethod(LINK);
    previousMessageDeliveryMethod.current = LINK;
    closeModal();
  };

  const focusRecipientPicker = (force = false) => {
    if (force || !recipients.length) {
      const elements = document.querySelectorAll('[tabindex="1"]');
      if (elements.length) {
        const recipientSelector = elements[0] as HTMLInputElement;
        recipientSelector.focus();
      }
    }
  };

  const _handleRecipientSelected = (newRecipients: Recipient[]) => {
    if (!newRecipients) return;
    if (newRecipients.length > 0) {
      newRecipients = [newRecipients[0]];
    }

    updateScheduledMessage('recipients', newRecipients);
  };

  const _previewScheduleMessage = () => {
    const { noReply } = scheduledMessage;
    const { onClose, feed } = options;

    updateScheduledMessage('noReply', noReply);
    updateScheduledMessage('deliveryMethod', messageDeliveryMethod);
    openModal('scheduleMessageReview', {
      reopenModal: 'scheduleMessage',
      onClose,
      feed,
    });
  };

  const _toggleNoReply = () => {
    updateScheduledMessage('noReply', !noReply);
  };

  const _toggleRepeat = () => {
    updateScheduledMessage('repeatEnabled', !repeatEnabled);
  };

  return (
    <Modal
      bodyClass={classes('body')}
      className={classes()}
      hasCloseButton
      closeClass={classes('close-btn')}
      header={
        <div className={classes('header-content')}>
          <ScheduleMessageHeaderSvg />
          <p>Schedule Message</p>
        </div>
      }
      headerClass={classes('header')}
      isOpen={isOpen}
      onRequestClose={_closeModal}
      size="large"
      footerClass={classes('footer')}
      footerPrimaryActions={
        <button
          type="button"
          className={classes('primary-btn')}
          data-test-id={'schedule-message-next-button'}
          disabled={!_canScheduleMessage()}
          onClick={_previewScheduleMessage}
        >
          NEXT
        </button>
      }
      footerSecondaryActions={
        <button
          className={classes('secondary-btn')}
          data-test-id={'schedule-message-cancel-button'}
          type="button"
          onClick={_closeModal}
        >
          CANCEL
        </button>
      }
    >
      <div className={classes('form-container')}>
        <Scrollbars shouldScrollOverflow={true}>
          <div className={classes('sections-container')}>
            <div className={classes('section', { deliveryMethodSection: true })}>
              <div className={classes('delivery-method-icon')}>
                <DeliveryMethodIcon
                  circleColor="var(--neutral-200)"
                  isSecure={messageDeliveryMethod === LINK}
                  size={31}
                />
                <label className={classes('delivery-label')}>Delivery</label>
              </div>
              <ContextMenu
                className={classes('delivery-method')}
                event="click"
                offsetY={6}
                position="bottominnerleft"
                relativeTo={deliveryMethodRef.current}
                theme="vertical"
                value={messageDeliveryMethod}
                data-test-id={'template-delivery-input'}
                showOnLoad
                menu={
                  <DeliveryMethodScheduledMessageMenu
                    handleClick={(newDeliveryMethod, newNoReply) => {
                      setMessageDeliveryMethod(newDeliveryMethod);
                      updateScheduledMessage('noReply', newNoReply);
                      focusRecipientPicker();
                    }}
                  />
                }
              >
                <div className={classes('message-type-dropdown')} ref={deliveryMethodRef}>
                  <div className={classes('message-kind-dropdown')}>
                    <DeliveryMethod
                      copy={`${
                        messageDeliveryMethod === LINK
                          ? 'Secure Browser Link: '
                          : 'Unsecure SMS Text: Non-'
                      }HIPAA Compliant`}
                      deliveryMethod={messageDeliveryMethod}
                      noReply={noReply}
                      shouldShowIcon={false}
                    />
                    <DropDownChevronSvg className={classes('nav-item-dropdown')} />
                  </div>
                </div>
              </ContextMenu>
            </div>
            <div className={classes('section')}>
              <ScheduleRecipientSvg className={classes('recipient-svg')} />
              <div
                className={classes('section-container', {
                  recipientPicker: true,
                })}
                data-test-id={'schedule-message-recipient-picker'}
              >
                <RecipientSearchPicker
                  className="new-message-patient"
                  isSingleRecipient={true}
                  multi
                  onChange={_handleRecipientSelected}
                  organization={currentOrganization}
                  placeholder={searchPlaceholder.getRecipientPickerSearchPlaceholder({
                    allowPatientBroadcastLists,
                    isProviderNetwork: false,
                  })}
                  searchTypes={searchTypes}
                  selected={recipients}
                  tabIndex={1}
                />
              </div>
            </div>
            <div className={classes('section')}>
              <ScheduleSvg />
              <div className={classes('section-container')}>
                <div className={classes('section-header')}>
                  <div className={classes('schedule-header')}>
                    <p>Schedule</p>
                    {scheduleOption === ScheduleOption.SET_DATE_TIME && (
                      <div className={classes('switch-container')}>
                        <RepeatSvg className={classes('repeat')} />
                        <span>Repeat</span>
                        <div
                          className={classes('switch')}
                          data-test-id={'schedule-message-repeat-switch'}
                        >
                          <Switch
                            checked={repeatEnabled}
                            onSwitch={_toggleRepeat}
                            theme={'patient'}
                            dataTestId="enableRepeat"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <ScheduleInputs
                  repeatEnabled={repeatEnabled}
                  accessibilityMode={accessibilityMode}
                />
              </div>
            </div>
            <div className={classes('section')}>
              <ScheduleMessageSvg />
              <div className={classes('section-container')}>
                <div className={classes('section-header')}>Message</div>
                <MessageInputs messageDeliveryMethod={messageDeliveryMethod} />
              </div>
            </div>
            {!allowPatientDeliveryMethod && isPatientBroadcastList && (
              <div className={classes('section')}>
                <PreferencesSvg />
                <div className={classes('section-container')}>
                  <div className={classes('section-header')}>Preferences</div>
                  <div className={classes('switch-container')}>
                    <span>No-Reply</span>
                    <div className={classes('switch')}>
                      <Switch
                        checked={noReply}
                        onSwitch={_toggleNoReply}
                        theme={'patient'}
                        dataTestId="noReply"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
    </Modal>
  );
}

export default mobxInjectSelect<ScheduleMessageModalProps, MobxProps>({
  messengerStore: ['currentOrganization'],
  modalStore: ['openModal'],
  scheduleMessageStore: [
    'bulkUpdateScheduledMessage',
    'resetScheduledMessageState',
    'scheduledMessage',
    'smsMessageLengthLimit',
    'updateScheduledMessage',
  ],
  patientAdminStore: ['allowPatientBroadcastLists', 'allowPatientDeliveryMethod'],
})(ScheduleMessageModal);

// @ts-nocheck
import _uniq from 'lodash-bound/uniq';
import { decorator as reusePromise } from 'reuse-promise';
import { formatSavedEvent } from '../utils';
import * as errors from '../errors';
import BaseService from './BaseService';

export default class TeamsService extends BaseService {
  mounted() {
    this._fetched = false;
  }

  dispose() {
    this._fetched = false;
  }

  @reusePromise()
  async find(id, { organizationId, bypassCache = false } = {}) {
    this.host.requireUser();

    if (!id) throw new errors.ValidationError('id', 'required');
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');

    let team;
    if (!bypassCache) {
      team = this.host.models.Team.get(id);
      if (team && !team.$placeholder) {
        return team;
      }
    }

    const response = await this.host.api.teams.find(id, { organizationId });
    const rawTeam = response.team;

    team = this.host.models.Team.inject(rawTeam);
    return team;
  }

  @reusePromise()
  async saveTeam(teamId: string, organizationId: string) {
    this.host.requireUser();
    organizationId = this._resolveModelId(organizationId);

    await this.host.api.teams.saveTeam(teamId, this.host.currentUserId, organizationId);
  }

  @reusePromise()
  async removeSavedTeam(teamId: string, organizationId: string) {
    this.host.requireUser();
    organizationId = this._resolveModelId(organizationId);

    await this.host.api.teams.removeSavedTeam(teamId, this.host.currentUserId, organizationId);
  }

  @reusePromise()
  async findSavedTeams(organizationId: string, options: Object = {}) {
    options = _.defaults(options, { ignoreNotFound: false });
    this.host.requireUser();
    organizationId = this._resolveModelId(organizationId);

    const results = await this.host.api.teams.findSavedTeams(
      this.host.currentUserId,
      organizationId
    );
    if (!results) {
      if (options.ignoreNotFound) return;
      throw new errors.NotFoundError('saved teams', organizationId);
    }

    this.host.models.Organization.touch(organizationId);

    return results;
  }

  @reusePromise()
  async refresh(id, { organizationId } = {}) {
    const team = await this.find(id, { organizationId, bypassCache: true });

    return team;
  }

  @reusePromise()
  async findAll({ bypassCache = false } = {}) {
    if (!bypassCache && this._fetched) return this.getAll();

    let organizations = this.host.models.Organization.getAll();
    if (organizations.length === 0) {
      organizations = await this.host.organizations.findAll();
    }

    const { results } = await this.host.search.query({
      version: this.config.allowSearchParity ? 'SEARCH_PARITY' : 'LEGACY',
      excludeReturnFields: this.config.allowUT,
      types: ['team'],
      organizationIds: organizations.map((o) => o.id),
      query: { displayName: '' },
      followContinuations: true,
    });

    const teams = results.map((searchResult) => searchResult.entity);
    this._fetched = true;

    return teams;
  }

  @reusePromise()
  async findByOrganizationId(organizationId: string | Object) {
    organizationId = this._resolveModelId(organizationId);
    const organization = await this.host.organizations.find(organizationId);

    const { results } = await this.host.search.query({
      types: ['team'],
      organizationIds: organization.id,
      query: { displayName: '' },
      followContinuations: true,
    });

    const teams = results.map(({ entity }) => entity);

    return teams;
  }

  @reusePromise()
  async refreshAll() {
    const teams = await this.findAll({ bypassCache: true });

    return teams;
  }

  getAll() {
    return this.host.models.Team.getAll();
  }

  /**
   * Create a Team
   * @param  {File} options.avatar - Avatar
   * @param {boolean} options.canRequestToJoin - Check if a user can request to join a team
   * @param {boolean} options.canMembersLeave - Check if members can leave a team
   * @param  {string} options.description - Desciption of the team
   * @param  {string} options.display_name - Team name
   * @param  {Array<string>} options.memberIds - Member IDs
   * @param  {string} options.tagId - Tag ID
   * @return {Promise.<Team,Error>} - A promise with a team
   */
  async create({
    avatar,
    canMembersLeave = true,
    canRequestToJoin = true,
    description,
    displayName,
    memberIds = [],
    organizationId,
    tagId,
  }) {
    const minimumMembersLength = this.config.allowUT ? 1 : 2;
    this.host.requireUser();
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (memberIds.length < minimumMembersLength)
      throw new errors.ValidationError(
        'memberIds',
        `required to have ${minimumMembersLength} or more IDs`
      );

    const users = await Promise.all(
      memberIds
        .map(this.host.roles.__resolveRoleId)
        .filter(Boolean)
        .map((userId) => this._resolveModelIdWithTypes(userId, 'user'))
        .map((userId) => this.host.users.find(userId, { organizationId }))
    );
    const uniqUsers = _uniq.call(users);

    memberIds = uniqUsers.map((userId) => this._resolveModelIdWithTypes(userId, 'user'));

    const response = await this.host.api.teams.create({
      avatar,
      canMembersLeave,
      canRequestToJoin,
      description,
      displayName,
      memberIds,
      organizationId,
      tagId,
    });

    const team = this.host.models.Team.inject(response.team);
    return team;
  }

  async update({
    avatar,
    canMembersLeave = true,
    canRequestToJoin = true,
    description,
    displayName,
    id,
    memberIds = [],
    organizationId,
    tagId,
  }) {
    const minimumMembersLength = this.config.allowUT ? 1 : 2;
    this.host.requireUser();
    if (!id) throw new errors.ValidationError('id', 'required');
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (memberIds.length < minimumMembersLength)
      throw new errors.ValidationError(
        'memberIds',
        `required to have ${minimumMembersLength} or more IDs`
      );

    memberIds = memberIds.map(this.host.roles.__resolveRoleId);

    const response = await this.host.api.teams.update({
      avatar,
      canMembersLeave,
      canRequestToJoin,
      description,
      displayName,
      id,
      memberIds,
      organizationId,
      tagId,
    });

    const team = this.host.models.Team.inject(response.team);
    return team;
  }

  async delete(teamId, { organizationId }) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!teamId) throw new errors.ValidationError('teamId', 'required');

    const status = await this.host.api.teams.delete(teamId, {
      organizationId,
    });

    if (status === 204) {
      const team = this.host.models.Team.get(teamId);

      if (team) {
        this.host.models.Team.eject(team);
      }
    }

    return status;
  }

  async requestToJoin(teamId, { requestedById, organizationId }) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!teamId) throw new errors.ValidationError('teamId', 'required');
    if (!requestedById) throw new errors.ValidationError('requestedById', 'required');

    const status = await this.host.api.teams.requestToJoin(teamId, {
      requestedById,
      organizationId,
    });

    if (status === 204) {
      this.host.models.Team.inject({
        id: teamId,
        hasCurrentUserPending: true,
        hasCurrentUserBeenDeclined: false,
      });
    }
    return status;
  }

  async acceptJoinRequest(requestId, { teamId, organizationId, handledAsId }) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!teamId) throw new errors.ValidationError('teamId', 'required');
    if (!requestId) throw new errors.ValidationError('requestId', 'required');
    if (!handledAsId) throw new errors.ValidationError('handledAsId', 'required');

    const response = await this.host.api.teams.updateJoinRequest(requestId, {
      teamId,
      organizationId,
      handledAsId,
      status: 'accepted',
    });

    return response;
  }

  async declineJoinRequest(requestId, { teamId, organizationId, handledAsId }) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!teamId) throw new errors.ValidationError('teamId', 'required');
    if (!requestId) throw new errors.ValidationError('requestId', 'required');
    if (!handledAsId) throw new errors.ValidationError('handledAsId', 'required');

    const response = await this.host.api.teams.updateJoinRequest(requestId, {
      teamId,
      organizationId,
      handledAsId,
      status: 'declined',
    });

    return response;
  }

  async leave(teamId, { organizationId } = {}) {
    if (!teamId) throw new errors.ValidationError('teamId', 'required');
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');

    const status = await this.host.api.teams.leave(teamId, { organizationId });

    if (status === 204) {
      this.refresh(teamId, { organizationId });
    }

    return status;
  }

  reactToSavedEvent(data) {
    this.emit('saved', formatSavedEvent(data));
  }

  _setHasUserPending(entity) {
    if (entity.pendingRequestUserIds) {
      for (const userId of entity.pendingRequestUserIds) {
        if (userId === this.host.currentUserId) {
          entity.hasCurrentUserPending = true;
          return;
        }
      }
    }
  }

  __injectTeamRequest(attrs) {
    if (!attrs.id) return null;

    let createdByEntity;
    if (attrs.created_by && attrs.created_by.token) {
      createdByEntity = this.host.models.User.inject(attrs.created_by);
    }

    if (attrs.team && attrs.team.id) {
      const { status } = attrs;
      const team = Object.assign({}, attrs.team);

      if (createdByEntity && createdByEntity.id === this.host.currentUserId) {
        if (status === 'accepted') {
          team.hasCurrentUserBeenDeclined = false;
          team.hasCurrentUserPending = false;
        } else if (status === 'declined') {
          team.hasCurrentUserBeenDeclined = true;
          team.hasCurrentUserPending = false;
        }
      }

      this.host.models.Team.inject(team);
    }

    const teamRequest = this.host.models.TeamRequest.inject(attrs);

    return teamRequest;
  }

  resetDeclinedUserOnTeam(teamId) {
    this.host.models.Team.inject({ id: teamId, hasCurrentUserBeenDeclined: false });
  }
}

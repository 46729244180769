// @ts-nocheck
import BaseAPI, { recoverFromNotFound } from './BaseAPI';

export default class ScimAPI extends BaseAPI {
  @recoverFromNotFound()
  async findSettings() {
    const endpoint = `${this.host.tcUrl}/v1/user-service/SCIMConfiguration`;
    const res = await this.httpClient.get(endpoint);
    return res.data;
  }

  async findAvailableSCIMFields() {
    const endpoint = `${this.host.tcUrl}/v1/user-service/AvailableSCIMFields`;
    const res = await this.httpClient.get(endpoint);
    return res.data;
  }
}

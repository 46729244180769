// @ts-nocheck
import _, { isEmpty, get, defaults, without, difference, intersection } from 'lodash';
import moment from 'moment';
import { decorator as reusePromise } from 'reuse-promise';
import _uniq from 'lodash-bound/uniq';
import * as errors from '../errors';
import { EscalationChangeAction, GroupType, MessageSubType, MessageType } from '../models/enums';
import { arrayWrap, Camelizer, jsonCloneDeep } from '../utils';
import ArrayOfFailedPhoneNumberError from '../errors/ArrayOfFailedPhoneNumberError';
import BaseService from './BaseService';

export default class GroupsService extends BaseService {
  mounted() {
    this._fetched = false;
    this._groupMemberIds = {};
    this._userGroups = {};
    this._userState = {};
    this.host.models.Conversation.on('afterInject', this._onChangeConversation);
    this.host.models.Conversation.on('afterEject', this._onRemoveConversation);
    this.host.models.Group.on('afterInject', this._onChangeGroup);
    this.host.models.Group.on('afterEject', this._onRemoveGroup);
    this.host.models.Metadata.on('afterInject', this._onChangeMetadata);
    this.host.models.Metadata.on('afterEject', this._onChangeMetadata);
    this.host.models.User.on('afterInject', this._onChangeUser);
    this.host.models.User.on('afterEject', this._onRemoveUser);
  }

  dispose() {
    this._fetched = false;
    this._groupMemberIds = {};
    this._userGroups = {};
    this._userState = {};
    this.host.models.Conversation.removeListener('afterInject', this._onChangeConversation);
    this.host.models.Conversation.removeListener('afterEject', this._onRemoveConversation);
    this.host.models.Group.removeListener('afterInject', this._onChangeGroup);
    this.host.models.Group.removeListener('afterEject', this._onRemoveGroup);
    this.host.models.Metadata.removeListener('afterInject', this._onChangeMetadata);
    this.host.models.Metadata.removeListener('afterEject', this._onChangeMetadata);
    this.host.models.User.removeListener('afterInject', this._onChangeUser);
    this.host.models.User.removeListener('afterEject', this._onRemoveUser);
  }

  /**
   * Create a group
   * @param  {string} options.name - Group name
   * @param  {File} options.avatarFile - Avatar
   * @param  {Boolean} options.replayHistory - Replay History
   * @param  {Array<string>} options.memberIds - Member IDs
   * @param  {string} options.organizationId - Organization ID
   * @param  {Object} options.metadata - Optional metadata
   * @param  {string} options.senderId - ID of role or user who is creating the group
   * @return {Promise.<Group,Error>} - A promise with a group
   */
  async create({
    avatarFile,
    description,
    memberIds = [],
    metadata,
    name,
    organizationId,
    replayHistory = false,
    senderId = this.host.currentUserId,
    patientContextId,
  }) {
    this.host.requireUser();
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');

    const members = await Promise.all(
      [senderId, ...memberIds]
        .map(this.host.roles.__resolveRoleId)
        .filter(Boolean)
        .map((memberId) => this._resolveModelIdWithTypes(memberId, ['user', 'team', 'careTeam']))
        .map(async (memberId) => {
          const entity = this._resolveEntity(memberId);
          let member = entity;
          try {
            if (get(entity, '$entityType') !== 'team') {
              member = await this.host.users.findOrCreate(memberId, { organizationId });
            }
            return member;
          } catch (e) {
            return { error: e, memberId };
          }
        })
    );

    const filterMembersErr = members.filter((m) => m?.error);
    if (filterMembersErr.length) {
      const mapMembersErr = filterMembersErr.map((member) => member.memberId);
      const { error = {} } = filterMembersErr[0];
      throw new ArrayOfFailedPhoneNumberError(error.text, error.status, mapMembersErr);
    }

    const uniqueMembers = _uniq.call(members);

    memberIds = uniqueMembers.map((memberId) =>
      this._resolveModelIdWithTypes(memberId, ['user', 'team', 'careTeam'])
    );
    const excludeCreator = this._shouldExcludeSender(organizationId, memberIds, senderId);

    const response = await this.host.api.groups.create({
      avatarFile,
      description,
      excludeCreator,
      memberIds,
      metadata,
      name,
      organizationId,
      replayHistory,
      patientContextId,
    });

    let group;
    if (this.config.condensedReplays && response.entity) {
      group = this.host.conversations.__injectCounterParty({
        conversationId: response.conversation_id,
        entityAttrs: response.entity,
        entityType: 'group',
        organizationId: response.organization_id,
      });

      const highestSortNumber = this.host.messages.__getNextSortNumber(group.organizationId);
      this.host.conversations.__injectConversation(
        {
          ...response,
          entity: group,
        },
        { highestSortNumber }
      );
    } else {
      group = this.host.conversations.__injectCounterParty({
        entityAttrs: response,
        entityType: 'group',
        organizationId,
        shouldDisplay: true,
      });

      const highestSortNumber = this.host.messages.__getNextSortNumber(group.organizationId);
      const conversation = this.host.conversations.ensureConversation(
        'group',
        group.id,
        group.organizationId,
        {
          highestSortNumber,
          shouldDisplay: true,
        }
      );
      conversation.lastConversationSortNumber = highestSortNumber;
      this.host.models.Conversation.touch(conversation.id);
    }

    if (metadata) {
      this.host.metadata.__injectMetadata(group.id, organizationId, metadata);
    } else if (!this.config.condensedReplays) {
      await this.host.metadata.find(group.id, organizationId);
    }

    this.host.models.Group.injectPlaceholder({ id: group.id, localGroupCreated: true });

    return group;
  }

  createPatientGroup({ metadata, name, organizationId, recipientId, senderId }) {
    return this.create({
      isPatientGroup: true,
      metadata,
      name,
      organizationId,
      memberIds: [senderId, ...recipientId],
    });
  }

  _shouldExcludeSender(organizationId, memberIds, senderId) {
    const sender = this.host.models.User.get(senderId);
    const isUser = (item) => item && item.$entityType === 'user';
    const isRole = (item) => isUser(item) && item.isRoleBot;

    if (isRole(sender)) {
      if (sender.botRole && sender.botRole.members.length > 0) {
        return !memberIds.includes(sender.botRole.memberIds[0]);
      }
    }

    return false;
  }

  /**
   * Update a group
   * @param  {Group} group - a group object with updated properties
   * @param  {string} group.id - Group ID (required)
   * @return {Promise.<Group,Error>} - a promise with the updated group
   */
  async update(
    id: string | Object,
    {
      name,
      avatarFile,
      // TODO
      replayHistory,
      memberIds,
      metadata,
      preserve,
    }
  ) {
    this.host.requireUser();
    id = this._resolveModelId(id);

    // TODO validation

    const newAttrs = {};
    if (typeof name !== 'undefined') newAttrs.name = name;
    if (typeof replayHistory !== 'undefined') newAttrs.replayHistory = replayHistory;
    if (typeof memberIds !== 'undefined') {
      newAttrs.memberIds = memberIds.map(this.host.roles.__resolveRoleId);
    }
    if (typeof preserve !== 'undefined') newAttrs.preserve = preserve;

    const res = await this.host.api.groups.update(id, {
      name,
      avatarFile,
      replayHistory,
      memberIds,
      preserve,
    });

    if (typeof res.avatarUrl !== 'undefined') newAttrs.avatarUrl = res.avatarUrl;

    const group = this.host.models.Group.inject({ id, ...newAttrs });

    if (metadata) {
      await this.host.metadata.update(id, metadata, group.organizationId);
    }

    return group;
  }

  @reusePromise()

  /**
   * Find a group
   * @param  {string} id - group ID
   * @param  {Boolean} options.bypassCache - Force fetch from server
   * @param  {Boolean} options.ignoreNotFound - Ignore if not found - promise will return null
   * @return {Promise.<Group,Error>} - a promise with the group, or error
   */
  async find(id: string, options = {}) {
    options = defaults(options, {
      bypassCache: false,
      ignoreNotFound: false,
      includeMembers: false,
    });
    this.host.requireUser();

    let group = null;

    // local first
    if (!options.bypassCache) {
      group = this.host.models.Group.get(id);
      if (group && !group.$placeholder) {
        await this.__ensureEntitiesLoaded(group.id, options);
        return group;
      }
    }

    try {
      group = await this.__find(id);
    } catch (err) {
      if (options.ignoreNotFound) {
        return null;
      } else {
        return Promise.reject(err);
      }
    }

    await this.__ensureEntitiesLoaded(group.id, options);

    return group;
  }

  @reusePromise()
  async __find(id: string) {
    let group = this.host.models.Group.get(id);
    let data;

    try {
      data = await this.host.api.groups.find(id);
    } catch (err) {
      if (group) {
        this.host.models.Group.removePlaceholder({ entity: group });
      }
      return Promise.reject(err);
    }

    if (this.config.condensedReplays && data.entity) {
      const { entity: entityAttrs } = data;
      group = this.host.conversations.__injectCounterParty({
        conversationId: data.conversation_id,
        entityAttrs,
        entityType: 'group',
        organizationId: data.organization_id,
      });

      this.host.models.Group.removePlaceholder({ entity: group, attrs: entityAttrs });

      return group;
    } else {
      this.__removeForumMembers(data);
      const { metadata } = data;

      group = this.host.models.Group.inject(data);

      const { organizationId } = group;
      if (metadata && organizationId) {
        this.host.metadata.__injectMetadata(id, organizationId, metadata);
      }

      this.host.models.Group.removePlaceholder({ entity: group, attrs: data });

      return group;
    }
  }

  __removeForumMembers(data) {
    const { members: memberIds } = data;
    const groupType = this.__extractGroupTypeFromAttrs(data);
    if (groupType === GroupType.FORUM) {
      if (memberIds && memberIds.length === 0) {
        // the server always returns `members: []` for forums; represent those as null (unfetched) instead
        delete data['members'];
      }
    } else if (groupType === GroupType.ROLE_ASSIGNMENT) {
      // ignore this kind of group
    } else {
      if (!memberIds) data['members'] = [];
    }
  }

  __extractGroupTypeFromAttrs(attrs) {
    const id = attrs.id || attrs['token'];
    const organizationId = attrs.organizationId || attrs['organization_key'];
    const metadataEntity = organizationId ? this.host.metadata.get(id, organizationId) : null;
    const metadata = attrs.metadata || (metadataEntity ? metadataEntity.data : null);
    let groupType = GroupType.GROUP;

    if (metadata && metadata['meta_type'] === 'role_assignment') {
      groupType = GroupType.ROLE_ASSIGNMENT;
    } else if (metadata && metadata['feature_service'] === 'role') {
      groupType = GroupType.ROLE_P2P;
    } else if (metadata && metadata['feature_service'] === 'escalation') {
      groupType = GroupType.ESCALATION;
    } else if (metadata && metadata['feature_service'] === 'patient_care') {
      groupType = GroupType.PATIENT_CARE;
    } else if (metadata && metadata['feature_service'] === 'patient_messaging') {
      groupType = GroupType.PATIENT_MESSAGING;
    } else if (metadata && metadata['feature_service'] === 'teams') {
      const { meta_type: metaType } = metadata;
      if (metaType === 'activated_team') {
        groupType = GroupType.ACTIVATED_TEAM;
      } else if (metaType === 'intra_team') {
        groupType = GroupType.INTRA_TEAM;
      }
    } else if (attrs.isPublic || attrs['is_public']) {
      groupType = GroupType.FORUM;
    }

    return groupType;
  }

  @reusePromise()
  async __ensureEntitiesLoaded(id: string, options) {
    const group = this.host.models.Group.get(id);

    const promises = [this.ensureMetadata(group)];

    if (options.includeMembers) {
      promises.push(this.ensureMembers(group));
    }

    await Promise.all(promises);
  }

  @reusePromise()
  async findAll({ includeMembers = false } = {}) {
    if (this._fetched) return this.getAll();

    let organizations = this.host.models.Organization.getAll();
    if (organizations.length === 0) {
      organizations = await this.host.organizations.findAll();
    }

    const { results } = await this.host.search.query({
      version: this.config.allowSearchParity ? 'SEARCH_PARITY' : 'LEGACY',
      types: ['group'],
      organizationIds: organizations.map((o) => o.id),
      query: { displayName: '' },
      followContinuations: true,
    });
    const groups = results.map((searchResult) => searchResult.entity);

    if (includeMembers) {
      const byOrgId = {};
      for (const { organizationId, memberIds } of groups) {
        for (const memberId of memberIds) {
          if (!byOrgId[organizationId]) byOrgId[organizationId] = [];
          if (!byOrgId[organizationId].includes(memberId)) {
            byOrgId[organizationId].push(memberId);
          }
        }
      }

      const extraPromises = [];
      for (const [organizationId, memberIds] of Object.entries(byOrgId)) {
        extraPromises.push(this.host.users.ensureUsers(memberIds, organizationId));
      }

      await Promise.all(extraPromises);
    }

    this._fetched = true;

    return groups;
  }

  @reusePromise()
  async findAllWithSpecificMembers(
    memberIds: string | string[] | Object[],
    organizationId: string | Object,
    options: Object | null | undefined
  ) {
    organizationId = this._resolveModelId(organizationId);
    memberIds = arrayWrap(memberIds).map(this._resolveModelId).map(this.host.roles.__resolveRoleId);

    let groups = this.__findAllLocalWithSpecificMembers(memberIds, organizationId, options);

    const localOnly = options ? !!options.localOnly : false;
    if (!localOnly && groups.length === 0) {
      await this.findAll();
      groups = this.__findAllLocalWithSpecificMembers(memberIds, organizationId, options);
    }

    return groups;
  }

  /**
   * Ensures all members in group are loaded, and if not, fetches them from the server
   * @param  {Group} group
   */
  async ensureMembers(group: Object, options = {}) {
    const isRoleAssignment = group.groupType === GroupType.ROLE_ASSIGNMENT;

    if (
      !group.memberIds ||
      (!!group.memberIds && group.memberCount > 0 && group.memberIds?.length === 0)
    ) {
      if (group.groupType === GroupType.FORUM) {
        const { memberIds } = await this.findMemberIds(group.id);

        if (memberIds) {
          this.host.models.Group.inject({ id: group.id, members: memberIds });
        }
      } else if (isRoleAssignment) {
        // ignore this kind of group
      } else {
        await this.__find(group.id);
      }
    }

    const attrs = options.attrs ? { ...options.attrs } : {};
    const memberIds = isRoleAssignment && !group.memberIds ? [] : group.memberIds;
    attrs.organizationId = group.organizationId;
    await this.ensureMembersWithIds(group.id, memberIds, { ...options, attrs });
  }

  async ensureForumMembers(group: Object) {
    if (group.groupType === GroupType.FORUM && group.memberCount > 50) {
      const memberIds = await this.findAllMemberIds(group.id);

      if (memberIds) {
        this.host.models.Group.inject({ id: group.id, members: memberIds });
      }
    } else if (group.groupType === GroupType.FORUM) {
      const { memberIds } = await this.findMemberIds(group.id);

      if (memberIds) {
        this.host.models.Group.inject({ id: group.id, members: memberIds });
      }
    }
  }

  async ensureMembersWithIds(id: string, memberIds: string[], options = {}) {
    this.host.requireUser();

    memberIds = without(memberIds, this.host.currentUserId);
    if (memberIds.length === 0) return;

    options = defaults(options, { onlyPlaceholder: false });

    const ensuredMembers = await Promise.all(
      memberIds.map((memberId) => this.__ensureMember(memberId, options)).filter(Boolean)
    );

    if (ensuredMembers.length > 0) this.host.models.Group.touch(id);
  }

  __ensureMember(memberId: string, options = {}) {
    if (!this.host.models.User.shouldEnsure(memberId, options.onlyPlaceholder)) return null;

    const user = this.host.models.User.get(memberId);
    if (user && options.attrs) {
      this.host.models.User.injectPlaceholder({ id: user.id, ...options.attrs });
    }
    return this.host.models.User.waitForEnsuredEntity(memberId, options);
  }

  async ensureMetadata(group: Object) {
    const { groupType, id, organizationId } = group;
    const metadataEntity = organizationId ? this.host.metadata.get(id, organizationId) : null;

    if (!metadataEntity) {
      if (groupType === GroupType.FORUM || groupType === GroupType.ROLE_ASSIGNMENT) {
        // ignore these kinds of groups
      } else {
        await this.__find(id);
      }
    }
  }

  getAll() {
    return this.host.models.Group.getAll();
  }
  getById(id: string) {
    return this.host.models.Group.get(id);
  }
  getMultiById(ids: Array<string>) {
    return this.host.models.Group.getMulti(ids);
  }

  // TODO make a property group.memberIdsString - contains sorted IDs, easier compare
  __findAllLocalWithSpecificMembers(
    memberIds: string[],
    organizationId: string,
    {
      exact = true,
      includeCurrentUser = true,
      includeForums = true,
      includeRoles = true,
      senderId = null,
    } = {}
  ) {
    const { currentUser, currentUserId } = this.host;
    const myBotUserIds = currentUser.roles.map(({ botUserId }) => botUserId);
    if (senderId && myBotUserIds.includes(senderId)) {
      memberIds = memberIds.slice();
      memberIds.push(senderId, currentUserId);
    } else if (includeCurrentUser && !memberIds.includes(currentUserId)) {
      memberIds = memberIds.slice();
      memberIds.push(currentUserId);
    }

    const matchesCurrentUser = memberIds.includes(currentUserId);
    const memberCnt = memberIds.length;
    const all = this.host.models.Group.getAll();
    const groups = [];

    for (const group of all) {
      if (!includeForums && group.groupType === GroupType.FORUM) continue;

      const { memberIds: groupMemberIds, organizationId: groupOrgId } = group;
      if (groupOrgId !== organizationId || !groupMemberIds) continue;

      let hasAllMembers = true;
      for (const memberId of memberIds) {
        if (!groupMemberIds.includes(memberId)) {
          hasAllMembers = false;
          break;
        }
      }
      if (!hasAllMembers) continue;

      if (exact) {
        if (!senderId && !matchesCurrentUser) continue;

        let matchCount = memberCnt;
        if (includeRoles) {
          for (const botUserId of myBotUserIds) {
            if (group.memberIds.includes(botUserId)) {
              matchCount++;
            }
          }
        }

        if (matchCount !== group.memberIds.length) continue;
      }

      groups.push(group);
    }

    return groups;
  }

  async destroy(id: string | Object) {
    this.host.requireUser();
    id = this._resolveModelId(id);

    const operationResult = await this.host.api.groups.destroy(id);

    if (!operationResult) {
      throw new errors.NotFoundError(this.host.models.Group.name, id);
    }
  }

  @reusePromise()
  async findMemberIds(id: string | Object, { continuation } = {}) {
    this.host.requireUser();
    id = this._resolveModelId(id);
    const { members, metadata } = await this.host.api.groups.findMemberIds(id, { continuation });
    const memberIds = members.map(({ token }) => token);

    return {
      memberIds,
      metadata,
    };
  }

  async findAllMemberIds(id: string | Object) {
    let continuation;
    const currentMemberIds = [];
    this.host.requireUser();
    id = this._resolveModelId(id);
    do {
      const { memberIds, metadata } = await this.findMemberIds(id, {
        continuation,
      });
      continuation = metadata.continuation;
      currentMemberIds.push(...memberIds);
    } while (continuation);
    return currentMemberIds;
  }

  @reusePromise()
  async findMembers(id: string, { continuation } = {}) {
    this.host.requireUser();
    const { members, metadata } = await this.host.api.groups.findMemberIds(id, { continuation });

    return {
      members,
      metadata,
    };
  }

  async findAllMembers(id: string) {
    let continuation;
    const currentMembers = [];
    this.host.requireUser();
    do {
      const { members, metadata } = await this.findMembers(id, {
        continuation,
      });
      continuation = metadata.continuation;
      currentMembers.push(...members);
    } while (continuation);

    return Camelizer.camelizeObject(currentMembers);
  }

  async addMembers(id: string | Object, memberIds: string | string[] | Object[]) {
    let existingMemberIds;
    this.host.requireUser();
    id = this._resolveModelId(id);
    memberIds = arrayWrap(memberIds).map(this._resolveModelId).map(this.host.roles.__resolveRoleId);
    const group = this.getById(id);

    if (group && group.groupType === GroupType.ROLE_P2P) {
      throw new errors.PermissionDeniedError('role p2p group', group.id, 'add users');
    }

    if (group) {
      if (group.groupType === 'FORUM') {
        existingMemberIds = await this.findAllMemberIds(id);
      } else {
        existingMemberIds = group.memberIds;
      }
      memberIds = difference(memberIds, existingMemberIds);
    }

    if (memberIds.length === 0) return;

    const newGroup = await this.host.api.groups.addMembers(id, memberIds);

    if (this.config.condensedReplays) {
      let conversation = this.host.conversations.getById(newGroup.conversation_id);
      if (!conversation) {
        const rawConversation = await this.host.api.conversations.find(newGroup.conversation_id);

        if (!rawConversation) {
          throw new errors.NotFoundError(this.host.models.Conversation.name, id);
        }

        conversation = this.host.conversations.__injectConversation(rawConversation);
      }

      if (newGroup.unread_message_count) {
        conversation.unreadCount = newGroup.unread_message_count;
      }

      if (newGroup.unread_priority_message_count) {
        conversation.unreadPriorityCount = newGroup.unread_priority_message_count;
      }

      this.host.models.Conversation.inject(conversation);
    }
  }

  async removeMembers(id: string | Object, memberIds: string | string[] | Object[]) {
    this.host.requireUser();
    id = this._resolveModelId(id);
    memberIds = arrayWrap(memberIds).map(this._resolveModelId).map(this.host.roles.__resolveRoleId);
    const group = this.getById(id);
    let existingMemberIds;

    if (group) {
      const { groupType, id: groupId, p2pSender } = group;
      const botUserId = p2pSender && p2pSender.$entityType === 'role' && p2pSender.botUserId;

      if (groupType === GroupType.ROLE_P2P && botUserId && memberIds.includes(botUserId)) {
        throw new errors.PermissionDeniedError('role p2p group', groupId, 'remove an on-duty user');
      }

      if (groupType === GroupType.FORUM) {
        existingMemberIds = await this.findAllMemberIds(id);
      } else {
        existingMemberIds = group.memberIds;
      }
      memberIds = intersection(memberIds, existingMemberIds);
    }

    if (memberIds.length === 0) return;

    // removeMembers doesn't remove members from local DB
    // it will wait for a bang event to do so
    // For now, we are using /v2/group/:id/members/remove call for both conversation store on/off cases. We will eventually want to use /v5/group/:id/members/remove call upon completion of https://tigertext.atlassian.net/browse/TS-6515 server ticket.
    await this.host.api.groups.removeMembers(id, memberIds);
  }

  async addMember(id: string | Object, memberId: string | Object) {
    return this.addMembers(id, [memberId]);
  }

  async removeMember(id: string | Object, memberId: string | Object) {
    return this.removeMembers(id, [memberId]);
  }

  // TODO TEST
  async leave(id: string | Object) {
    id = this._resolveModelId(id);
    const group = this.getById(id);
    if (!group) return;

    const { currentUser, currentUserId } = this.host;
    const { groupType, p2pSender } = group;
    const { memberIds } = group;
    const myRoleBotIds = currentUser.roles.map(({ botUserId }) => botUserId);
    let leavingAs = [currentUserId];

    if (groupType === GroupType.ROLE_P2P && p2pSender) {
      leavingAs = [p2pSender.$entityType === 'user' ? p2pSender.id : p2pSender.botUserId];
    } else if (memberIds) {
      for (const memberId of memberIds) {
        if (myRoleBotIds.includes(memberId)) {
          leavingAs.push(memberId);
        }
      }
    }

    await this.removeMembers(id, leavingAs);
  }

  reactToBangEvent({ data }) {
    data = jsonCloneDeep(data);
    const eventId = data['bang_id'] || `${data['sort_number']}`;
    return this.__handleBang({ data, eventId });
  }

  __handleBang(bangData) {
    const action = bangData.data['event'];

    if (action === 'added') {
      this.__addedToGroup(bangData);
    } else if (action === 'removed') {
      this.__removedFromGroup(bangData);
    } else if (action === 'teams_request_accepted') {
      this.__acceptedToTeam(bangData);
    } else if (action === 'teams_request_declined' || action === 'teams_request_declined_full') {
      this.__deniedToTeam(bangData);
    } else if (EscalationChangeAction.fromServer(action)) {
      this.__handleEscalationBang(bangData);
    } else if (action === 'ended_voip_call' || action === 'missed_voip_call') {
      this.__handleEndedCall(bangData);
    } else if (action === 'vwr') {
      this.__handleVwrBang(bangData);
    } else if (action === 'role_transition_opt_out') {
      this.__handleRoleTransitionBang(bangData);
    } else if (action === 'chose_action') {
      this.__handleReactionBang(bangData);
    } else if (action === 'disabled_auto_forward' || action === 'enabled_auto_forward') {
      this.__handleAutoForward(bangData);
    }
  }

  async __handleAutoForward({ data, eventId }) {
    const {
      caller_entity: callerEntity,
      created_on: createdAt,
      expire_in: expireIn,
      event: event,
      organization_id: organizationId,
      sort_number: sortNumber,
      subject_entity: subjectEntity,
      target_entity: targetEntity,
      ttl,
    } = data;
    const { token: callerId, display_name: displayName } = callerEntity;
    const { token: groupId } = targetEntity;
    let conversationId;
    if (this.config.condensedReplays) {
      conversationId = data['conversation_id'];
    } else {
      conversationId = this.host.conversations.getConversationKey('group', groupId, organizationId);
    }

    const currentAction = event === 'enabled_auto_forward' ? 'enabled' : 'disabled';

    if (
      !callerEntity ||
      !callerEntity['token'] ||
      !targetEntity ||
      !targetEntity['token'] ||
      !subjectEntity ||
      !subjectEntity['token']
    )
      return;

    const group = this.host.models.Group.get(targetEntity['token']);
    if (!group) return;

    this.host.models.Message.inject({
      body: `${displayName} has ${currentAction} Auto-Forward and designated this Team as a recipient of their forwarded messages.`,
      conversationId,
      counterPartyId: groupId,
      counterPartyType: 'group',
      createdAt,
      expireIn,
      groupId,
      id: `bang:${eventId}`,
      inTimeline: true,
      isOutgoing: false,
      recipient: this.host.currentUser,
      messageType: MessageType.AUTO_FORWARD,
      sortNumber,
      senderId: callerId,
      ttl,
    });
  }

  async __handleReactionBang({ data, eventId }) {
    const {
      caller_entity: callerEntity,
      created_on: createdAt,
      expire_in: expireIn,
      organization_id: organizationId,
      reaction,
      sort_number: sortNumber,
      subject_entity: subjectEntity,
      target_entity: targetEntity,
      ttl,
    } = data;
    if (!callerEntity || !createdAt || !reaction || !sortNumber || !subjectEntity || !targetEntity)
      return;

    const { token: groupId } = targetEntity;
    let conversationId;
    if (this.config.condensedReplays) {
      conversationId = data['conversation_id'];
    } else {
      conversationId = this.host.conversations.getConversationKey('group', groupId, organizationId);
    }

    let group = this.host.models.Group.get(groupId);
    if (!group) {
      group = this.host.models.Group.injectPlaceholder({
        ...targetEntity,
        conversationId,
        organizationId,
      });
    }

    const { display_name: displayName, token: callerId } = callerEntity;
    const { token: messageId } = subjectEntity;
    const alertMessage = this.host.models.Message.get(messageId);

    this.host.models.Message.inject({
      alertDetails: {
        reaction,
        isPrimaryAction: reaction === alertMessage?.alertDetails?.primaryAction,
      },
      body: `${
        callerId === this.host.currentUserId ? 'You' : displayName
      } chose "${reaction}" at ${moment(createdAt).format('h:mma')}`,
      conversationId,
      counterPartyId: groupId,
      counterPartyType: 'group',
      createdAt,
      expireIn,
      groupId,
      id: `bang:${eventId}`,
      inTimeline: true,
      isOutgoing: false,
      recipient: this.host.currentUser,
      messageType: MessageType.REACTION,
      sortNumber,
      senderId: callerId,
      ttl,
    });
  }

  addMembershipChangelogForEmptyRoleBeingRemovedOnOptIn(
    optedOutRoleName: string,
    optedOutRoleId: string,
    organizationId: string,
    eventDateTime: string,
    group: Record<string, unknown>,
    eventId: string,
    sortNumber: string,
    expireIn: number,
    ttl: number
  ) {
    const roleThatIsLeavingConversationActor = {
      displayName: optedOutRoleName,
      id: optedOutRoleId,
      organizationId,
      type: 'role',
    };

    this.host.conversations.addMembershipChangeLog(
      {
        action: 'LEAVE',
        actionTime: eventDateTime,
        actor: roleThatIsLeavingConversationActor,
        createdAt: eventDateTime,
        expireIn,
        group,
        id: eventId,
        members: [roleThatIsLeavingConversationActor],
        memberIds: [roleThatIsLeavingConversationActor.id],
        sortNumber: Number(sortNumber),
        ttl,
      },
      false
    );
  }

  addMembershipChangeLogForUserOptingOutOfRtuRole(
    idOfUserWhoOptedOut: string,
    nameOfUserWhoOptedOutOfRole: string,
    organizationId: string,
    optedOutRoleName: string,
    optedOutRoleId: string,
    eventDateTime: string,
    group: Record<string, unknown>,
    eventId: string,
    sortNumber: string,
    expireIn: number,
    ttl: number
  ) {
    const userWhoOptedOut = this.host.models.User.get(idOfUserWhoOptedOut);

    const userOptingOutOfRoleActor = {
      displayName: nameOfUserWhoOptedOutOfRole,
      id: idOfUserWhoOptedOut,
      organizationId,
      type: 'user',
    };

    const roleBeingOptedOutOfActor = {
      displayName: optedOutRoleName,
      id: optedOutRoleId,
      organizationId,
      type: 'role',
    };

    if (!userWhoOptedOut) {
      this.host.models.User.injectPlaceholder(roleBeingOptedOutOfActor);
    }

    this.host.conversations.addMembershipChangeLog(
      {
        action: 'OPT_OUT',
        actionTime: eventDateTime,
        actor: roleBeingOptedOutOfActor,
        createdAt: eventDateTime,
        expireIn,
        group,
        id: eventId,
        members: [userOptingOutOfRoleActor],
        memberIds: [userOptingOutOfRoleActor.id],
        sortNumber: Number(sortNumber),
        ttl,
      },
      false
    );
  }

  async __handleRoleTransitionBang({ data, eventId }) {
    const {
      created_on: eventDateTime,
      caller_entity: callerEntity,
      expire_in: expireIn,
      organization_id: organizationId,
      sort_number: sortNumber,
      subject_entity: subjectEntity,
      target_entity: targetEntity,
      ttl,
    } = data;

    const { token: groupId } = targetEntity;
    const group = this.host.models.Group.get(groupId);

    if (group) {
      const { display_name: nameOfUserWhoOptedOutOfRole, token: idOfUserWhoOptedOut } =
        callerEntity;
      const { display_name: optedOutRoleName, token: optedOutRoleId } = subjectEntity;

      if (callerEntity.token === subjectEntity.token) {
        this.addMembershipChangelogForEmptyRoleBeingRemovedOnOptIn(
          optedOutRoleName,
          optedOutRoleId,
          organizationId,
          eventDateTime,
          group,
          eventId,
          sortNumber,
          expireIn,
          ttl
        );
      } else {
        this.addMembershipChangeLogForUserOptingOutOfRtuRole(
          idOfUserWhoOptedOut,
          nameOfUserWhoOptedOutOfRole,
          organizationId,
          optedOutRoleName,
          optedOutRoleId,
          eventDateTime,
          group,
          eventId,
          sortNumber,
          expireIn,
          ttl
        );
      }
    }
  }

  async __handleVwrBang({ data, eventId }) {
    const targetEntity = data['target_entity'];
    const createdAt = data['created_on'];
    const expireIn = data['expire_in'];
    const groupId = targetEntity['token'];
    const organizationId = data['organization_id'];
    const ttl = data['ttl'];

    const { currentUser } = this.host;
    let parsedData, conversationId;
    try {
      parsedData = JSON.parse(data?.data);
    } catch (err) {
      console.error(err);
      parsedData = {};
    }
    const isUser = targetEntity?.type?.includes('account');
    const isGroup = targetEntity?.type?.includes('group');

    if (!isGroup && !isUser) {
      console.error('No staff assigned for this event');
      return;
    }

    if (this.config.condensedReplays) {
      conversationId = data['conversation_id'];
    } else {
      if (isGroup) {
        // get group and set conv id from group
        let group = this._resolveEntity(groupId, 'group');
        if (!group) {
          group = await this.host.groups.find(groupId);
        }
        conversationId = !group.conversationId
          ? this.host.conversations.getConversationKey('group', group.id, group.organizationId)
          : group.conversationId;
      } else if (isUser) {
        // find p2p chat and set conv id from that
        conversationId = this.host.conversations.getConversationKey(
          'user',
          targetEntity.token,
          organizationId
        );
      }
    }

    if (!conversationId) {
      console.error('No conversation ID found');
      return;
    }

    const injectVwrBang = ({
      body,
      messageType,
      metadata,
      subType,
    }: {
      body: string;
      messageType: string;
      metadata: { payload: Object; mimetype: string };
      subType: string;
    }) => {
      const conversation = this.host.models.Conversation.get(conversationId);
      const sortNumber = +data['sort_number'];
      const inTimeline =
        this.config.condensedReplays ||
        this.host.conversations.__isBangInTimeline(sortNumber, conversation?.isLive);
      const messagePayload = {
        body,
        conversationId,
        counterPartyId: groupId,
        counterPartyType: 'group',
        createdAt,
        expireIn,
        groupId,
        id: `bang:${eventId}`,
        inTimeline,
        isOutgoing: false,
        recipient: currentUser,
        ...(messageType ? { messageType } : {}),
        ...(metadata ? { metadata } : {}),
        ...(subType ? { subType } : {}),
        sortNumber,
        senderId: null,
        ttl,
      };

      this.host.models.Message.inject(messagePayload);
    };

    const injectChatbotMessage = () => {
      injectVwrBang({
        body: parsedData?.body,
        subType: MessageSubType.CHATBOT_MESSAGE,
        metadata: {
          payload: parsedData,
          mimetype: 'application/json',
        },
      });
    };

    const injectVisitAssignment = async (organizationId: string) => {
      const staff = await this.host.users.find(parsedData.assignee_token, { organizationId });
      if (!staff) return;

      const visitorFullName = parsedData?.visitor_full_name;
      const staffDisplayName = staff.displayName;

      injectVwrBang({
        body: `${staffDisplayName} has been assigned to ${visitorFullName}`,
        messageType: 'VISIT_ASSIGNMENT',
      });
    };

    const injectVisitCompleted = () => {
      if (parsedData.status !== 'COMPLETED') return;

      const visit = this.host.models.VirtualWaitingRoomVisit.get(parsedData.visit_id);
      if (!visit) return;

      const visitorFullName = visit.visitorFullName;

      injectVwrBang({
        body: `${visitorFullName}'s session has been completed`,
        messageType: 'VISIT_STATUS_UPDATE',
      });
    };

    const injectProvidedNotified = () => {
      const {
        provider: { display_name },
      } = parsedData;

      injectVwrBang({
        body: `${display_name} Notified`,
        messageType: 'CALL_PROVIDER_NOTIFIED',
      });
    };

    const injectCallProviderEnded = () => {
      const {
        provider: { display_name },
      } = parsedData;

      injectVwrBang({
        body: `${display_name} has ended this call`,
        messageType: 'CALL_PROVIDER_ENDED',
      });
    };

    const injectStaffCancelledCall = () => {
      const {
        vwr_staff: { display_name },
      } = parsedData;

      injectVwrBang({
        body: `${display_name} has cancelled the invite`,
        messageType: 'CALL_STAFF_CANCELLED',
      });
    };

    const injectCallProviderCompleted = () => {
      const {
        visitor: { full_name },
      } = parsedData;

      injectVwrBang({
        body: `Your Visit with ${full_name} is marked complete`,
        messageType: 'CALL_PROVIDER_COMPLETED',
      });
    };

    const injectCallLinkExpired = () => {
      injectVwrBang({
        body: 'The audio/video link expired after 5 minutes',
        messageType: 'CALL_LINK_EXPIRED',
      });
    };

    switch (parsedData.type) {
      case 'CHATBOT_MESSAGE':
        return injectChatbotMessage();
      case 'VISIT_ASSIGNMENT':
        return await injectVisitAssignment(organizationId);
      case 'VISIT_STATUS_UPDATE':
        return injectVisitCompleted();
      case 'CALL_PROVIDER_NOTIFIED':
        return injectProvidedNotified();
      case 'CALL_PROVIDER_ENDED':
        return injectCallProviderEnded();
      case 'CALL_STAFF_CANCELLED':
        return injectStaffCancelledCall();
      case 'CALL_LINK_EXPIRED':
        return injectCallLinkExpired();
      case 'CALL_PROVIDER_COMPLETED':
        return injectCallProviderCompleted();
    }
  }

  async __handleEndedCall({ data, eventId, alwaysAddToTimeline }) {
    if (data.target_entity?.metadata?.feature_service === 'vwr') {
      return;
    }

    const {
      event,
      participants_duration: participantsDuration,
      caller_entity: callerEntity,
      conversation_id: conversationId,
      created_on: createdAt,
      expire_in: expireIn,
      is_video: isVideo,
      organization_id: organizationId,
      sort_number: sortNumber,
      subject_entity: callEntity,
      target_entity: calleeEntity,
      ttl,
    } = data;
    const action = event === 'ended_voip_call' ? 'CALL_CHANGE' : 'MISSED_CALL';

    let durationsByParticipant;
    try {
      durationsByParticipant = JSON.parse(participantsDuration);
    } catch (err) {
      return;
    }

    if (!durationsByParticipant || isEmpty(durationsByParticipant)) return;
    if (action === 'CALL_CHANGE' && isEmpty(durationsByParticipant[this.host.currentUserId]))
      return;
    if (action === 'MISSED_CALL' && callerEntity && callerEntity.token === this.host.currentUserId)
      return;

    let duration, userStartTime;
    if (action === 'CALL_CHANGE') {
      duration = durationsByParticipant[this.host.currentUserId].duration;
      userStartTime = durationsByParticipant[this.host.currentUserId].start_time;
    } else if (action === 'MISSED_CALL') {
      duration = Object.values(durationsByParticipant)[0].duration;
      userStartTime = Object.values(durationsByParticipant)[0].start_time;
    }

    if (!duration) return; // current user did not participate in this call, so we can ignore it

    // if caller or callee were deleted -- just ignore
    if (!get(callerEntity, 'token') || !get(calleeEntity, 'token')) return;

    const [caller, callee] = [callerEntity, calleeEntity].map((entity) => {
      const { display_name: displayName, token: id, type } = entity;
      return { displayName, id, organizationId, type };
    });

    let group;
    if (callee.type.includes('group')) {
      group = this.host.models.Group.get(callee.id);
    } else if (callee.type.includes('account')) {
      const groups = await this.findAllWithSpecificMembers([callee.id, caller.id], organizationId);
      const isOnlyP2PGroup = groups.length === 1 && groups[0].memberCount === 2;
      group = isOnlyP2PGroup && groups[0];
    }

    this.host.conversations.addCallChangeLog({
      action,
      alwaysAddToTimeline,
      callee,
      callEntity,
      caller,
      conversationId,
      createdAt,
      duration,
      expireIn,
      group,
      id: eventId,
      isVideo,
      organizationId,
      sortNumber: +sortNumber,
      ttl,
      userStartTime,
    });
  }

  __addedToGroup({ data, eventId, alwaysAddToTimeline }) {
    const callerEntity = data['caller_entity'];
    const subjectEntity = data['subject_entity'];
    const targetEntity = data['target_entity'];
    const organizationId = data['organization_id'];

    // group creator, member or group were deleted -- just ignore
    if (
      !callerEntity ||
      !callerEntity['token'] ||
      !targetEntity ||
      !targetEntity['token'] ||
      !subjectEntity ||
      !subjectEntity['token']
    )
      return;

    if (subjectEntity && !subjectEntity.display_name) {
      alwaysAddToTimeline = false;
    }

    // this event relies on the existence of the group in the local db
    const group = this.host.models.Group.get(targetEntity['token']);
    if (!group) return;

    const actor = {
      displayName: callerEntity['display_name'],
      id: callerEntity['token'],
      organizationId,
      type: 'user',
    };
    const memberToAdd = {
      displayName: subjectEntity['display_name'],
      id: subjectEntity['token'],
      organizationId,
      type: 'user',
    };

    const user = this.host.models.User.get(subjectEntity['token']);
    if (user === null) {
      this.host.models.User.injectPlaceholder({
        id: subjectEntity['token'],
        displayName: subjectEntity['display_name'],
        removedFromOrg: false,
        mostRecentOrgStatusBangSortNumber: data['sort_number'],
      });
    } else if (user && data['sort_number'] > user.mostRecentOrgStatusBangSortNumber) {
      this.host.models.User.inject({
        id: subjectEntity['token'],
        removedFromOrg: false,
        mostRecentOrgStatusBangSortNumber: data['sort_number'],
      });
    }

    const actorModel = this.host.models.User.get(actor.id);
    if (actorModel === null) {
      this.host.models.User.injectPlaceholder(actor);
    }

    let isRoleAmbiguous = true;
    if (callerEntity?.metadata?.feature_service === 'role') {
      isRoleAmbiguous = false;
    } else if (actorModel) {
      isRoleAmbiguous = !!(actorModel.$placeholder && !actorModel.isRoleBot);
    }

    let action;
    if (
      (actorModel && actorModel.isRoleBot) ||
      (callerEntity.metadata && callerEntity.metadata.feature_service === 'role')
    ) {
      action = 'OPT_IN';
    } else {
      if (memberToAdd.id === actor.id) {
        action = 'JOIN';
      } else {
        action = 'ADD';
      }
    }
    const actionTime = data['action_time'] || data['created_on'];

    const newEventData = {
      action,
      actionTime,
      actor,
      alwaysAddToTimeline,
      createdAt: data['created_on'],
      expireIn: data['expire_in'],
      group,
      id: eventId,
      members: [memberToAdd],
      memberIds: [memberToAdd.id],
      patientContextId: group.patientContextId,
      sortNumber: +data['sort_number'],
      ttl: data['ttl'],
    };

    // add a change log anyway, but fire membership:change only if there was an actual change
    this.host.conversations.addMembershipChangeLog(newEventData, {
      // TODO: Remove when TS-8706 is done
      placeholder: isRoleAmbiguous,
    });

    if (action === 'OPT_IN' || !group.memberIds || !group.memberIds.includes(memberToAdd.id)) {
      this.host.models.Group.touch(group);
      this.emit('membership:change', newEventData);
    }
  }

  __removedFromGroup({ data, eventId, alwaysAddToTimeline }) {
    const callerEntity = data['caller_entity'];
    const subjectEntity = data['subject_entity'];
    const targetEntity = data['target_entity'];
    const organizationId = data['organization_id'];
    let removedFromGroup = false;

    if (
      data.reason === 'leave_org' &&
      !!targetEntity &&
      !callerEntity['token'] &&
      !subjectEntity['token']
    ) {
      const user = this.host.models.User.get(data['reason_subject_token']);
      if (user === null) {
        this.host.models.User.injectPlaceholder({
          id: data['reason_subject_token'],
          removedFromOrg: true,
          mostRecentOrgStatusBangSortNumber: data['sort_number'],
        });
      } else if (data['sort_number'] > user.mostRecentOrgStatusBangSortNumber) {
        this.host.models.User.inject({
          id: data['reason_subject_token'],
          removedFromOrg: true,
          mostRecentOrgStatusBangSortNumber: data['sort_number'],
        });
      }
      return;
    }

    // group creator, member or group were deleted -- just ignore
    if (
      !callerEntity ||
      !callerEntity['token'] ||
      !targetEntity ||
      !targetEntity['token'] ||
      !subjectEntity ||
      !subjectEntity['token']
    )
      return;

    const group = this.host.models.Group.get(targetEntity['token']);
    if (!group) return;

    if (
      data.reason &&
      (data.reason === 'leave_org' || data.reason === 'delete_account') &&
      (data['reason_subject_token'] || data['subject_entity'].token)
    ) {
      alwaysAddToTimeline = false;
      removedFromGroup = true;
      const userToRemoveToken = data['reason_subject_token'] || data['subject_entity'].token;
      const user = this.host.models.User.get(userToRemoveToken);
      if (user === null) {
        this.host.models.User.injectPlaceholder({
          id: userToRemoveToken,
          removedFromOrg: true,
          mostRecentOrgStatusBangSortNumber: data['sort_number'],
        });
      } else if (data['sort_number'] > user.mostRecentOrgStatusBangSortNumber) {
        this.host.models.User.inject({
          id: userToRemoveToken,
          removedFromOrg: true,
          mostRecentOrgStatusBangSortNumber: data['sort_number'],
        });
      }
    }

    const actor = {
      displayName: callerEntity['display_name'],
      id: callerEntity['token'],
      organizationId,
      type: 'user',
    };
    const memberToRemove = {
      displayName: subjectEntity['display_name'],
      id: subjectEntity['token'],
      organizationId,
      type: 'user',
    };

    const actorModel = this.host.models.User.get(actor.id);
    if (actorModel === null) {
      this.host.models.User.injectPlaceholder(actor);
    }

    let isRoleAmbiguous = true;
    if (callerEntity?.metadata?.feature_service === 'role') {
      isRoleAmbiguous = false;
    } else if (actorModel) {
      isRoleAmbiguous = !!(actorModel.$placeholder && !actorModel.isRoleBot);
    }

    let action;
    if (
      (actorModel && actorModel.isRoleBot) ||
      (callerEntity.metadata && callerEntity.metadata.feature_service === 'role')
    ) {
      action = 'OPT_OUT';
    } else {
      if (memberToRemove.id === actor.id) {
        action = 'LEAVE';
      } else {
        action = 'REMOVE';
      }
    }

    const actionTime = data['action_time'] || data['created_on'];

    const newEventData = {
      action,
      actionTime,
      actor,
      alwaysAddToTimeline,
      createdAt: data['created_on'],
      expireIn: data['expire_in'],
      group,
      id: eventId,
      members: [memberToRemove],
      memberIds: [memberToRemove.id],
      removedFromGroup,
      sortNumber: +data['sort_number'],
      ttl: data['ttl'],
    };

    // add a change log anyway, but fire membership:change only if there was an actual change
    this.host.conversations.addMembershipChangeLog(newEventData, {
      // TODO: Remove when TS-8706 is done
      placeholder: isRoleAmbiguous,
    });

    if (action === 'OPT_OUT' || !group.memberIds || group.memberIds.includes(memberToRemove.id)) {
      this.emit('membership:change', newEventData);
    }
  }

  __acceptedToTeam({ data, eventId, alwaysAddToTimeline }) {
    const callerEntity = data['caller_entity'];
    const subjectEntity = data['subject_entity'];
    const targetEntity = data['target_entity'];
    const organizationId = data['organization_id'];

    // group creator, member or group were deleted -- just ignore
    if (
      !callerEntity ||
      !callerEntity['token'] ||
      !targetEntity ||
      !targetEntity['token'] ||
      !subjectEntity ||
      !subjectEntity['token']
    )
      return;

    const group = this.host.models.Group.get(targetEntity['token']);
    if (!group) return;

    const actor = {
      displayName: data['action_user_name'],
      id: data['action_user_id'],
      organizationId,
      type: 'user',
    };

    const memberToAdd = {
      displayName: callerEntity['display_name'],
      id: callerEntity['token'],
      organizationId,
      type: 'user',
    };

    const actionTime = data['action_time'] || data['created_on'];

    const newEventData = {
      action: 'ADD',
      actionTime,
      actor,
      alwaysAddToTimeline,
      createdAt: data['created_on'],
      expireIn: data['expire_in'],
      group,
      id: eventId,
      members: [memberToAdd],
      memberIds: [memberToAdd.id],
      sortNumber: +data['sort_number'],
      ttl: data['ttl'],
    };

    // add a change log anyway, but fire membership:change only if there was an actual change
    this.host.conversations.addMembershipChangeLog(newEventData);
  }

  __deniedToTeam({ data, eventId, alwaysAddToTimeline }) {
    const callerEntity = data['caller_entity'];
    const subjectEntity = data['subject_entity'];
    const targetEntity = data['target_entity'];
    const organizationId = data['organization_id'];

    // group creator, member or group were deleted -- just ignore
    if (
      !callerEntity ||
      !callerEntity['token'] ||
      !targetEntity ||
      !targetEntity['token'] ||
      !subjectEntity ||
      !subjectEntity['token']
    )
      return;

    const group = this.host.models.Group.get(targetEntity['token']);
    if (!group) return;

    const actor = {
      displayName: data['action_user_name'],
      id: data['action_user_id'],
      organizationId,
      type: 'user',
    };

    const memberToDeny = {
      displayName: callerEntity['display_name'],
      id: callerEntity['token'],
      organizationId,
      type: 'user',
    };

    const actionTime = data['action_time'] || data['created_on'];

    const newEventData = {
      action: 'DENY',
      actionTime,
      actor,
      alwaysAddToTimeline,
      createdAt: data['created_on'],
      expireIn: data['expire_in'],
      group,
      id: eventId,
      members: [memberToDeny],
      memberIds: [memberToDeny.id],
      sortNumber: +data['sort_number'],
      ttl: data['ttl'],
    };

    // add a change log anyway, but fire membership:change only if there was an actual change
    this.host.conversations.addMembershipChangeLog(newEventData);
  }

  __handleEscalationBang({ data, eventId, alwaysAddToTimeline }) {
    const callerEntity = data['caller_entity'];
    const subjectEntity = data['subject_entity'];
    const targetEntity = data['target_entity'];
    const organizationId = data['organization_id'];
    if (!subjectEntity['token'] || !targetEntity['token']) return;

    let group = this.host.models.Group.get(targetEntity['token']);
    if (!group) {
      group = this.host.models.Group.injectPlaceholder({
        id: targetEntity['token'],
        organizationId,
      });
    }

    const action = EscalationChangeAction.resolve(data['event']);
    let escalationExecution = this.host.models.EscalationExecution.get(subjectEntity['token']);
    if (!escalationExecution) escalationExecution = group.escalationExecution;
    if (!escalationExecution) {
      if (action === EscalationChangeAction.MEMBER_ADDED) {
        escalationExecution = null;
      } else {
        escalationExecution = this.host.models.EscalationExecution.inject({
          id: subjectEntity['token'],
          organizationId,
        });
      }
    }

    const actionUserName = data['action_user_name'];
    const actionTime = data['action_time'] || data['created_on'];
    let actor = null,
      members = null;

    if (action === EscalationChangeAction.INITIATED) {
      actor = {
        displayName: callerEntity['display_name'],
        id: callerEntity['token'],
        organizationId,
        type: 'user',
      };
    } else if (action === EscalationChangeAction.MEMBER_ADDED) {
      members = [
        {
          displayName: data['member_name'],
          id: data['member_id'],
          organizationId,
          type: 'user',
        },
      ];
    } else if (action !== EscalationChangeAction.NO_RESPONSE) {
      actor = {
        displayName: actionUserName,
        id: null,
        organizationId,
        type: 'user',
      };
    }

    const newEventData = {
      action,
      actionTime,
      actor,
      alwaysAddToTimeline,
      createdAt: data['created_on'],
      escalationExecution,
      expireIn: data['expire_in'],
      group,
      id: eventId,
      members,
      sortNumber: +data['sort_number'],
      ttl: data['ttl'],
    };

    this.host.conversations.addEscalationExecutionChangeLog(newEventData);
  }

  __getMemberCounts = (members: { botRole: Object | null }[]): { users: number; roles: number } => {
    return members.reduce(
      (acc, cur) => {
        if (cur.botRole) {
          acc.roles++;
        } else {
          acc.users++;
        }
        return acc;
      },
      { users: 0, roles: 0 }
    );
  };

  isRoleIAmIn = (roleId: string) => {
    for (const role of this.host.currentUser.roles) {
      if (role.id === roleId) return true;
    }
    return false;
  };

  _setP2PEntities = (group) => {
    const { createdByRole, createdByUser, targetUser, targetRole } = group;
    const createdBy = createdByRole ? createdByRole : createdByUser;
    const target = targetUser ? targetUser : targetRole;
    const isP2PCreatorMe =
      createdBy.id === this.host.currentUser.id || this.isRoleIAmIn(createdBy.id);

    group.p2pSender = isP2PCreatorMe ? createdBy : target;
    group.p2pRecipient = isP2PCreatorMe ? target : createdBy;
  };

  _setMembership(entity) {
    const roleMap = this.host.currentUser.roles.reduce((acc, { botUserId }) => {
      if (botUserId) {
        acc[botUserId] = true;
      }
      return acc;
    }, {});

    entity.hasCurrentUserOrRole = false;
    if (entity.memberIds) {
      for (const memberId of entity.memberIds) {
        if (memberId === this.host.currentUserId || roleMap[memberId]) {
          entity.hasCurrentUserOrRole = true;
          return;
        }
      }
    }
  }

  _onChangeConversation = (resource, conversation) => {
    const { counterPartyId, counterPartyType } = conversation;
    if (counterPartyType !== 'group') return;

    const group = this.host.models.Group.get(counterPartyId);
    if (!group) return;

    if (!group.conversation) {
      this.host.models.Group.inject(group);
    }
  };

  _onRemoveConversation = (resource, conversation) => {
    const { counterPartyId, counterPartyType } = conversation;
    if (counterPartyType !== 'group') return;

    const group = this.host.models.Group.get(counterPartyId);
    if (!group) return;

    if (group.conversation) {
      this.host.models.Group.inject(group);
    }
  };

  _onChangeGroup = (resource, group) => {
    const { id: groupId, memberIds } = group;
    const lastGroupMemberIds = this._groupMemberIds[groupId];

    if (lastGroupMemberIds) {
      for (const userId of lastGroupMemberIds) {
        const groups = this._userGroups[userId];
        const idx = groups ? groups.indexOf(group) : -1;
        if (idx > -1 && memberIds && !memberIds.includes(userId)) {
          groups.splice(idx, 1);
        }
      }
    }

    if (!memberIds) return;
    this._groupMemberIds[groupId] = memberIds;

    for (const userId of memberIds) {
      const groups = this._userGroups[userId] || [];
      if (!groups.includes(group)) {
        groups.push(group);
      }
      this._userGroups[userId] = groups;
    }
  };

  _onRemoveGroup = (resource, group) => {
    const { id: groupId, memberIds } = group;
    delete this._groupMemberIds[groupId];
    if (!memberIds) return;

    for (const userId of memberIds) {
      const groups = this._userGroups[userId];
      const idx = groups ? groups.indexOf(group) : -1;
      if (idx > -1) {
        groups.splice(idx, 1);
      }
    }
  };

  _onChangeMetadata = (resource, metadata) => {
    const { entityId } = metadata;
    const group = this.host.models.Group.get(entityId);
    if (!group) return;

    this.host.models.Group.inject(group);
  };

  _onChangeUser = (resource, user) => {
    const { botRole, id: userId, isRoleBot } = user;
    const groups = this._userGroups[userId];
    const prevUserState = this._userState[userId];
    const changedRoleState =
      prevUserState && (prevUserState.botRole !== botRole || prevUserState.isRoleBot !== isRoleBot);

    if (!prevUserState || changedRoleState) {
      this._userState[userId] = { botRole, isRoleBot };
    }

    if (groups) {
      for (const group of groups) {
        const { conversation } = group;
        if (changedRoleState) {
          this.host.models.Group.inject(group);
          if (conversation) {
            this.host.models.Conversation.inject(conversation);
          }
        }
      }
    }
  };

  _onRemoveUser = (resource, user) => {
    const { id: userId } = user;
    const groups = this._userGroups[userId];

    if (groups && groups.length > 0) {
      for (const group of groups) {
        const { members } = group;
        if (members && members.includes(user)) {
          this.host.models.Group.inject(group);
        }
      }
    }

    delete this._userGroups[userId];
    delete this._userState[userId];
  };
}

// @ts-nocheck
import uuid from 'uuid';
import Camelizer from '../utils/Camelizer';
import BaseAPI, { recoverFromNotFound, ROUTES, VERSIONS } from './BaseAPI';

const { VERSION_TWO } = VERSIONS;
const { PRESENCE_FIND_MULTI } = ROUTES;

export default class PresenceAPI extends BaseAPI {
  @recoverFromNotFound()
  async findMulti({ userIds }: { userIds: string[] }) {
    const { version = VERSION_TWO } = super.getVersion(PRESENCE_FIND_MULTI);
    const res = await this.httpClient.post('/:version/presence_status', {
      data: {
        user_ids: userIds,
      },
      urlParams: { version },
    });

    return Camelizer.camelizeObject(res.data);
  }

  subscribeUser(userId: string) {
    this.host.events.send('v1_subscribe_user_status', {
      commandId: uuid(),
      recipient: userId,
      timestamp: Date.now(),
    });
  }

  unsubscribeUser(userId: string) {
    this.host.events.send('v1_unsubscribe_user_status', {
      commandId: uuid(),
      recipient: userId,
      timestamp: Date.now(),
    });
  }
}

import {
  forwardRef,
  KeyboardEventHandler,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

type AccessibleListProps = {
  children: (JSX.Element | false | undefined)[];
  className?: string | undefined;
  direction?: 'horizontal' | 'vertical';
  focusableClasses: string[] | string;
  focusStart?: 'first' | 'last' | HTMLElement;
  loop?: boolean;
  accessibilityMode?: boolean;
  focusableChildrenClasses?: string[] | string;
  setStartElementOnChange?: boolean;
  focusCenteredOnScroll?: boolean;
  role?: string;
};

const AccessibleList = (
  {
    className,
    children,
    direction = 'vertical',
    focusableClasses,
    focusStart = 'first',
    loop = false,
    focusableChildrenClasses,
    setStartElementOnChange = false,
    accessibilityMode = false,
    focusCenteredOnScroll = false,
    role,
  }: AccessibleListProps,
  ref: Ref<HTMLUListElement>
) => {
  const containerRef = useRef<HTMLUListElement>(null);
  const listItems = useRef<HTMLElement[]>([]);
  const [startElementSet, setStartElementSet] = useState(false);
  const [prevActiveIndex, setPrevActiveIndex] = useState(0);
  const forwardKey = direction === 'horizontal' ? 'ArrowRight' : 'ArrowDown';
  const backKey = direction === 'horizontal' ? 'ArrowLeft' : 'ArrowUp';
  const cssQuery = Array.isArray(focusableClasses) ? focusableClasses.join(',') : focusableClasses;
  const cssChildrenQuery = Array.isArray(focusableChildrenClasses)
    ? focusableChildrenClasses.join(',')
    : focusableChildrenClasses;

  useImperativeHandle<HTMLUListElement | null, HTMLUListElement | null>(
    ref,
    () => containerRef.current
  );

  const onKeyDown: KeyboardEventHandler<HTMLUListElement> = (event) => {
    const isHtmlInputEvent = event.target instanceof HTMLInputElement;
    const isActionableKey = event.key === 'Enter' || event.key === ' ';
    const isNavigationKey = event.key === backKey || event.key === forwardKey;
    if ((event.key !== 'Tab' && !isHtmlInputEvent && !isActionableKey) || isNavigationKey)
      event.preventDefault();
    if ([backKey, forwardKey].includes(event.key)) {
      let activeElement = document.activeElement;
      if (!activeElement) return;
      let activeIndex = listItems.current.findIndex((item) => item === document.activeElement);
      if (activeIndex === -1) {
        activeIndex = prevActiveIndex;
        activeElement = listItems.current[prevActiveIndex];
      }
      const listLength = listItems.current.length;
      const atBottom = event.key === forwardKey && activeIndex === listLength - 1;
      const atTop = event.key === backKey && activeIndex === 0;

      if (!listLength) return;
      if ((atBottom || atTop) && !loop) return;

      let nextElement: HTMLElement;

      if (atBottom) {
        nextElement = listItems.current[0];
        setPrevActiveIndex(0);
      } else if (atTop) {
        nextElement = listItems.current[listLength - 1];
        setPrevActiveIndex(listLength - 1);
      } else {
        nextElement = listItems.current[activeIndex + (event.key === forwardKey ? 1 : -1)];
        setPrevActiveIndex(activeIndex + (event.key === forwardKey ? 1 : -1));
      }

      if (nextElement) {
        if (activeElement) (activeElement as HTMLElement).tabIndex = -1;
        nextElement.tabIndex = 0;
        nextElement.focus();
        focusCenteredOnScroll && nextElement.scrollIntoView({ block: 'center' });
        if (cssChildrenQuery) {
          setChildrenFocus(nextElement, 0);
          activeElement && setChildrenFocus(activeElement, -1);
        }
      }
    }
  };

  const setChildrenFocus = useCallback(
    (element: HTMLElement | Element, tabIndexValue: 0 | -1) => {
      cssChildrenQuery &&
        element.querySelectorAll<HTMLElement>(cssChildrenQuery).forEach((element: HTMLElement) => {
          element.tabIndex = tabIndexValue;
        });
    },
    [cssChildrenQuery]
  );

  const setStartElement = useCallback(() => {
    if (startElementSet || !accessibilityMode) return;
    let startingElement: HTMLElement | null | undefined = null;
    if (focusStart === 'first') {
      startingElement = listItems.current[0];
    } else if (focusStart === 'last') {
      const listLength = listItems.current.length;
      if (listLength) startingElement = listItems.current[listLength - 1];
    } else {
      startingElement = focusStart;
    }
    if (startingElement) {
      startingElement.tabIndex = 0;
      setChildrenFocus(startingElement, 0);
      listItems.current
        .filter((item) => item !== startingElement)
        .forEach((item) => {
          item.tabIndex = -1;
          setChildrenFocus(item, -1);
        });
      setStartElementSet(true);
    }
  }, [accessibilityMode, focusStart, startElementSet, setChildrenFocus]);

  const refreshListItems = useCallback(() => {
    listItems.current = Array.from(
      containerRef.current?.querySelectorAll<HTMLElement>(cssQuery) || []
    );
  }, [cssQuery]);

  const onMutation = useCallback(() => {
    refreshListItems();
    setStartElement();
  }, [refreshListItems, setStartElement]);

  useEffect(() => {
    setStartElement();
    if (setStartElementOnChange) {
      setStartElementSet(false);
    }
  }, [focusStart, containerRef, cssQuery, setStartElement, setStartElementOnChange]);

  useEffect(() => {
    if (!containerRef.current || !accessibilityMode) return;
    if (listItems.current.length === 0) {
      onMutation();
    }
    const observer = new MutationObserver(onMutation);
    observer.observe(containerRef.current, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
    };
  }, [accessibilityMode, onMutation]);

  refreshListItems();

  return (
    <ul
      ref={containerRef}
      className={className}
      onKeyDown={accessibilityMode ? onKeyDown : undefined}
      role={role}
    >
      {children}
    </ul>
  );
};

export default forwardRef(AccessibleList);

// @ts-nocheck
import EventEmitter from 'events';
import invariant from 'invariant';
import _ from 'lodash';
import raf from 'raf';
import uuid from 'uuid';
import * as apis from './apis';
import * as errors from './errors';
import configuration from './configuration';
import Logger from './Logger';
import * as modelEnums from './models/enums';
import ProcessingActionTypes from './models/enums/ProcessingActionTypes';
import { resetLoadEntityQueue } from './models/extensions/ensureEntity';
import EventSourceClient from './network/EventSourceClient';
import HttpClient from './network/HttpClient';
import NetworkStatus from './network/NetworkStatus';
import * as services from './services';
import {
  SSE_CONNECTION_DURATION,
  EVENTS_QUEUE_ERROR,
  WS_CONNECTIONS_CLOSE_COUNT,
  WS_CONNECTIONS_OVER_LIMIT,
} from './services/EventsService';
import TCStore from './store/TCStore';
import { Camelizer, attachments, date, email, file, phone } from './utils';
import PACKAGE_VERSION from './version';

export class TigerConnectClient {
  static CONSUMER_ORGANIZATION_ID = configuration.CONSUMER_ORGANIZATION_ID;
  static errors = errors;
  static VERSION = PACKAGE_VERSION;
  static version = PACKAGE_VERSION;

  errors = errors;
  VERSION = PACKAGE_VERSION;
  version = PACKAGE_VERSION;

  sessionWipeStatus: 'remote_wipe' | null = null;

  /**
   * [constructor description]
   * @param  {[type]} originalConfig [description]
   * @constructs TigerConnectClient
   */
  constructor(originalConfig: Object | null | undefined = {}) {
    this.config = Object.assign({}, originalConfig);
    _.defaultsDeep(this.config, configuration.CONFIG_DEFAULT);
    const { apiEnv, logLevel } = this.config;

    this.config.cloudfront = configuration.CLOUDFRONT[apiEnv];

    const isDynamicEnv = /(env\d+|lt\d*)/.test(apiEnv);
    if (isDynamicEnv) {
      this.config.cloudfront = configuration.CLOUDFRONT['development'];

      configuration.BASE_URLS[apiEnv] = `https://${apiEnv}-devapi.tigertext.xyz`;
      configuration.FORGOT_PASSWORD_URLS[
        apiEnv
      ] = `https://login.tigerconnect.com/${apiEnv}/app/forgot`;
      configuration.CN_URLS[apiEnv] = `https://${apiEnv}-console.tigertext.xyz`;
      configuration.TC_URLS[apiEnv] = `https://api.${apiEnv}.tigerconnect.com`;
      configuration.ADMIN_URLS[apiEnv] = `/${apiEnv}/app/admin`;
      configuration.ROLES_URLS[apiEnv] = `/${apiEnv}/app/roles/index.html`;
      configuration.INTEGRATION_URLS[apiEnv] = 'https://integration.dev.tigerconnect.com';
      configuration.WEB_INFRA_URLS[apiEnv] = 'https://web.env7.tigerconnect.com';
      configuration.WEBSOCKET_URLS[apiEnv] = `https://${apiEnv}-devapi.tigertext.xyz`;
    }

    if (!this.config.baseUrl) {
      this.config.baseUrl = configuration.BASE_URLS[apiEnv];
    }

    if (!this.config.webSocketUrl) {
      this.config.webSocketUrl = configuration.WEBSOCKET_URLS[apiEnv];
    }

    // enforce autoSignOutOnBrowserUnload=false on non-web targets
    if (process.env.NODE_TARGET !== 'web') {
      this.config.autoSignOutOnBrowserUnload = false;
    }

    Object.assign(this, { attachments, date, email, file, phone });
    this.httpClient = new HttpClient({
      config: this.config,
      logger: new Logger({ name: 'HttpClient', level: logLevel }),
      sendReport: (data) => this.report.send(data),
    });
    this.eventSourceClient = new EventSourceClient({
      config: this.config,
      httpClient: this.httpClient,
      logger: new Logger({ name: 'EventSourceClient', level: logLevel }),
    });
    this.enums = { ...modelEnums, NetworkStatus };
    this.store = new TCStore(this, {
      logger: new Logger({ name: 'Store', level: logLevel }),
    });
    this.store.defineModels();
    this.models = this.store.models;
    this.modelsByEntityType = _.mapKeys(this.store.models, (v, k) => Camelizer.camelizeKey(k));
    this.modelsByEntityType['account'] = this.modelsByEntityType['user'];
    this.modelsByEntityType['list'] = this.modelsByEntityType['distributionList'];
    this.allModelEntityTypes = Object.keys(this.modelsByEntityType);

    this.logger = new Logger({ name: 'TigerConnectClient', level: logLevel });

    this.api = {};
    for (const [name, Klass] of Object.entries(apis)) {
      const key = Camelizer.camelizeKey(name);
      this.api[key] = new Klass(this, {
        logger: new Logger({ name: `${name}API`, level: logLevel }),
      });
    }

    this._services = [];

    for (const [name, Klass] of Object.entries(services)) {
      const key = Camelizer.camelizeKey(name);

      const service = new Klass(this, {
        logger: new Logger({
          name: `${name}Service`,
          level: this.config.serviceLogLevels[key] || logLevel,
        }),
      });

      this[key] = service;
      this._services.push(service);
    }

    for (const service of this._services) {
      if (service.mounted) service.mounted();
    }

    this._listenToEvents();
    this._startTicker();

    this.on('browserUnload', (performAtPriority) =>
      performAtPriority(
        () => {
          if (this.config.autoSignOutOnBrowserUnload) {
            this.notifications.disconnect({ source: 'On browser unload' });
            this.signOut({ async: false, resetClient: false });
          }
        },
        100,
        'signOut'
      )
    );

    this._checkBangTimer = null;
    this.conversationsLoaded = false;
    this.currentlyServingOfflineMessages = false;
    this.isInitialMessageReplayDone = false;
    this.isInitialBangReplayDone = false;
  }

  _listenToEvents() {
    this.on('signedIn', (user, auth) => {
      this.logger.info('signedIn', user.id);
    });
    this.on('signedOut', ({ clearStore, resetClient }) => {
      if (resetClient) this.reset({ clearStore });
    });
    this.on('signUp', ({ user, auth }) => {
      this.logger.info('signUp', user.id);
      this._setCurrentUser(user);
      this._setAuth(auth);
    });

    this.events.on('event', (event) => {
      this.emit('event', event);
      if (event.type === 'bang') this._resetLastBang();
      if (this.events.eventSourceIsWebSocket && event && event.error) {
        this.emit('event:websockets:error', event.error);
      }
    });

    this.events.on('batch:available', (count) => this.emit('events:batch:available', count));
    this.events.on('connected', () => this.emit('events:connected'));
    this.events.on('immediateRolesProcessing', (event) => this.emit('roles:processing', event));
    this.events.on('disconnected', this._onDisconnected);
    this.events.on('processing:start', (event) => this.emit('processing:start', event));
    this.events.on('processing:stop', (event) => this.emit('processing:stop', event));
    this.events.on('visibilityChange', (event, state) =>
      this.emit('visibilityChange', event, state)
    );
    this.events.on(SSE_CONNECTION_DURATION, (event) => {
      this.emit(SSE_CONNECTION_DURATION, event);
    });
    this.events.on(EVENTS_QUEUE_ERROR.PARSE, (event) => {
      this.emit(EVENTS_QUEUE_ERROR.PARSE, event);
    });
    this.events.on(EVENTS_QUEUE_ERROR.UNKNOWN, (event) => {
      this.emit(EVENTS_QUEUE_ERROR.UNKNOWN, event);
    });
    this.events.on(WS_CONNECTIONS_CLOSE_COUNT, (event) =>
      this.emit(WS_CONNECTIONS_CLOSE_COUNT, event)
    );
    this.events.on(WS_CONNECTIONS_OVER_LIMIT, (event) =>
      this.emit(WS_CONNECTIONS_OVER_LIMIT, event)
    );
    this.calls.on('answered', (event) => this.emit('call:answered', event));
    this.calls.on('calleeBusy', (event) => this.emit('call:calleeBusy', event));
    this.calls.on('closed', (event) => this.emit('call:closed', event));
    this.calls.on('ended', (event) => this.emit('call:ended', event));
    this.calls.on('memberUpdate', (event) => this.emit('call:memberUpdate', event));
    this.calls.on('incoming', (event) => this.emit('call:incoming', event));
    this.calls.on('state', (event) => this.emit('call:state', event));
    this.conversations.on('clearTimeline', (conversationId) =>
      this.emit('conversation:clearTimeline', conversationId)
    );
    this.conversations.on('clearMessageStatuses', (updatedMessages) =>
      this.emit('conversation:clearMessageStatuses', updatedMessages)
    );
    this.conversations.on('message', (message) => this.emit('message', message));
    this.conversations.on('friends', (event) => this.emit('friends', event));
    this.conversations.on('roster:download:start', () => this.emit('roster:download:start'));
    this.conversations.on('roster:download:stop', () => this.emit('roster:download:stop'));
    this.eventSourceClient.on('auth:invalid', () => this.emit('auth:invalid'));
    this.groups.on('membership:change', (event) => this.emit('group:membership:change', event));
    this.httpClient.on('networkStatus:change', (event) => this.emit('networkStatus:change', event));
    this.messages.on('alertMetadataSSEReceived', (event) =>
      this.emit('message:alertMetadataSSEReceived', event)
    );
    this.messages.on('messageStatusSync', (event) => this.emit('message:statusSync', event));
    this.notifications.on('connected', () => this.emit('notifications:connected'));
    this.notifications.on('disconnected', (options) => this.emit('notifications:disconnected'));
    this.notifications.on('signingIn', () => this.emit('notifications:signingIn'));
    this.notifications.on('signedIn', (auth) => this.emit('notifications:signedIn', auth));
    this.notifications.on('signingOut', (options) =>
      this.emit('notifications:signingOut', options)
    );
    this.notifications.on('signedOut', (options) => this.emit('notifications:signedOut', options));
    this.notifications.on('event', (event) => this.emit('notifications:event', event));
    this.notifications.on('message', (message) => this.emit('notification:message', message));
    this.notifications.on('unread:change', (count) =>
      this.emit('notification:unread:change', count)
    );
    this.organizations.on('preferences:change', (event) =>
      this.emit('organization:preferences:change', event)
    );
    this.presence.on('change', (event) => this.emit('presence:change', event));
    this.roles.on('change', (event) => this.emit('role:change', event));
    this.roles.on('saved', (event) => this.emit('role:saved', event));
    this.tags.on('saved', (event) => this.emit('tag:saved', event));
    this.teams.on('saved', (event) => this.emit('team:saved', event));
    this.typingStatus.on('change', (e) => this.emit('typing:change', e));
    this.users.on('preferences:change', (event) => this.emit('user:preferences:change', event));
    this.virtualWaitingRoom.on('vwr:visitor:add', (event) =>
      this.emit('notification:vwr:visitor', event)
    );

    if (typeof window !== 'undefined') window.addEventListener('unload', this._onBrowserUnload);
  }

  _onBrowserUnload = () => {
    const ops = [];
    const performAtPriority = (fn, priority, name) => ops.push({ fn, priority, name });
    this.emit('browserUnload', performAtPriority);
    _.orderBy(ops, ['priority'], ['desc']).forEach(({ fn, priority, name }) => {
      console.log('[unload]', priority, name);
      fn();
    });
  };

  _onDisconnected = ({ isRecoverable }) => {
    if (!isRecoverable) this._removeAuth();
    this.emit('events:disconnected', { isRecoverable });
  };

  _startTicker() {
    this.__ticker = setTimeout(this._onTick, 5000);
    this.__tickerFrame = null;
  }

  _stopTicker() {
    if (this.__ticker !== null) {
      clearTimeout(this.__ticker);
      this.__ticker = null;
    }

    if (this.__tickerFrame !== null) {
      raf.cancel(this.__tickerFrame);
      this.__tickerFrame = null;
    }
  }

  _onTick = () => {
    this.__tickerFrame = raf(() => {
      try {
        this.emit('tick');
      } finally {
        this.__ticker = setTimeout(this._onTick, 5000);
      }
    });
  };

  async signIn(userId, password, params = {}) {
    const { udid = uuid(), refreshUser = true, jwt } = params;

    this.emit('signingIn');

    const { auth, user } = await this.users.signIn(userId, password, {
      udid,
      ...(jwt ? { jwt } : {}),
    });

    this._setCurrentUser(user);
    this._setAuth(auth);

    // re-query user because /login doesn't return all user fields
    if (refreshUser) await this.users.findMe({ bypassCache: true });

    this.emit('signedIn', user, auth);

    this.setSessionWipeStatus(null);

    return { auth, user };
  }

  async signInWithApiKeyAndSecret(apiKey, apiSecret, params = {}) {
    const { udid = uuid(), refreshUser = true } = params;

    this.emit('signingIn');

    const { auth, user } = await this.users.signInWithApiKeyAndSecret(apiKey, apiSecret, { udid });

    this._setCurrentUser(user);
    this._setAuth(auth);

    // re-query user because /login doesn't return all user fields
    if (refreshUser) await this.users.findMe({ bypassCache: true });

    this.emit('signedIn', user, auth);

    return { auth, user };
  }

  async signInWithMagicToken(magicToken, params = {}) {
    const { udid = uuid(), refreshUser = true } = params;

    this.emit('signingIn');

    const { auth, user } = await this.users.signInWithMagicToken(magicToken, { udid });

    this._setCurrentUser(user);
    this._setAuth(auth);

    // re-query user because /login doesn't return all user fields
    if (refreshUser) await this.users.findMe({ bypassCache: true });

    this.emit('signedIn', user, auth);

    return { auth, user };
  }

  async signInWithJwtToken(jwtToken, params = {}) {
    const { udid = uuid(), refreshUser = true, jwt } = params;

    this.emit('signingIn');

    const { auth, user } = await this.users.signInWithJwtToken(jwtToken, {
      udid,
      ...(jwt ? { jwt } : {}),
    });

    this._setCurrentUser(user);
    this._setAuth(auth);

    // re-query user because /login doesn't return all user fields
    if (refreshUser) await this.users.findMe({ bypassCache: true });

    this.emit('signedIn', user, auth);

    return { auth, user };
  }

  setSessionWipeStatus(status: 'remote_wipe' | null) {
    this.sessionWipeStatus = status;
  }

  signOut({ async = true, clearStore = true, fromServer = false, resetClient = true } = {}) {
    if (!this.isSignedIn) return Promise.resolve(false);

    if (!async && typeof XMLHttpRequest === 'undefined') {
      throw new Error('`async: false` option cannot be set unless using signOut() in a browser');
    }

    this.events.disconnect({ source: 'On sign out' });

    if (async) {
      return this.users.signOut({ clearStore, fromServer, resetClient });
    } else {
      this.emit('signingOut', { fromServer, resetClient });

      // browser only code
      if (this.sessionWipeStatus !== 'remote_wipe') {
        try {
          const xhr = new XMLHttpRequest();
          xhr.open('POST', `${this.config.baseUrl}/v2/logout`, false);
          for (const [header, value] of Object.entries(this.httpClient.getAuthHeaders())) {
            xhr.setRequestHeader(header, value);
          }
          xhr.send(null);
        } catch {
          console.error('Error sending logout request');
        }
      }
      this.emit('signedOut', { clearStore, fromServer, resetClient, signedOut: true });

      return Promise.resolve(true);
    }
  }

  signOutAllDevices({ async = true } = {}) {
    if (!this.isSignedIn) return Promise.resolve();

    if (!async && typeof XMLHttpRequest === 'undefined') {
      throw new Error(
        '`async: false` option cannot be set unless using signOutAllDevices() in a browser'
      );
    }

    if (async) {
      return this.users.signOutAllDevices();
    } else {
      // browser only code
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${this.config.baseUrl}/v2/wipe?token=${this.currentUserId}`, false);
      for (const [header, value] of Object.entries(this.httpClient.getAuthHeaders())) {
        xhr.setRequestHeader(header, value);
      }
      xhr.send(null);
      return Promise.resolve();
    }
  }

  async authenticate(key: string, secret: string) {
    const user = await this._authenticateWithParams({ key, secret });
    return user;
  }

  async authenticateUsingOauth(bearerToken: string, params = { udid: undefined }) {
    const { udid = uuid() } = params;
    const user = await this._authenticateWithParams({ bearerToken, udid });
    return user;
  }

  async _authenticateWithParams(params) {
    this._setAuth(params);
    try {
      const user = await this.users.findMe();
      this._setCurrentUser(user);
      this.emit('signedIn', user, params);
      return user;
    } catch (ex) {
      this._removeAuth();
      this._currentUser = null;
      throw ex;
    }
  }

  /**
   * Sets current logged in user on the client. Every future request will be authenticated with that user.
   * @param {Object} user - current user
   * @param {Object} auth - current user's auth object (contains key/secret keys)
   */
  _setCurrentUser(user) {
    this._currentUser = user;
  }

  _setAuth(auth) {
    this._auth = auth;
    this.httpClient.setAuth(auth);
    this.events.setAuth(auth);
  }

  _removeAuth() {
    this._auth = null;
    this.httpClient.removeAuth();
    this.events.removeAuth();
  }

  /**
   * Gets current user if set
   * @return {User} current user object
   */
  getCurrentUser() {
    return this._currentUser || null;
  }

  get currentUser() {
    return this.getCurrentUser();
  }

  get currentUserId() {
    const user = this.getCurrentUser();
    return user ? user.id : null;
  }

  getAuth() {
    return this._auth;
  }

  /**
   * Is there an authenticated user on this client?
   * @return {Boolean} if user is signed in
   */
  get isSignedIn() {
    return !!this.getAuth();
  }

  /**
   * Ensures a user is authenticated on this client
   * @throws {Error} if user isn't signed in
   */
  requireUser() {
    if (!this.isSignedIn) {
      throw new errors.NotSignedInError();
    }
  }

  reactToAdvisoryEvent(type, data) {
    this.emit('advisory', { type, data });

    switch (type) {
      case 'replayStart':
        this.currentlyServingOfflineMessages = true;
        this.emit('messages:offline:start', data);

        this.products._addProcessingEvent({
          actionType: ProcessingActionTypes.MESSAGE_REPLAY,
          entity: this.products.getAll()[0],
          itemsEstimate: data.replayMessageCount,
        });

        break;
      case 'replayStop':
        if (!this.isInitialMessageReplayDone) {
          this.isInitialMessageReplayDone = true;
          this._reloadPendingConversations({
            flushImmediately: true,
            emitEvents: this.conversations._fetched,
          });
        }

        if (this._checkBangTimer === null) {
          this.emit('groups:offline:start');
          this._startBangTimer();
        }

        this.products._removeProcessingEvent({
          actionType: ProcessingActionTypes.MESSAGE_REPLAY,
          entity: this.products.getAll()[0],
        });

        break;
      default:
    }
  }

  _resetLastBang = () => {
    this._bangOccurredDuringReplay = true;
  };

  _startBangTimer = () => {
    this._bangOccurredDuringReplay = false;
    this._checkBangTimer = setTimeout(this._didBangOccur, this.config.detectBangReplayTimeout);
  };

  _didBangOccur = () => {
    if (!this._bangOccurredDuringReplay) {
      this._checkBangTimer = null;
      this._bangReplayDone();
    } else {
      this._startBangTimer();
    }
  };

  _reloadPendingConversations = ({ flushImmediately = false, emitEvents = true } = {}) => {
    if (emitEvents) {
      this.emit('conversations:loading:start');
    }

    if (
      (flushImmediately || this.isInitialBangReplayDone) &&
      this.conversations.hasPendingReloads()
    ) {
      this.conversations.__flushConversationsToReload();
    }

    if (emitEvents) {
      this.emit('conversations:loading:stop');
    }
  };

  _bangReplayDone = () => {
    if (!this.currentlyServingOfflineMessages) return;
    this.currentlyServingOfflineMessages = false;

    this._reloadPendingConversations({ emitEvents: false });

    this.emit('groups:offline:stop');
    this.emit('messages:offline:stop');
    this.emit('messages:new:start');

    this.isInitialBangReplayDone = true;
  };

  get MODEL_BY_TYPE_NS() {
    if (!this._MODEL_BY_TYPE_NS) {
      this._MODEL_BY_TYPE_NS = {
        'tigertext:entity:account': this.models.User,
        'tigertext:entity:patient_account': this.models.User,
        'tigertext:entity:individual': this.models.User,
        'tigertext:entity:role': this.models.User,
        'tigertext:entity:care_team': this.models.CareTeam,
        'tigertext:entity:public_group': this.models.Group,
        'tigertext:entity:group': this.models.Group,
        'tigertext:entity:distribution_list': this.models.DistributionList,
        'tigertext:entity:dist_list_member': this.models.User,
        'tigertext:entity:dist_list_shared': this.models.User,
        'tigertext:entity:list': this.models.DistributionList,
        'tigertext:entity:message_template': this.models.MessageTemplate,
        'tigertext:entity:organization': this.models.Organization,
        'tigertext:entity:patient_distribution_list': this.models.DistributionList,
        'tigertext:entity:schedule_message': this.models.ScheduledMessage,
        'tigertext:entity:tag': this.models.Tag,
        'tigertext:entity:team': this.models.Team,
        'tigertext:iq:message': this.models.Message,
        'tigertext:iq:group_message': this.models.Message,
        'tigertext:iq:group:message': this.models.Message,
      };
    }

    return this._MODEL_BY_TYPE_NS;
  }

  modelByTypeNS(ns) {
    return this.MODEL_BY_TYPE_NS[ns];
  }

  modelNameByTypeNS(type) {
    const Klass = this.MODEL_BY_TYPE_NS[type];
    if (!Klass) {
      invariant(Klass, `modelNameByTypeNS: Missing class for ${type}`);
      return null;
    }

    return Klass.name;
  }

  modelNameByEntityType(type) {
    const Klass = this.modelsByEntityType[type] || this.MODEL_BY_TYPE_NS[type];
    if (!Klass) {
      invariant(Klass, `modelNameByEntityType: Missing class for ${type}`);
    } else {
      return Klass.name;
    }
  }

  ejectModel(attrs, type = attrs.xmlns || attrs.type) {
    const Klass = this.MODEL_BY_TYPE_NS[type] || this.modelsByEntityType[type];
    if (!Klass) {
      invariant(Klass, `ejectModel: Missing class for ${type}`);
    } else {
      return Klass.eject(attrs);
    }
  }

  injectModel(attrs, type = attrs.xmlns || attrs.type) {
    const Klass = this.MODEL_BY_TYPE_NS[type] || this.modelsByEntityType[type];
    if (!Klass) {
      invariant(Klass, `injectModel: Missing class for ${type}`);
      return null;
    }

    return Klass.inject(attrs);
  }

  injectPlaceholderModel(attrs, type = attrs.xmlns || attrs.type) {
    const Klass = this.modelByTypeNS(type) || this.modelsByEntityType[type];
    if (!Klass) {
      invariant(Klass, `injectPlaceholderModel: Missing class for ${type}`);
      return null;
    }

    return Klass.injectPlaceholder(attrs);
  }

  getEntity(type, id) {
    return this.modelsByEntityType[type].get(id);
  }

  getEntities(arr) {
    return arr.map((item) => this.getEntity(item.type, item.id));
  }

  dispose({ clearStore = true } = {}) {
    this._stopTicker();
    this.conversationsLoaded = false;

    if (this._checkBangTimer !== null) {
      clearTimeout(this._checkBangTimer);
      this._checkBangTimer = null;
    }

    for (const service of this._services) {
      if (service.dispose) service.dispose();
    }

    resetLoadEntityQueue();
    if (clearStore) {
      this.store && this.store.clear();
    }
  }

  reset({ clearStore = true } = {}) {
    this.dispose({ clearStore });

    for (const service of this._services) {
      if (service.mounted) service.mounted();
    }

    this._startTicker();
  }

  // public helper methods
  configure(newPartialConfig) {
    Object.assign(this.config, newPartialConfig);
    this.httpClient.mounted();
    return this;
  }

  setDefaultOrganization(defaultOrganizationId) {
    return this.configure({ defaultOrganizationId });
  }

  get networkStatus() {
    return this.httpClient.networkStatus;
  }

  get webInfraUrl() {
    return this.config.webInfraUrl || configuration.WEB_INFRA_URLS[this.config.apiEnv];
  }

  get forgotPasswordUrl() {
    return configuration.FORGOT_PASSWORD_URLS[this.config.apiEnv];
  }

  get adminUrl() {
    if (this.config.adminFeatureBranch)
      return `/feature/${this.config.adminFeatureBranch}/app/admin`;
    if (this.config.localAdminUrl) return this.config.localAdminUrl;
    return configuration.ADMIN_URLS[this.config.apiEnv];
  }

  get tcUrl() {
    return configuration.TC_URLS[this.config.apiEnv];
  }

  get cnUrl() {
    if (this.config.localCnUrl) return this.config.localCnUrl;
    return configuration.CN_URLS[this.config.apiEnv];
  }

  get integrationUrl() {
    return configuration.INTEGRATION_URLS[this.config.apiEnv];
  }

  get rolesUrl() {
    if (this.config.rolesFeatureBranch)
      return `/feature/${this.config.rolesFeatureBranch}/app/roles/index.html`;
    if (this.config.localRolesUrl) return this.config.localRolesUrl;
    return configuration.ROLES_URLS[this.config.apiEnv];
  }

  get webSocketUrl() {
    return configuration.WEBSOCKET_URLS[this.config.apiEnv];
  }
}

Object.assign(TigerConnectClient.prototype, EventEmitter.prototype);

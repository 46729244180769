import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../../common/bem';
import propTypes from '../../../../common/propTypes';
import { mobxInjectSelect } from '../../../../common/utils';
import { SearchResult } from './';

const classes = BEM.with('SearchResultBox');

class SearchResultBox extends Component {
  static propTypes = {
    input: PropTypes.string.isRequired,
    isProviderNetwork: PropTypes.bool.isRequired,
    searchResults: propTypes.searchResultArray.isRequired,
  };

  render() {
    const { input, isProviderNetwork, searchResults } = this.props;

    const results = searchResults.map(({ entity, organizationId }) => (
      <SearchResult key={entity.id} entity={entity} input={input} organizationId={organizationId} />
    ));

    return (
      <div className={classes({ borderBottom: !isProviderNetwork, test: true })}>{results}</div>
    );
  }
}

export default mobxInjectSelect({
  networkStore: ['isProviderNetwork'],
})(SearchResultBox);

/* eslint @typescript-eslint/no-use-before-define: 0 */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../bem';
import { mobxInjectSelect } from '../../../utils';

import propTypes from '../../../propTypes';
import { ReactComponent as ImageUploadSvg } from '../../../images/pa-img-upload.svg';

const classes = BEM.with('CustomLabeling');
const TIGERTOUCH_DOMAIN = '.med.tc';

function CustomLabeling({
  currentOrganization,
  currentOrganizationId,
  fetchWhiteLabelData,
  processWhiteLabelLogo,
  setWhiteLabelData,
  openModal,
  whiteLabelData,
}) {
  const logoInputRef = useRef(null);
  const [orgName, setOrgName] = useState(currentOrganization.name);
  const [orgUrl, setOrgUrl] = useState('');
  const [orgPhone, setOrgPhone] = useState('');
  const [orgLogoUrl, setOrgLogoUrl] = useState('');
  const [formErrors, setFormErrors] = useState({
    orgLogo: '',
    orgUrl: '',
    orgPhone: '',
    orgName: '',
  });
  const [orgLogoImageType, setOrgLogoImageType] = useState('');

  const validForm = Object.values(formErrors).filter((field) => field !== '').length === 0;

  useEffect(() => {
    fetchWhiteLabelData(currentOrganizationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganizationId]);

  useEffect(() => {
    if (whiteLabelData) {
      setOrgName(whiteLabelData.orgName || currentOrganization.name);
      setOrgUrl(
        whiteLabelData.orgUrl === ''
          ? whiteLabelData.orgUrl
          : `${whiteLabelData.orgUrl}${TIGERTOUCH_DOMAIN}`
      );
      setOrgLogoUrl(whiteLabelData.orgLogoUrl);
      setOrgPhone(whiteLabelData.orgPhone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whiteLabelData]);

  const handleLogoFileInputClick = () => {
    if (logoInputRef.current) logoInputRef.current.click();
  };

  return (
    <div className={classes()}>
      <h1>Custom Labeling</h1>
      <fieldset>
        <legend>Logo</legend>
        <label htmlFor="logo-upload-input" id="logo-upload-input">
          We highly recommend that you upload an .png or .jpeg file that is 50 x 50 pixels and under
          5mb in size.
          <div className={classes('logo-upload-strip')}>
            {!orgLogoUrl && (
              <button
                className={classes('logo-upload-preview-empty')}
                onClick={handleLogoFileInputClick}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' || event.key === ' ') {
                    event.preventDefault();
                    handleLogoFileInputClick();
                  }
                }}
                aria-label="Upload logo"
                aria-describedby="logo-upload-input form-file-error"
              >
                <ImageUploadSvg aria-hidden />
              </button>
            )}
            {orgLogoUrl && (
              <img
                alt="upload preview"
                className={classes('logo-upload-preview')}
                src={orgLogoUrl}
                height="40"
                width="100"
              />
            )}
            <button
              className={classes('logo-upload-button')}
              aria-describedby="logo-upload-input form-file-error"
              onClick={handleLogoFileInputClick}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  event.preventDefault();
                  handleLogoFileInputClick();
                }
              }}
            >
              Upload
            </button>
            {(formErrors.orgLogo || orgLogoUrl) && (
              <button
                className={classes('logo-delete-button')}
                onClick={handleLogoFileDelete}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' || event.key === ' ') {
                    event.preventDefault();
                    handleLogoFileDelete();
                  }
                }}
              >
                Delete
              </button>
            )}
          </div>
          <input
            ref={logoInputRef}
            id="logo-upload-input"
            className={classes('logo-upload-input')}
            type="file"
            accept="image/png, image/jpeg"
            onChange={handleLogoFileInput}
            tabIndex={0}
            aria-describedby="logo-upload-input form-file-error"
          />
        </label>
        <span className={classes('error-text')} id="form-file-error">
          {formErrors.orgLogo === 'filetype' &&
            'This file type is not supported. Please upload a .jpeg or .png file type.'}
          {formErrors.orgLogo === 'resolution' &&
            'This file resolution is not within 50x50 px, please adjust and try again.'}
          {formErrors.orgLogo === 'filesize' &&
            'This file size is too large, please adjust and try again.'}
          &nbsp;
        </span>
      </fieldset>

      <fieldset>
        <legend>Organization Name</legend>
        <label id="name-description">
          This field will allow you to customize how patients visualize your organization's name.
          Please limit to less than 50 characters.
          <input
            id="orgNameInput"
            type="text"
            value={orgName}
            onChange={handleOrgNameInput}
            onBlur={handleOrgNameInputBlur}
            required
            aria-describedby="name-description name-error"
          />
        </label>
        <span id="name-error" className="error-text" role="alert">
          {formErrors.orgName === 'length' &&
            'The name is too long, please limit to 50 characters or less.'}
          &nbsp;
        </span>
      </fieldset>

      <fieldset>
        <legend>Organization URL</legend>
        <label id="url-description">
          This field will allow you to add a unique URL to the TigerConnect Patient Engagement
          service. Please limit to less than 50 characters.
          <input
            id="orgUrlInput"
            type="text"
            placeholder="Enter URL.med.tc"
            value={orgUrl}
            onBlur={handleOrgURLInputBlur}
            onFocus={handleOrgURLInputFocus}
            onChange={handleOrgURLInput}
            aria-describedby="url-description url-error"
          />
        </label>
        <span id="url-error" className="error-text" role="alert">
          {formErrors.orgUrl === 'length' &&
            'The URL is too long, please limit to 50 characters or less.'}
          &nbsp;
        </span>
      </fieldset>

      <fieldset>
        <legend>Call Back Number</legend>
        <label id="phone-description">
          Enter the phone number for your organization. If any of your patients have trouble with
          TigerConnect Patient Engagement this number will be visible for them for further
          communications.
          <input
            id="orgPhoneInput"
            type="text"
            placeholder="1 (000) 000-0000"
            onFocus={handleOrgPhoneInputFocus}
            onBlur={handleOrgPhoneInputBlur}
            inputMode="numeric"
            value={orgPhone}
            onChange={handleOrgPhoneInput}
            aria-describedby="phone-description phone-error"
          />
        </label>
        <span id="phone-error" className="error-text" role="alert">
          {formErrors.orgPhone === 'invalid' &&
            'The phone number you provided is invalid, please use a valid number.'}
          &nbsp;
        </span>
      </fieldset>

      <div className={classes('save-container')}>
        <div className={classes('save-content', { valid: validForm })}>
          <div>
            <button onClick={handleFormSubmit} disabled={!validForm}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  function handleOrgNameInput(event) {
    const value = event.currentTarget.value;
    if (value.length > 50) return;
    setOrgName(value);
  }

  function handleOrgNameInputBlur(event) {
    const value = event.currentTarget.value;
    if (value === '') setOrgName(currentOrganization.name);
  }

  function handleOrgURLInput(event) {
    const value = event.currentTarget.value;
    if (value.length > 50) return;
    setOrgUrl(value);
  }

  function handleOrgURLInputBlur(event) {
    let value = event.currentTarget.value;

    if (value === '') {
      setOrgUrl('');
      return;
    }

    const match = value.match(/[a-zA-Z0-9-]/g);
    if (match[0] === '-') match.shift();
    if (match[match.length - 1] === '-') match.pop();
    value = match.join('');

    setOrgUrl(`${value}${TIGERTOUCH_DOMAIN}`);
  }

  function handleOrgURLInputFocus(event) {
    const value = event.currentTarget.value;
    const strippedValue = value.slice(0, -TIGERTOUCH_DOMAIN.length);
    setOrgUrl(strippedValue);
  }

  function handleOrgPhoneInput(event) {
    const value = event.currentTarget.value;
    if (value.length < orgPhone.length) {
      if (value === '') {
        setOrgPhone('1 ');
      } else {
        setOrgPhone(value);
      }

      return;
    }
    let strippedValue = value.replace(/\D/g, '').substring(1);

    const length = strippedValue.length;
    if (length > 10) return;
    if (length >= 1) strippedValue = '(' + strippedValue.substring(0);
    if (length >= 3)
      strippedValue = strippedValue.substring(0, 4) + ') ' + strippedValue.substring(4);
    if (length >= 6)
      strippedValue = strippedValue.substring(0, 9) + '-' + strippedValue.substring(9);

    setOrgPhone(`1 ${strippedValue}`);
  }

  function handleOrgPhoneInputFocus(event) {
    const value = event.currentTarget.value;
    if (value === '') setOrgPhone('1 ');
  }

  function handleOrgPhoneInputBlur(event) {
    const value = event.currentTarget.value;
    setFormErrors(Object.assign({}, formErrors, { orgPhone: '' }));

    if (value.length < 4) {
      setOrgPhone('');
      return;
    }

    const strippedValue = value.replace(/\D/g, '');
    if (strippedValue.length < 11) {
      setFormErrors(Object.assign({}, formErrors, { orgPhone: 'invalid' }));
      return;
    }
  }

  function handleLogoFileDelete(event) {
    if (event) event.preventDefault();

    if (logoInputRef) logoInputRef.current.value = '';
    setOrgLogoUrl('');
    setFormErrors(Object.assign({}, formErrors, { orgLogo: '' }));
  }

  async function handleLogoFileInput(event) {
    const logoFile = event.currentTarget.files[0];
    const { data = '', type = '', error = '' } = await processWhiteLabelLogo(logoFile);
    if (error) {
      setFormErrors(Object.assign({}, formErrors, { orgLogo: error }));
      return;
    }
    setOrgLogoUrl(data);
    setOrgLogoImageType(type);
    setFormErrors(Object.assign({}, formErrors, { orgLogo: '' }));
  }

  async function handleFormSubmit(event) {
    if (event) event.preventDefault();

    let orgLogo;
    if (orgLogoUrl.startsWith('https://')) orgLogo = orgLogoUrl;
    else if (orgLogoUrl === '') orgLogo = '';
    else orgLogo = orgLogoUrl.split(',')[1];

    const payload = {
      orgName,
      orgLogoImageType,
      orgLogo,
      orgUrl: orgUrl.slice(0, -TIGERTOUCH_DOMAIN.length),
      orgPhone,
    };

    try {
      await setWhiteLabelData(currentOrganizationId, payload);
      await fetchWhiteLabelData(currentOrganizationId);
      openModal('success');
    } catch (error) {
      console.error(error);
      openModal('failure');
    }
  }
}

CustomLabeling.propTypes = {
  currentOrganization: propTypes.organization.isRequired,
  currentOrganizationId: PropTypes.string.isRequired,
  fetchWhiteLabelData: PropTypes.func.isRequired,
  processWhiteLabelLogo: PropTypes.func.isRequired,
  setWhiteLabelData: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  whiteLabelData: PropTypes.shape({
    orgName: PropTypes.string,
    orgUrl: PropTypes.string,
    orgLogoUrl: PropTypes.string,
    orgPhone: PropTypes.string,
  }),
};

export default mobxInjectSelect({
  messengerStore: ['currentOrganization', 'currentOrganizationId'],
  modalStore: ['openModal'],
  patientAdminStore: [
    'fetchWhiteLabelData',
    'setWhiteLabelData',
    'processWhiteLabelLogo',
    'whiteLabelData',
  ],
})(CustomLabeling);

import Video from 'twilio-video';
import { Client as TigerConnectClient } from './js-sdk/src';

import { UsersService } from './js-sdk/src/services/Admin/UsersService';
import OrganizationsService from './js-sdk/src/services/Admin/OrganizationsService';
import MultiOrgService from './js-sdk/src/services/MultiOrgService';
import PbxService from './js-sdk/src/services/PbxService';
import SearchService from './js-sdk/src/services/SearchService';

import { params } from './params';
import updateParamsWithIdpState from './common/utils/updateParamsWithIdpState';
import ScimService from 'js-sdk/src/services/ScimService';

import type { ClientConfiguration } from 'types';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(global as any)._tcShouldPolyfill = false;

const FEATURE_FLAGS = [
  'allowDelPhoneNumber',
  'allowPresence',
  'allowRTU',
  'allowUT',
  'allowGA',
  'allowGAVC',
  'reportErrors',
  'reportPerformance',
  'wantCondensedReplays',
  'wantWebSockets',
];
const ALLOWED_PARAMS = [
  'adminFeatureBranch',
  'apiEnv',
  'baseUrl',
  'eventsDebugging',
  'iss',
  'launch',
  'launchType',
  'localAdminUrl',
  'localCnUrl',
  'localMessengerUrl',
  'localRolesUrl',
  'logLevel',
  'rolesFeatureBranch',
  'ttRoute',
  'webInfraUrl',
  'webSocketUrl',
  ...FEATURE_FLAGS,
];
const BOOLEAN_PARAMS = ['eventsDebugging', ...FEATURE_FLAGS];
const appVersion = buildInfo.version;

updateParamsWithIdpState(params);

const config = ALLOWED_PARAMS.reduce(
  (all, key) => {
    if (key in params) {
      if (BOOLEAN_PARAMS.includes(key)) {
        all[key] = params[key] === 'true';
      } else {
        all[key] = params[key];
      }
    }

    return all;
  },
  {
    wantCondensedReplays: false,
  } as Record<string, unknown>
);

export const xyzMatch = global.location.host.match(/\.xyz/);
export const featureMatch = global.location.pathname.match(/feature\/([-\w]*)\/app\//);
const urlMatch = global.location.pathname.match(/([a-z]+\d*)\/app\//);
export const localHostMatch = global.location.host.match('^(localhost|[0-9.]+):');

if (!config.apiEnv && featureMatch) {
  config.apiEnv = 'uat';
} else if (!config.apiEnv && urlMatch) {
  config.apiEnv = urlMatch[1];
} else {
  if (config.localAdminUrl === undefined && localHostMatch) {
    config.localAdminUrl = `${window.location.protocol}//${localHostMatch[1]}:1339`;
  }
  if (config.localCnUrl === undefined && localHostMatch) {
    config.localCnUrl = `${window.location.protocol}//${localHostMatch[1]}:1339`;
  }
  if (config.localRolesUrl === undefined && localHostMatch) {
    config.localRolesUrl = `${window.location.protocol}//${localHostMatch[1]}:3000`;
  }
  if (config.localMessengerUrl === undefined && localHostMatch) {
    config.localMessengerUrl = `${window.location.protocol}//${localHostMatch[1]}:9002`;
  }
}

if (config.localAdminUrl === 'null') {
  config.localAdminUrl = null;
}
if (config.localCnUrl === 'null') {
  config.localCnUrl = null;
}
if (config.localRolesUrl === 'null') {
  config.localRolesUrl = null;
}
if (config.localMessengerUrl === 'null') {
  config.localMessengerUrl = null;
}

const client = new TigerConnectClient({
  keepConversationsForAllForums: true,
  manuallyRequestEventQueueBatch: true,
  partnerName: 'react',
  product: 'wrut',
  twilioVideo: Video,
  version: appVersion,
  ...config,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}) as Record<string, Record<string, (...args: unknown[]) => any>> & {
  // Overrides
  admin: never;
  adminUsers: UsersService;
  adminOrganizations: OrganizationsService;
  scim: ScimService;
  multiOrg: MultiOrgService;
  pbx: PbxService;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  api: Record<string, Record<string, (...args: unknown[]) => any>>;
  config: ClientConfiguration;
  configure: (config: Partial<ClientConfiguration>) => void;
  currentUser: { id: string; organizationIds?: Array<string>; roles: { id: string }[] };
  on: (event: unknown, handler: unknown) => void;
  off: (event: unknown, handler: unknown) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  models: Record<string, Record<string, (...args: unknown[]) => any>>;
  search: SearchService;
};

const shouldReport =
  ['prod', 'uat', 'production'].includes(client.config.apiEnv) && !featureMatch && !localHostMatch;

client.configure({
  reportErrors: shouldReport || config.reportErrors === true,
  reportPerformance: shouldReport || config.reportPerformance === true,
});

export default client;

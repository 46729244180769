// @ts-nocheck
import _, { isEmpty } from 'lodash';
import _flatten from 'lodash-bound/flatten';
import _initial from 'lodash-bound/initial';
import _map from 'lodash-bound/map';
import _uniq from 'lodash-bound/uniq';
import _without from 'lodash-bound/without';
import moment from 'moment';
import { decorator as reusePromise } from 'reuse-promise';
import Hashes from 'jshashes';
import * as errors from '../errors';
import {
  AllowedSendersReason as Reason,
  ConversationAnchorPoints,
  EscalationChangeAction,
  FeatureService,
  GroupType,
  MessageMetadataNamespaces,
  MessagePriority,
  MessageSenderStatus,
  MessageSubType,
  MessageType,
  Networks,
  ProcessingActionTypes,
} from '../models/enums';
import MessageRecipientStatus, {
  MESSAGE_STATUS_ORDER,
} from '../models/enums/MessageRecipientStatus';
import { MembershipChangeEvent, Message } from '../types/Common';
import { arrayWrap, jsonCloneDeep } from '../utils';
import { isEmail } from '../utils/email';
import { isPhone } from '../utils/phone';
import { secondsToDurationFormat } from '../utils/date';
import BaseService from './BaseService';

const md5 = new Hashes.MD5();

const BANG_MESSAGE_TYPES = [
  MessageType.ESCALATION_EXECUTION_CHANGE,
  MessageType.GROUP_MEMBERSHIP_CHANGE,
  MessageType.AUTO_FORWARD,
];

const DEFAULT_FETCH_TIMELINE_ITEMS = 15;
const DEFAULT_SELECT_CONVERSATION_ITEMS = 20;
const DELIVERED_STATUS = MessageRecipientStatus.toServer(MessageRecipientStatus.DELIVERED);

const ESCALATION_ACTION_TEXT = {
  [EscalationChangeAction.ACKNOWLEDGED]: 'Acknowledged',
  [EscalationChangeAction.CANCELLED]: 'Cancelled',
  [EscalationChangeAction.INITIATED]: 'Initiated',
  [EscalationChangeAction.MEMBER_ADDED]: 'Added',
  [EscalationChangeAction.NO_RESPONSE]: 'ended with No Response',
};

const GROUP_TYPES_WITH_METADATA = [
  GroupType.GROUP,
  GroupType.FORUM,
  GroupType.ROLE_ASSIGNMENT,
  GroupType.ROLE_P2P,
];

const GROUP_MEMBERSHIP_ACTION_TEXT = {
  ADD: 'added',
  JOIN: 'joined',
  LEAVE: 'left',
  REMOVE: 'removed',
  DENY: 'declined',
};

const GROUP_MEMBERSHIP_ACTION_PREPOSITION = {
  ADD: 'to',
  REMOVE: 'from',
  DENY: 'to join',
};

const GROUP_TYPE_LABEL = {
  [GroupType.ACTIVATED_TEAM]: 'group',
  [GroupType.ESCALATION]: 'escalation',
  [GroupType.FORUM]: 'forum',
  [GroupType.GROUP]: 'group',
  [GroupType.PATIENT_CARE]: 'care team',
  [GroupType.ROLE_P2P]: 'conversation',
  [GroupType.PATIENT_MESSAGING]: 'group',
  [GroupType.INTRA_TEAM]: 'team',
};

const NEW_OR_DELIVERED = [MessageRecipientStatus.NEW, MessageRecipientStatus.DELIVERED];
const READ_STATUS = MessageRecipientStatus.toServer(MessageRecipientStatus.READ);

const ROLE_ACTION_TEXT = {
  OPT_IN: 'opted into',
  OPT_OUT: 'opted out of',
};

type PlaceholderBang = {
  event: MembershipChangeEvent;
  message: Message;
};

export default class ConversationsService extends BaseService {
  _placeholderBangs: {
    [key: string]: PlaceholderBang[];
  };

  mounted() {
    this.__conversationsToClear = {};
    this.__escalationEnabledOrganizations = [];
    this.__highestHistoricalSortNumber = 0;
    this.__queuedConversationsToReload = [];
    this.__selectedConversations = {};
    this._convByConvHandle = {};
    this._conversationsPendingCounterPartyReload = {};
    this._fetched = false;
    this._placeholderBangs = {};
    this._roleConversationsProcessingEvent = {};
    this._roleMessagesToRefresh = {};
    this._rolesFriendsEventPromise = null;
    this.host.on('message:sending', this._onSendingMessage);
    this.host.on('message:sent', this._onSentMessage);
    this.host.models.Group.on('afterEject', this._onRemoveGroup);
    this.host.models.Group.on('afterInject', this._onChangeGroup);
    this.host.models.Team.on('afterEject', this._onRemoveTeam);
    this.host.models.Message.on('afterInject', this._onChangeMessage);
    this.host.models.Message.on('afterEject', this._onRemoveMessage);
    this.host.models.Organization.on('afterInject', this._onChangeOrganization);
    this.host.models.User.on('afterInject', this._onChangeUser);
  }

  dispose() {
    this._convByConvHandle = {};
    this._conversationsPendingCounterPartyReload = {};
    this._fetched = false;
    this._placeholderBangs = {};
    this._rolesFriendsEventPromise = null;
    this.__escalationEnabledOrganizations = [];
    this.__queuedConversationsToReload = [];
    this.host.removeListener('message:sending', this._onSendingMessage);
    this.host.removeListener('message:sent', this._onSentMessage);
    this.host.models.Group.removeListener('afterEject', this._onRemoveGroup);
    this.host.models.Group.removeListener('afterInject', this._onChangeGroup);
    this.host.models.Team.removeListener('afterEject', this._onRemoveTeam);
    this.host.models.Message.removeListener('afterInject', this._onChangeMessage);
    this.host.models.Message.removeListener('afterEject', this._onRemoveMessage);
    this.host.models.Organization.removeListener('afterInject', this._onChangeOrganization);
    this.host.models.User.removeListener('afterInject', this._onChangeUser);
  }

  __isBangInTimeline(
    sortNumber: number,
    isConversationLive: boolean,
    { removedFromGroup }: { removedFromGroup?: boolean } = {}
  ) {
    const isHistoricalBang =
      !this.host.isInitialBangReplayDone && sortNumber < this.__highestHistoricalSortNumber;

    if (isHistoricalBang || removedFromGroup) {
      return false;
    }

    return isConversationLive;
  }

  clearTimeline(conversationId: string | Object, { emit = true } = {}) {
    const conversation = this._resolveEntity(conversationId, 'conversation');
    if (!conversation)
      throw new errors.NotFoundError(this.host.models.Conversation.name, conversationId);
    conversationId = this._resolveModelId(conversationId);

    this.clearMessageStatuses(conversation, { emit: false });

    const { higherContinuation, lowerContinuation, timeline } = conversation;
    let messagesCleared = 0;
    for (const message of timeline) {
      if (!message.isEphemeral) {
        message.inTimeline = false;
        messagesCleared += 1;
      }
    }

    conversation.isLive = false;
    higherContinuation.itemsEstimate = null;
    lowerContinuation.itemsEstimate = null;

    if (emit) this.emit('clearTimeline', conversationId);

    if (this.config.condensedReplays) {
      this.host.models.Conversation.inject(conversation);
    } else {
      this.__reloadConversation(conversation, { notify: false });
    }

    return messagesCleared;
  }

  _isHistoricalBang = (message) => {
    return (
      BANG_MESSAGE_TYPES.includes(message.messageType) &&
      message.sortNumber < this.__highestHistoricalSortNumber
    );
  };

  _isIrrelevantBang = (message) => {
    return (
      BANG_MESSAGE_TYPES.includes(message.messageType) &&
      !this.__selectedConversations[message.conversationId]
    );
  };

  async fetchAllAlertMessagesForOrganization(organizationId) {
    const organization = this.host.organizations.getById(organizationId);

    if (!organization) return 0;
    let totalAlertMessages = 0;
    for (const conversation of organization.alertConversations) {
      totalAlertMessages += await this.selectConversation(conversation, {
        fetchAllItems: true,
        markAsRead: false,
        maxItemsPerBatch: 400, // RW-3664: ~3-4 second fetch time
      });
    }

    return totalAlertMessages;
  }

  _doesAllowedSendersContainRole = (allowedSenders) => {
    return allowedSenders.some((entity) => entity.$entityType === 'role');
  };

  async fetchHistoricalMessages(
    conversation,
    {
      anchorPoint,
      direction,
      maxItems,
      shouldReturnMessagesAdded = false,
    }: {
      anchorPoint: ConversationAnchorPoints;
      direction: 'lower' | 'higher';
      maxItems: number;
      shouldReturnMessagesAdded?: boolean;
    }
  ) {
    const isFullyFetchedProp = this._doesAllowedSendersContainRole(conversation.allowedSenders)
      ? 'fullyFetchedRole'
      : 'fullyFetchedUser';

    if (
      conversation?.lowerContinuation?.[isFullyFetchedProp] &&
      conversation?.higherContinuation?.[isFullyFetchedProp]
    ) {
      return 0;
    }

    if (anchorPoint === ConversationAnchorPoints.FIRST_UNREAD_ITEM) {
      if (conversation?.timeline?.length === 0) {
        anchorPoint = ConversationAnchorPoints.CONVERSATION_END;
      } else {
        anchorPoint = ConversationAnchorPoints.CONTINUATION;
        direction = 'lower';
      }
    }

    if (anchorPoint === ConversationAnchorPoints.CONTINUATION) {
      if (direction && conversation[`${direction}Continuation`][isFullyFetchedProp]) {
        return 0;
      } else if (direction === 'higher') {
        conversation.higherContinuation[isFullyFetchedProp] = true;
        return 0;
      }
    }

    let continuation;
    if (anchorPoint === ConversationAnchorPoints.CONTINUATION) {
      const { timeline } = conversation;
      const timelineWithoutBangs = timeline.filter((timelineItem) => {
        return (
          timelineItem.id &&
          typeof timelineItem.id === 'string' &&
          !timelineItem.id.startsWith('bang:')
        );
      });
      if (timelineWithoutBangs.length === 0) {
        anchorPoint = ConversationAnchorPoints.CONVERSATION_END;
        direction = undefined;
      } else {
        const lowestSortNumber = timelineWithoutBangs[0]?.sortNumber;
        const highestSortNumber = timelineWithoutBangs[timelineWithoutBangs.length - 1]?.sortNumber;
        continuation = direction === 'lower' ? lowestSortNumber : highestSortNumber;
      }
    }

    try {
      const { messages: rawMessages = [], is_fully_fetched: isFullyFetched = false } =
        await this.host.api.roster.getTimeline(
          conversation.counterPartyId,
          conversation.counterPartyType,
          conversation.organizationId,
          {
            anchorPoint: ConversationAnchorPoints.toServer(anchorPoint),
            size: maxItems,
            continuation,
            direction,
            markAsDelivered: true,
          }
        );

      let conversationUpdated = false;
      if (direction) {
        conversation[`${direction}Continuation`][isFullyFetchedProp] = isFullyFetched;
        conversationUpdated = true;
      } else if (anchorPoint === ConversationAnchorPoints.CONVERSATION_END) {
        if (rawMessages.length === 0 || rawMessages.length < maxItems) {
          conversation.lowerContinuation[isFullyFetchedProp] = true;
        }

        conversation.higherContinuation[isFullyFetchedProp] = true;
        conversationUpdated = true;
      }

      if (conversationUpdated) {
        this.host.models.Conversation.touch(conversation);
      }

      rawMessages.forEach((rawMessage) => {
        const { message } = this._reactToMessageEvent({ data: rawMessage });
        message.inTimeline = true;
        this.host.models.Message.inject(message);
      });

      if (shouldReturnMessagesAdded) {
        return { itemsAdded: rawMessages.length, messagesAdded: rawMessages };
      } else {
        return rawMessages.length;
      }
    } catch (err) {
      console.error(err);

      conversation.lowerContinuation[isFullyFetchedProp] = true;
      conversation.higherContinuation[isFullyFetchedProp] = true;
      this.host.models.Conversation.touch(conversation);
    }

    return 0;
  }

  async fetchTimeline(
    conversationId: string | Object,
    {
      anchorPoint = ConversationAnchorPoints.CONVERSATION_END,
      continuation,
      fetchAllItems = false,
      markAsDelivered = true,
      maxItems = DEFAULT_FETCH_TIMELINE_ITEMS,
      isConversationBeingSelectedForPrintMode = false,
      shouldReturnMessagesAdded = false,
    } = {}
  ) {
    const conversation = this._resolveEntity(conversationId, 'conversation');
    if (!conversation)
      throw new errors.NotFoundError(this.host.models.Conversation.name, conversationId);
    conversationId = this._resolveModelId(conversationId);

    if (this.config.condensedReplays) {
      const returned = await this._fetchTimelineCR(conversationId, {
        anchorPoint,
        continuation,
        conversation,
        fetchAllItems,
        markAsDelivered,
        maxItems,
      });

      return returned;
    }

    if (
      anchorPoint !== ConversationAnchorPoints.CONTINUATION &&
      conversation.isLive &&
      ((!fetchAllItems && conversation.timeline.length >= maxItems) ||
        (conversation.lowerContinuation && conversation.lowerContinuation.itemsEstimate === 0)) &&
      !isConversationBeingSelectedForPrintMode
    ) {
      return 0;
    }

    const { content, timeline } = conversation;
    const { firstUnreadMessage } = conversation;

    let end = content.length;
    let itemsAdded = 0;
    const messagesToAdd = [];
    let start = end - maxItems;
    let target = end;

    if (anchorPoint === ConversationAnchorPoints.CONVERSATION_END) {
      this.clearTimeline(conversation, { emit: false });
    } else if (anchorPoint === ConversationAnchorPoints.FIRST_UNREAD_ITEM) {
      this.clearTimeline(conversation, { emit: false });

      let index = -1;
      for (const message of content) {
        index++;
        target = index;
        if (firstUnreadMessage === message) break;
      }
      if (target < content.length) {
        target = Math.min(target + maxItems, content.length);
      }
    } else if (anchorPoint === ConversationAnchorPoints.CONTINUATION) {
      const firstInTimeline = timeline[0] ? timeline[0].sortNumber : Infinity;
      const lastInTimeline = timeline[timeline.length - 1]
        ? timeline[timeline.length - 1].sortNumber
        : 0;
      const sortNumber = continuation === 'LOWER' ? firstInTimeline : lastInTimeline;

      if (sortNumber === 0) {
        target = target - maxItems;
      } else {
        let index = -1;
        for (const message of content) {
          index++;
          target = index;
          if (continuation === 'HIGHER' && message.sortNumber > sortNumber) {
            break;
          }
          if (continuation === 'LOWER' && message.sortNumber >= sortNumber) {
            break;
          }
        }
      }
    }

    if (anchorPoint !== ConversationAnchorPoints.CONVERSATION_END) {
      if (anchorPoint === ConversationAnchorPoints.CONTINUATION && continuation === 'HIGHER') {
        start = target;
        end = start + maxItems;
      } else {
        if (target === content.length - 1) {
          target += 1;
        }
        end = target;
        start = target - maxItems;
      }
    }

    end = Math.min(end, content.length + 1);
    start = Math.max(start, 0);

    const determineIfAdding = (message) => {
      if (!message.inTimeline) {
        if (!this.host.isInitialBangReplayDone && this._isHistoricalBang(message)) {
          return;
        }

        message.inTimeline = true;
        this.host.models.Message.inject(message);
        itemsAdded += 1;
      }
    };

    const attemptToAdd = () => {
      messagesToAdd.push(...content.slice(start, end));
      messagesToAdd.forEach(determineIfAdding);
      if (itemsAdded === 0) {
        if (
          anchorPoint === ConversationAnchorPoints.CONTINUATION &&
          continuation === 'HIGHER' &&
          end !== content.length
        ) {
          end = Math.min(content.length, end + maxItems);
          attemptToAdd();
        } else if (start !== 0) {
          start = Math.max(0, start - maxItems);
          attemptToAdd();
        }
      }
    };

    attemptToAdd();

    if (this.config.allowRolesPerformance && itemsAdded < maxItems) {
      return await this.fetchHistoricalMessages(conversation, {
        anchorPoint,
        direction: continuation?.toLowerCase(),
        maxItems,
        shouldReturnMessagesAdded,
      });
    }

    if (shouldReturnMessagesAdded) {
      return { itemsAdded, messagesToAdd };
    } else {
      return itemsAdded;
    }
  }

  async _fetchTimelineCR(
    conversationId,
    {
      anchorPoint = ConversationAnchorPoints.CONVERSATION_END,
      continuation,
      conversation,
      fetchAllItems = false,
      markAsDelivered = true,
      maxItems = DEFAULT_FETCH_TIMELINE_ITEMS,
    } = {}
  ) {
    if (
      anchorPoint !== ConversationAnchorPoints.CONTINUATION &&
      conversation.isLive &&
      ((!fetchAllItems && conversation.timeline.length >= maxItems) ||
        (conversation.lowerContinuation && conversation.lowerContinuation.itemsEstimate === 0))
    ) {
      return 0;
    }

    if (anchorPoint === ConversationAnchorPoints.CONVERSATION_END) {
      this.clearTimeline(conversation, { emit: false });
    } else if (anchorPoint === ConversationAnchorPoints.FIRST_UNREAD_ITEM) {
      conversation.firstUnreadMessage = null;
      this.clearTimeline(conversation, { emit: false });
    }

    const {
      direction,
      higher_continuation,
      lower_continuation,
      max_items: serverMaxItems,
      timeline,
    } = await this.host.api.conversations.fetchTimeline(
      conversationId,
      ConversationAnchorPoints.toServer(anchorPoint),
      maxItems,
      {
        continuation,
        markAsDelivered,
      }
    );

    let { higherContinuation, lowerContinuation } = conversation;
    let firstMessageInConversation = null;

    if (serverMaxItems) maxItems = serverMaxItems;

    for (const rawMessage of timeline) {
      const { xmlns } = rawMessage;

      if (xmlns === 'tigertext:iq:bang') {
        const eventId = rawMessage['bang_id'] || `${rawMessage['sort_number']}`;

        if (eventId) {
          this.host.groups.__handleBang({ data: rawMessage, eventId, alwaysAddToTimeline: true });
        } else {
          this.logger.warn('Skipping bang with no event id');
        }
      } else if (xmlns === 'tigertext:iq:message' || xmlns === 'tigertext:iq:group_message') {
        if (!rawMessage.conversation_id) {
          rawMessage.conversation_id = conversationId;
        }

        const { message } = this._reactToMessageEventCR({
          data: rawMessage,
          injectConversation: false,
        });
        message.inTimeline = true;

        if (!message.isOutgoing) {
          const attrs = {
            id: `${message.id}:${message.counterPartyId}`,
            messageId: message.id,
            status: MessageRecipientStatus.resolve(rawMessage.status),
            userId: this.host.currentUserId,
          };
          this.host.models.MessageStatusPerRecipient.inject(attrs);
        }

        message.senderStatus = MessageSenderStatus.SENT;
        const injectedMessage = this.host.models.Message.inject(message);

        if (!firstMessageInConversation) {
          firstMessageInConversation = injectedMessage;
        }
      } else {
        this.logger.warn(`Skipping timeline entry with unrecognized xmlns: ${xmlns}`);
      }
    }

    if (direction === 'Higher' || direction === 'Initial') {
      let itemsEstimate = timeline.length === 0 || timeline.length < maxItems ? 0 : 1;
      if (anchorPoint === ConversationAnchorPoints.CONVERSATION_END) {
        itemsEstimate = 0;
      }

      if (itemsEstimate === 1 && direction === 'Initial') {
        const lastTimelineItem = timeline[timeline.length - 1];
        const { lastMessage } = conversation;

        if (
          anchorPoint === ConversationAnchorPoints.FIRST_UNREAD_ITEM &&
          firstMessageInConversation &&
          !firstMessageInConversation.isUnread
        ) {
          itemsEstimate = 0;
        } else if (lastMessage && lastTimelineItem.message_id === lastMessage.id) {
          itemsEstimate = lastMessage.isUnread ? 1 : 0;
        }
      }

      higherContinuation = {
        continuation: { higher_continuation },
        itemsEstimate,
      };
    }

    if (direction === 'Lower' || direction === 'Initial') {
      const itemsEstimate = timeline.length === 0 || timeline.length < maxItems ? 0 : 1;

      lowerContinuation = {
        continuation: { lower_continuation },
        itemsEstimate,
      };
    }

    const itemsAdded = timeline.length;
    conversation.isLive = higherContinuation.itemsEstimate === 0;
    conversation.higherContinuation = higherContinuation;
    conversation.lowerContinuation = lowerContinuation;

    this.host.models.Conversation.inject(conversation);

    if (
      anchorPoint !== ConversationAnchorPoints.CONTINUATION &&
      conversation.lowestSortNumber !== undefined
    ) {
      await this.markAsDelivered(conversation.id);
    }

    return itemsAdded;
  }

  @reusePromise()
  async findAll(options = {}) {
    options = _.defaults(options, { includeMutes: true });

    if (this.config.condensedReplays) {
      options.includeMutes = false;
    }

    if (this._fetched) return this.getAll();

    this.emit('roster:download:start');

    const [roster] = await Promise.all([
      this.host.api.roster.findAll(),
      options.includeMutes ? this.host.mute.findAll() : Promise.resolve(),
    ]);

    this.emit('roster:download:stop');

    let conversations;
    if (this.config.condensedReplays) {
      conversations = this._processFindAllCR(roster);
    } else {
      conversations = this._processFindAll(roster);
    }

    if (this.host.isInitialMessageReplayDone) {
      this.host._reloadPendingConversations({ flushImmediately: true });
    }

    return conversations;
  }

  async findAllForOrganization(organizationId) {
    organizationId = this._resolveModelIdWithTypes(organizationId, 'organization');

    if (!this.config.condensedReplays) {
      throw new errors.NotSupportedError(
        'findAllForOrganization() may not be called if this.config.condensedReplays=false'
      );
    }

    if (this._fetched) return this.getAllForOrganization(organizationId);

    const roster = await this.host.api.roster.findAll({ organizationId });

    return this._processFindAllCR(roster);
  }

  _processFindAllCR(roster) {
    const nonUsers = [];
    const users = [];
    const ensureOptions = {
      counterParty: this.config.ensureFullConversationCounterParty ? 'full' : 'placeholder',
      groupMembers: this.config.ensureFullConversationGroupMembers ? 'full' : 'placeholder',
      organization: 'placeholder',
    };

    for (let origIndex = 0; origIndex < roster.length; origIndex++) {
      const rawConversation = roster[origIndex];
      const { entity } = rawConversation;
      if (!entity) continue;

      const entityType = this.host.modelNameByEntityType(entity.type);

      if (entityType === 'user') {
        users.push({ rawConversation, origIndex });
      } else if (entityType) {
        nonUsers.push({ rawConversation, origIndex });
      }
    }

    // users are a first priority to inject as they may be members in groups,
    // and injecting groups triggers ensures members. if the user exists before the ensure, group
    // wouldn't need to put a placeholder member

    for (const rosterEntry of users) {
      const { rawConversation, origIndex } = rosterEntry;
      rosterEntry.conversation = this.__injectConversation(rawConversation, {
        origIndex,
        shouldDisplay: true,
      });
    }

    for (const rosterEntry of nonUsers) {
      const { rawConversation, origIndex } = rosterEntry;
      rosterEntry.conversation = this.__injectConversation(rawConversation, {
        hasCurrentUser: true,
        origIndex,
        shouldDisplay: true,
      });
    }

    const conversations = [];

    while (nonUsers.length > 0 || users.length > 0) {
      const rosterEntry =
        nonUsers.length === 0
          ? users.shift()
          : users.length === 0
          ? nonUsers.shift()
          : nonUsers[0].origIndex < users[0].origIndex
          ? nonUsers.shift()
          : users.shift();

      const { conversation } = rosterEntry;
      this.__ensureEntities(conversation, ensureOptions);
      conversations.push(conversation);
    }

    this._fetched = true;

    return conversations;
  }

  _processFindAll(roster) {
    const nonUsers = [];
    const toProcess = [];
    const users = [];

    // first, loop through all entries and inject users
    // users are a first priority to inject as they may be members in groups,
    // and injecting groups triggers ensures members. if the user exists before the ensure, group
    // wouldn't need to put a placeholder member

    for (let origIndex = 0; origIndex < roster.length; origIndex++) {
      let rosterEntry = roster[origIndex];

      const { organization_key: organizationId, type, ...entityAttrs } = rosterEntry;

      const entityType = this.host.modelNameByTypeNS(type);
      entityAttrs.organizationId = organizationId;

      rosterEntry = {
        entityAttrs,
        entityType,
        organizationId,
        origIndex,
      };

      if (rosterEntry.entityType === 'user') {
        rosterEntry.counterParty = this.__injectCounterParty(rosterEntry);
        users.push(rosterEntry);
      } else if (rosterEntry.entityType) {
        if (entityType === 'group') {
          rosterEntry.groupType = this.host.groups.__extractGroupTypeFromAttrs(entityAttrs);
        }

        if (rosterEntry.groupType === GroupType.FORUM) {
          delete entityAttrs.members;
        } else if (
          rosterEntry.groupType === GroupType.PATIENT_MESSAGING &&
          rosterEntry.entityAttrs &&
          rosterEntry.entityAttrs.metadata
        ) {
          const { avatar, metadata, organizationId } = rosterEntry.entityAttrs;
          const { patient_id: patientId, patient_contact_id: patientContactId } = metadata;
          if (patientContactId || patientId) {
            this.host.models.User.injectPlaceholder({
              id: patientContactId || patientId,
              avatar,
              metadata,
              organizationId,
            });
          }
        }

        toProcess.push(rosterEntry);
      }
    }

    for (const rosterEntry of toProcess) {
      rosterEntry.counterParty = this.__injectCounterParty({
        ...rosterEntry,
        hasCurrentUser: true,
      });
      nonUsers.push(rosterEntry);
    }

    const conversations = [];

    while (nonUsers.length > 0 || users.length > 0) {
      const rosterEntry =
        nonUsers.length === 0
          ? users.shift()
          : users.length === 0
          ? nonUsers.shift()
          : nonUsers[0].origIndex < users[0].origIndex
          ? nonUsers.shift()
          : users.shift();

      const {
        conversationId,
        counterParty,
        lastMessage,
        muted,
        organizationId,
        entityType,
        groupType,
      } = rosterEntry;
      const network =
        entityType === 'group' && groupType && groupType === 'PATIENT_MESSAGING'
          ? Networks.PATIENT
          : Networks.PROVIDER;

      delete counterParty.$placeholder;

      let highestSortNumber;
      if (this.host.config.condensedReplays && lastMessage && lastMessage.sort_number) {
        highestSortNumber = lastMessage.sort_number;
      }

      const conversation = this.ensureConversation(
        counterParty.$entityType,
        counterParty.id,
        organizationId,
        {
          conversationId,
          ensureEntities: {
            counterParty: this.config.ensureFullConversationCounterParty ? 'full' : 'placeholder',
            groupMembers: this.config.ensureFullConversationGroupMembers ? 'full' : 'placeholder',
            organization: 'placeholder',
          },
          highestSortNumber,
          muted,
          network,
          shouldDisplay: true,
        }
      );

      conversation._counterPartyAddedOnServer = true;
      conversations.push(conversation);
    }

    this.host.currentUser.roles.forEach((role) => {
      if (this._roleMessagesToRefresh[role.id]) {
        this._roleMessagesToRefresh[role.id].forEach((messageId) => {
          this.host.models.Message.touch(messageId);
        });
      }
    });

    this._fetched = true;

    return conversations;
  }

  async find(id: string, options = {}) {
    options = _.defaultsDeep(options, {
      ensureEntities: {
        counterParty: 'full',
        escalationExecution: 'full',
        groupMembers: 'placeholder',
        messageCounterParties: 'full',
        organization: 'placeholder',
        patient: 'full',
        touchOnRelatedEntityLoad: false,
        calculateRoleOwnership: false,
      },
    });
    let conversation;

    if (this.config.condensedReplays) {
      conversation = this.getById(id);
      if (!(conversation && conversation.$localOnly)) {
        const rawConversation = await this.host.api.conversations.find(id);

        if (!rawConversation) {
          throw new errors.NotFoundError(this.host.models.Conversation.name, id);
        }

        conversation = this.__injectConversation(rawConversation);
        if (!conversation) throw new errors.NotFoundError(this.host.models.Conversation.name, id);
      }
    } else {
      const { groupId, organizationId, includeMutes } = options;
      const conversationId =
        groupId && organizationId ? this.getConversationKey('group', groupId, organizationId) : id;
      conversation = this.getById(conversationId);

      if (!conversation) {
        await this.findAll({ includeMutes });
        conversation = this.getById(conversationId);
      }
      if (!conversation) {
        throw new errors.NotFoundError(this.host.models.Conversation.name, id);
      }
    }

    if (options.ensureEntities) {
      await this.__ensureEntities(conversation, options.ensureEntities);
      conversation.entitiesEnsured = true;
    }

    return conversation;
  }

  __injectConversation(
    rawConversation,
    {
      hasCurrentUser,
      highestSortNumber,
      origIndex = this.host.models.Conversation.getAll().length,
      shouldDisplay = false,
    } = {}
  ) {
    let counterParty;
    let {
      conversation_id: conversationId,
      entity: entityAttrs,
      first_expiring_unread_message: rawFirstExpiringUnreadMessage,
      in_progress_escalation_count: inProgressEscalationCount,
      last_message: rawLastMessage,
      lowest_sort_number: lowestSortNumber,
      muted,
      organization_id: organizationId,
      unread_message_count: unreadCount,
      unread_priority_message_count: unreadPriorityCount,
    } = rawConversation;

    let conversation = this.getById(conversationId);
    if (conversation) {
      counterParty = conversation.counterParty;
      organizationId = conversation.organizationId;
    } else {
      if (!organizationId) {
        organizationId = rawConversation.organization_key;
      }

      if (!muted && entityAttrs && entityAttrs.muted) {
        muted = entityAttrs.muted;
        delete entityAttrs.muted;
      }

      if (entityAttrs) {
        counterParty = this.__injectCounterParty({
          conversationId,
          entityAttrs,
          entityType: entityAttrs.$entityType,
          hasCurrentUser,
          organizationId,
        });
      }

      if (!counterParty) return null;
      delete counterParty.$placeholder;

      if (!conversation) {
        conversation = this.host.models.Conversation.createInstance({
          id: conversationId,
          counterParty,
          organizationId,
          _origIndex: origIndex,
          shouldDisplay,
        });
      }
    }

    const { id: counterPartyId, $entityType: counterPartyType, groupType } = counterParty;

    if (
      counterPartyType === 'group' &&
      groupType === GroupType.FORUM &&
      !counterParty.hasCurrentUser
    ) {
      rawLastMessage = null;
    }

    if (rawFirstExpiringUnreadMessage) {
      if (rawLastMessage) {
        if (rawLastMessage.message_id === rawFirstExpiringUnreadMessage.message_id) {
          rawFirstExpiringUnreadMessage = rawLastMessage;
        } else {
          rawFirstExpiringUnreadMessage.senderId = rawLastMessage.sender_token;
          rawFirstExpiringUnreadMessage.senderOrganizationId = rawLastMessage.sender_organization;
        }
      }

      conversation.firstExpiringUnreadMessage = this._ensureFirstExpiringUnreadMessage({
        rawFirstExpiringUnreadMessage,
        conversationId,
        counterParty,
      });
    } else if (rawFirstExpiringUnreadMessage === null) {
      conversation.firstExpiringUnreadMessage = null;
    }

    if (rawLastMessage !== undefined) {
      let lastMessage = rawLastMessage
        ? this._ensureLastMessage({
            rawLastMessage,
            conversationId,
            counterParty,
          })
        : null;

      if (lastMessage && lastMessage.isEphemeral) {
        const { lastEphemeralMessage } = conversation;

        if (!lastEphemeralMessage || lastMessage.sortNumber > lastEphemeralMessage.sortNumber) {
          conversation.lastEphemeralMessage = lastMessage;
        }
      }

      // NOTE: Server does not return ephemeral messages for rawConversation.last_message
      // so we need to set it in case it is next message to show in the preview
      if (conversation.lastEphemeralMessage) {
        const { lastEphemeralMessage } = conversation;

        if (!lastMessage || lastEphemeralMessage.sortNumber > lastMessage.sortNumber) {
          lastMessage = lastEphemeralMessage;
        }
      }

      conversation.lastMessage = lastMessage;

      if (highestSortNumber === undefined && rawLastMessage && rawLastMessage.sort_number) {
        highestSortNumber = rawLastMessage.sort_number;
      }
    }

    conversation._counterPartyAddedOnServer = true;
    if (entityAttrs && entityAttrs.metadata) {
      conversation.metadata = entityAttrs.metadata;
    }

    if (highestSortNumber !== undefined && highestSortNumber > conversation.highestSortNumber) {
      conversation.highestSortNumber = highestSortNumber;
    }
    if (lowestSortNumber !== undefined && lowestSortNumber !== 0)
      conversation.lowestSortNumber = lowestSortNumber;
    if (inProgressEscalationCount !== undefined)
      conversation.inProgressEscalationCount = inProgressEscalationCount;
    if (muted !== undefined) conversation.muted = muted;

    if (
      !(
        conversation._markingAsRead &&
        (highestSortNumber === undefined ||
          highestSortNumber <= conversation._markingAsReadSortNumber)
      )
    ) {
      if (unreadCount !== undefined) conversation.unreadCount = unreadCount;
      if (unreadPriorityCount !== undefined) conversation.unreadPriorityCount = unreadPriorityCount;
    }
    if (shouldDisplay) {
      conversation.shouldDisplay = shouldDisplay;
    }

    this.host.models.Conversation.inject(conversation);

    const conversationHandle = this.getConversationKey(
      counterPartyType,
      counterPartyId,
      organizationId
    );
    this._convByConvHandle[conversationHandle] = conversation;

    return conversation;
  }

  async refetch() {
    this._fetched = false;
    return this.findAll();
  }

  _ensureLastMessage({ rawLastMessage, conversationId, counterParty }) {
    const { message_id: messageId, ...messageAttrs } = rawLastMessage;
    if (!messageId) return null;

    let message = this.host.messages.getById(messageId);

    if (message) {
      message.shouldEnsureRecipientStatus = true;
      message.senderStatus = MessageSenderStatus.SENT;
      this.host.models.Message.inject(message);
    } else {
      messageAttrs.senderStatus = MessageSenderStatus.SENT;
      messageAttrs.id = messageId;
      messageAttrs.shouldEnsureRecipientStatus = true;
      messageAttrs.conversationId = conversationId;
      messageAttrs.counterParty = counterParty;

      message = this.host.models.Message.injectPlaceholder(messageAttrs);
    }

    return message;
  }

  _ensureFirstExpiringUnreadMessage({
    rawFirstExpiringUnreadMessage,
    conversationId,
    counterParty,
  }) {
    const {
      expire_in: expireIn,
      message_id: messageId,
      ...messageAttrs
    } = rawFirstExpiringUnreadMessage;
    if (messageId) {
      let message = this.host.messages.getById(messageId);

      if (message) {
        message.shouldEnsureRecipientStatus = true;
        message.expireIn = expireIn;

        this.host.models.Message.inject(message);
      } else {
        messageAttrs.id = messageId;
        messageAttrs.shouldEnsureRecipientStatus = true;
        messageAttrs.expireIn = expireIn;
        messageAttrs.conversationId = conversationId;
        messageAttrs.counterParty = counterParty;

        message = this.host.models.Message.injectPlaceholder(messageAttrs);
      }

      return message;
    }

    return null;
  }

  async markAsDelivered(conversationId: string | Object) {
    const conversation = this._resolveEntity(conversationId, 'conversation');
    if (!conversation)
      throw new errors.NotFoundError(this.host.models.Conversation.name, conversationId);
    conversationId = this._resolveModelId(conversationId);

    if (this.config.condensedReplays) {
      const { highestSortNumber, lowestSortNumber } = conversation;
      try {
        await this.host.api.conversations.updateConversationStatus(
          conversationId,
          DELIVERED_STATUS,
          {
            highestSortNumber,
            lowestSortNumber,
          }
        );

        conversation.lowestSortNumber = null;
      } catch (err) {
        this.logger.warn(`failed to mark conversation ${conversationId} as delivered`);
      }
      this.host.models.Conversation.inject(conversation);
    } else {
      return this.host.messages.markAsDelivered(conversation.messages);
    }
  }

  async markAsRead(conversationId: string | Object, options = {}) {
    const conversation = this._resolveEntity(conversationId, 'conversation');
    if (!conversation)
      throw new errors.NotFoundError(this.host.models.Conversation.name, conversationId);
    conversationId = this._resolveModelId(conversationId);

    if (this.config.condensedReplays) {
      const { highestSortNumber, unreadCount } = conversation;
      if (!conversation._markingAsRead) {
        if (unreadCount === 0) return;

        conversation._markingAsRead = true;
        conversation._markingAsReadSortNumber = highestSortNumber;
        conversation._markingAsReadExpiration = new Date().getTime() + 30 * 1000;

        this.__reloadConversation(conversation);
      }

      if (options.localOnly) return;

      try {
        await this.host.api.conversations.updateConversationStatus(conversationId, READ_STATUS, {
          highestSortNumber,
        });
      } catch (err) {
        this.logger.warn(`failed to mark conversation ${conversationId} as read`);
      }
    } else {
      return this.host.messages.markAsRead(conversation.markableAsReadMessages, options);
    }
  }

  // add/remove conversations to server roster
  @reusePromise()
  async add(counterPartyType: string, counterPartyId: string, organizationId: string) {
    const conversation = this.ensureConversation(counterPartyType, counterPartyId, organizationId, {
      ensureEntities: {
        counterParty: 'full',
      },
      shouldDisplay: true,
    });

    try {
      await this.host.api.roster.add(counterPartyType, counterPartyId, organizationId);
    } catch (err) {
      this.logger.warn(`can't add ${counterPartyId} ${counterPartyType}`, err);
    }

    conversation._counterPartyAddedOnServer = true;
  }

  @reusePromise()
  async remove(conversationId: string | Object) {
    conversationId = this._resolveModelId(conversationId);
    const conversation = this._resolveEntity(conversationId, 'conversation');
    if (!conversation) {
      throw new errors.NotFoundError(this.host.models.Conversation.name, conversationId);
    }
    const { counterPartyId, counterPartyType, organizationId } = conversation;

    try {
      await this.host.api.roster.remove(counterPartyType, counterPartyId, organizationId);
    } catch (e) {
      this.logger.warn(`can't remove conversation ${conversationId}`);
    }
  }

  ensureConversation(
    counterPartyType: string,
    counterPartyId: string,
    organizationId: string,
    options = {}
  ) {
    options = _.defaultsDeep(options, {
      ensureEntities: {
        counterParty: false,
        organization: false,
        messageCounterParties: false,
        groupMembers: false,
        touchOnRelatedEntityLoad: true,
      },
    });

    let { conversationId } = options;
    const { shouldDisplay = false } = options;

    const conversationHandle = this.getConversationKey(
      counterPartyType,
      counterPartyId,
      organizationId
    );
    if (this.config.condensedReplays && !conversationId) {
      const conversation = this._convByConvHandle[conversationHandle];
      if (conversation) conversationId = conversation.id;
    }
    if (!conversationId) {
      conversationId = conversationHandle;
    }

    let conversation = this.getById(conversationId);
    if (conversation) {
      // fire and forget __ensureEntities. will touch conversation when any related entity is loaded
      this.__ensureEntities(conversation, options.ensureEntities);
      let changed = false;

      if (this.config.condensedReplays && options.highestSortNumber !== undefined) {
        conversation.highestSortNumber = options.highestSortNumber;
        changed = true;
      }

      if (this.config.condensedReplays && options.muted !== undefined) {
        conversation.muted = options.muted;
        changed = true;
      }

      if (shouldDisplay) {
        conversation.shouldDisplay = shouldDisplay;
        changed = true;
      }

      const metadata = this.host.metadata.get(counterPartyId, organizationId);
      if (!conversation.metadata && metadata) {
        const { data } = metadata;
        conversation.metadata = data;
        changed = true;
      }

      this._convByConvHandle[conversationHandle] = conversation;

      if (changed) {
        this.host.models.Conversation.inject(conversation);
      }

      return conversation;
    }

    const attrs = {
      id: conversationId,
      counterPartyType,
      counterPartyId,
      organizationId,
      _origIndex: this.host.models.Conversation.getAll().length,
    };

    if (options.highestSortNumber) attrs.highestSortNumber = options.highestSortNumber;
    if (options.muted) attrs.muted = options.muted;
    if (options.network) attrs.network = options.network;
    if (options.featureService) {
      const { featureService } = options;

      if (featureService === FeatureService.VIRTUAL_WAITING_ROOM) {
        attrs.featureService = featureService;
      }

      if (
        featureService === FeatureService.ALERTS ||
        featureService === FeatureService.GROUP_ALERTS ||
        featureService === FeatureService.ROLE_ALERTS
      ) {
        attrs.featureService = featureService;
      }
    }
    if (options.shouldDisplay) {
      attrs.shouldDisplay = shouldDisplay;
    }

    const group = this.host.models.Group.get(counterPartyId);
    if (
      (group && group.groupType === GroupType.PATIENT_MESSAGING) ||
      attrs.featureService === FeatureService.VIRTUAL_WAITING_ROOM
    ) {
      attrs.network = Networks.PATIENT;
    }

    const metadata = this.host.metadata.get(counterPartyId, organizationId);
    if (metadata) {
      const { data } = metadata;
      attrs.metadata = data;
    }

    conversation = this.host.models.Conversation.createInstance(attrs);

    // fire and forget __ensureEntities. will touch conversation when any related entity is loaded
    this.__ensureEntities(conversation, options.ensureEntities);
    this.host.models.Conversation.inject(conversation);

    this._convByConvHandle[conversationHandle] = conversation;

    return conversation;
  }

  __ejectConversation(conversationId: string) {
    const conversation = this.getById(conversationId);

    if (conversation) {
      const { counterPartyType, counterPartyId, organizationId } = conversation;
      const conversationHandle = this.getConversationKey(
        counterPartyType,
        counterPartyId,
        organizationId
      );
      delete this._convByConvHandle[conversationHandle];
      this.host.models.Conversation.eject(conversation);
      this.__queuedConversationsToReload = this.__queuedConversationsToReload.filter(
        ({ id }) => id !== conversation.id
      );

      if (!this.host.isInitialBangReplayDone && conversation.organizationId) {
        this.host.models.Organization.touch(conversation.organizationId);
      }
    }

    return conversation;
  }

  reactToMessageEvent({ data }) {
    if (this.config.condensedReplays) {
      this.reactToMessageEventCR({ data });
      return;
    }

    const { message, conversation } = this._reactToMessageEvent({ data, shouldDisplay: true });
    this.host.models.Message.inject(message);
    this.emit('message', message);

    if (conversation) {
      conversation.emit('message', message);
    }

    const isNotDelivered =
      MESSAGE_STATUS_ORDER[message.markedRecipientStatus] <
      MESSAGE_STATUS_ORDER[MessageRecipientStatus.DELIVERED];
    const options = {};

    if (
      message.markedRecipientStatus === 'DELIVERED' &&
      message.counterPartyType === 'group' &&
      message.featureService === 'role' &&
      !this._fetched
    ) {
      if (!this._roleMessagesToRefresh[message.senderRole?.id]) {
        this._roleMessagesToRefresh[message.senderRole?.id] = new Set();
      }

      this._roleMessagesToRefresh[message.senderRole?.id].add(message.id);
    }

    if (isNotDelivered) {
      this.host.messages.markAsReceived(message, options);
    }
  }

  reactToMessageEventCR({ data }) {
    const options = {};

    const { message, conversation } = this._reactToMessageEventCR({ data, shouldDisplay: true });
    this.emit('message', message);

    if (conversation) {
      conversation.emit('message', message);
    }

    options.queue = true;
    this.host.messages.markAsReceived(message, options);
  }

  _reactToMessageEvent({ data, conversationId = null, shouldDisplay = false }) {
    data = jsonCloneDeep(data);

    const currentUserId = this.host.currentUserId;

    let recipientStatuses = data['recipient_status'];
    if (!recipientStatuses) recipientStatuses = [];
    const p2pMessageStatus = {
      status: data['status'],
      timestamp: data['created_time'],
    };
    if (data['team_request']) {
      this.host.teams.__injectTeamRequest(data['team_request']);
    }

    if (this.config.allowRolesPerformance && data['is_group'] && !data['group_token']) {
      data['group_token'] = data['recipient_token'];
      delete data['recipient_token'];
    }

    const attrs = this.host.models.Message.parseAttrs(data);

    attrs.messageType = MessageType.USER_SENT;

    let hasCounterParty = true;
    if (attrs.groupId) {
      attrs.counterPartyType = 'group';
      attrs.counterPartyId = attrs.groupId;
    } else if (attrs.recipientId) {
      if (this.host.distributionLists.getById(attrs.recipientId)) {
        attrs.counterPartyType = 'distributionList';
      } else if (this.host.users.getById(attrs.recipientId)) {
        attrs.counterPartyType = 'user';
      } else {
        hasCounterParty = false;
        const distributionListName = this.host.messages._looksLikeDistributionListMessage(attrs);
        if (distributionListName) {
          attrs.counterPartyType = 'distributionList';
        } else {
          attrs.counterPartyType = 'user';
        }
      }

      attrs.counterPartyId =
        attrs.counterPartyType === 'distributionList' || attrs.senderId === currentUserId
          ? attrs.recipientId
          : attrs.senderId;
    }

    const isOutgoing = this.host.messages._isMessageOutgoing(attrs);

    if (attrs.counterPartyType !== 'distributionList') {
      p2pMessageStatus['account_id'] = isOutgoing ? attrs.recipientId : this.host.currentUserId;
      if (p2pMessageStatus['account_id']) recipientStatuses.push(p2pMessageStatus);
    }
    attrs.senderStatus = MessageSenderStatus.SENT;

    let conversation;
    if (hasCounterParty) {
      const { data: metadata } = data;

      if (metadata && metadata[0]) {
        const parsedMetadata = this.host.metadata.__parseMessageMetadata(metadata[0]);
        if (parsedMetadata?.payload?.feature_service) {
          if (attrs.featureService !== FeatureService.PATIENT_MESSAGING) {
            this.__injectCounterParty({
              entityAttrs: {
                id: attrs.counterPartyId,
                metadata: parsedMetadata.payload,
              },
              entityType: attrs.counterPartyType,
              organizationId: attrs.recipientOrganizationId,
            });
          }
        }
      }

      if (!data['feature_service']) {
        if (data.sub_type === MessageSubType.toServer(MessageSubType.ALERTS)) {
          data['feature_service'] = FeatureService.ALERTS;
        }
      }

      conversation = this.ensureConversation(
        attrs.counterPartyType,
        attrs.counterPartyId,
        attrs.recipientOrganizationId,
        {
          conversationId,
          ensureEntities: {
            counterParty: 'placeholder',
            groupMembers: 'placeholder',
            organization: 'placeholder',
          },
          featureService: data['feature_service'],
          shouldDisplay,
        }
      );

      this._ensureConversationAndUserLoaded(
        attrs.counterPartyId,
        conversation.id,
        conversation.organizationId
      );
    }

    attrs.conversationId = conversation ? conversation.id : null;
    if (!attrs.conversationId && attrs.counterPartyId) {
      conversation = this.ensureConversation(
        attrs.counterPartyType,
        attrs.counterPartyId,
        attrs.recipientOrganizationId,
        {
          shouldDisplay,
        }
      );
      attrs.conversationId = conversation.id;
    }

    let message = this.host.models.Message.get(attrs.id);

    if (message) {
      if (message.featureService === FeatureService.GROUP_ALERTS && message.$placeholder) {
        delete message.$placeholder;
        this.host.models.Message.inject(attrs);

        if (attrs.recipientOrganizationId) {
          this.host.models.Organization.touch(attrs.recipientOrganizationId);
        }
      } else {
        const {
          body,
          escalationExecutionId,
          isForwardedFromPatientNetwork,
          originalMetadata,
          originalSender,
          patientDetails,
          priority,
          sortNumber,
          sortSeries,
        } = attrs;

        message.body = body;
        message.escalationExecutionId = escalationExecutionId || null;
        message.isForwardedFromPatientNetwork = isForwardedFromPatientNetwork;
        message.originalMetadata = originalMetadata;
        message.originalSender = originalSender;
        message.patientDetails = patientDetails;
        message.priority = priority;
        message.shouldEscalate = false;
        message.sortNumber = sortNumber;
        message.sortSeries = sortSeries;
      }
    } else {
      message = this.host.models.Message.createInstance(attrs, false);
    }

    const otherUserIdInMessage =
      message.senderId === currentUserId ? message.recipientId : message.senderId;
    if (otherUserIdInMessage && message.counterPartyType !== 'distributionList') {
      this.__ensureMessageCounterParty(conversation, otherUserIdInMessage, {
        messageCounterParties: this.host.currentlyServingOfflineMessages ? 'placeholder' : 'full',
        touchOnRelatedEntityLoad: true,
      });
    }

    for (const statusAttrs of recipientStatuses) {
      if (!this.config.condensedReplays) {
        this.host.messages.__injectMessageStatus(message, statusAttrs);
      }
    }

    if (
      // server does not return a status for every group recipient, so we have to fetch them later
      attrs.counterPartyType === 'group' ||
      this.config.condensedReplays
    ) {
      message.shouldEnsureRecipientStatus = true;
    }

    if (!hasCounterParty || conversation?.counterParty?.$placeholder) {
      const { conversationId, counterPartyId, recipientOrganizationId: organizationId } = attrs;
      this._ensureConversationAndUserLoaded(counterPartyId, conversationId, organizationId);
    }

    message.inTimeline = !!(conversation && conversation.isLive);

    return { message, conversation };
  }

  async _ensureConversationAndUserLoaded(
    counterPartyId: string,
    conversationId: string,
    organizationId: string
  ) {
    if (!counterPartyId || !conversationId || !organizationId) return null;

    const user = this.host.models.User.get(counterPartyId);
    if (user?.$placeholder) {
      const userId = counterPartyId;

      this._addConversationPendingCounterPartyReload(userId, organizationId, conversationId);
      await this.host.users.find(userId, { ignoreNotFound: true, organizationId });
    }
  }

  _addConversationPendingCounterPartyReload(
    counterPartyId: string,
    organizationId: string,
    conversationId: string
  ) {
    if (!counterPartyId || !organizationId || !conversationId) return null;

    const cpr = this._conversationsPendingCounterPartyReload;

    if (!cpr[counterPartyId]) {
      cpr[counterPartyId] = {};
    }

    if (!cpr[counterPartyId][organizationId]) {
      cpr[counterPartyId][organizationId] = [];
    }

    const conversationIds = cpr[counterPartyId][organizationId];
    if (!conversationIds.includes(conversationId)) {
      cpr[counterPartyId][organizationId].push(conversationId);
    }
  }

  _reloadConversationsPendingCounterPartyReload(counterPartyId: string, organizationId: string) {
    if (!counterPartyId || !organizationId) return null;

    const cpr = this.host.conversations._conversationsPendingCounterPartyReload[counterPartyId];

    if (cpr) {
      const conversationIds = cpr[organizationId];

      if (conversationIds) {
        for (const conversationId of conversationIds) {
          this.host.models.Conversation.inject({ id: conversationId, shouldDisplay: true });
        }

        delete cpr[organizationId];
      }
    }
  }

  _reactToMessageEventCR({ data = {}, injectConversation = true, shouldDisplay = false } = {}) {
    data = jsonCloneDeep(data);
    const currentUserId = this.host.currentUserId;
    const {
      first_expiring_unread_message,
      last_message,
      type: recipientType,
      unread_message_count,
      unread_priority_message_count,
      xmlns,
      ...messageData
    } = data;
    let highestSortNumber;
    if (messageData['team_request']) {
      this.host.teams.__injectTeamRequest(messageData['team_request']);
    }

    if (last_message) {
      last_message.conversation_id = messageData.conversation_id;
      highestSortNumber = last_message.sort_number;

      if (last_message['is_group'] && !last_message['group_token']) {
        last_message['group_token'] = last_message['recipient_token'];
      }

      if (last_message.message_id === messageData.client_id) {
        messageData.attachmentName = last_message.attachment_name;
      }
    }

    if (messageData['is_group'] && !messageData['group_token']) {
      messageData['group_token'] = messageData['recipient_token'];
    }

    const attrs = this.host.models.Message.parseAttrs(messageData);

    attrs.messageType = MessageType.USER_SENT;

    if (attrs.groupId) {
      attrs.counterPartyType = 'group';
      attrs.counterPartyId = attrs.groupId;
    } else if (attrs.recipientId) {
      if (this.host.distributionLists.getById(attrs.recipientId)) {
        attrs.counterPartyType = 'distributionList';
      } else if (this.host.users.getById(attrs.recipientId)) {
        attrs.counterPartyType = 'user';
      } else {
        const distributionListName = this.host.messages._looksLikeDistributionListMessage(attrs);
        if (distributionListName) {
          attrs.counterPartyType = 'distributionList';
        } else {
          attrs.counterPartyType = 'user';
        }
      }

      attrs.counterPartyId =
        attrs.counterPartyType === 'distributionList' || attrs.senderId === currentUserId
          ? attrs.recipientId
          : attrs.senderId;
    }

    attrs.senderStatus = MessageSenderStatus.SENT;

    let message = this.host.models.Message.get(attrs.id);

    if (message) {
      this.host.models.Message.inject({ shouldEscalate: false, ...attrs });
    } else {
      message = this.host.models.Message.inject(attrs);
    }

    let conversation;
    if (injectConversation) {
      const rawConversation = {
        conversation_id: message.conversationId,
        entity: this._resolveEntity(attrs.counterPartyId, attrs.counterPartyType),
        first_expiring_unread_message,
        last_message,
        organization_id: message.__organizationId,
        unread_message_count,
        unread_priority_message_count,
      };

      if (last_message && last_message['sort_number'] < message.sortNumber) {
        highestSortNumber = message.sortNumber;
        rawConversation.last_message = { message_id: message.id };

        conversation = this.__injectConversation(rawConversation, {
          highestSortNumber,
          shouldDisplay,
        });

        if (conversation) {
          this._ensureLastMessage({
            rawLastMessage: last_message,
            conversationId: conversation.id,
            counterParty: conversation.counterParty,
          });
        }
      } else {
        conversation = this.__injectConversation(rawConversation, {
          highestSortNumber,
          shouldDisplay,
        });
      }

      // Workaround for TS-6685: During replay, sometimes the last message doesn't get replayed
      if (last_message && last_message['message_id'] && conversation && conversation.isLive) {
        const lastMessage = this.host.models.Message.get(last_message['message_id']);
        if (lastMessage && !lastMessage.inTimeline) {
          lastMessage.inTimeline = true;
          this.host.models.Message.inject(lastMessage);
        }
      }
    } else {
      conversation = this.host.conversations.getById(messageData.conversation_id);
    }

    const otherUserIdInMessage =
      message.senderId === currentUserId ? message.recipientId : message.senderId;
    const ensureOnlyForumGroups =
      message.counterPartyType !== 'group' ||
      (message.counterParty && message.counterParty.groupType === 'FORUM');

    if (
      otherUserIdInMessage &&
      message.counterPartyType !== 'distributionList' &&
      ensureOnlyForumGroups
    ) {
      this.__ensureMessageCounterParty(conversation, otherUserIdInMessage, {
        messageCounterParties: this.host.currentlyServingOfflineMessages ? 'placeholder' : 'full',
        touchOnRelatedEntityLoad: true,
      });
    }

    message.inTimeline = !!message.isEphemeral || !!(conversation && conversation.isLive);
    message.shouldEnsureRecipientStatus = true;
    this.host.models.Message.inject(message);

    return { conversation, message };
  }

  reactToFriendsEventCR({ data, eventId }) {
    data = jsonCloneDeep(data);
    const { action, xmlns, ...rawConversation } = data;
    const organizationId = rawConversation.organization_id || rawConversation.organization_key;
    const { entity } = rawConversation;
    const entityId = entity.id || entity.token || rawConversation.token;
    let { metadata: tempMetadata } = entity;
    if (isEmpty(tempMetadata)) {
      const metadataInStore = this.host.metadata.get(entityId, organizationId);
      if (metadataInStore && metadataInStore.data) tempMetadata = metadataInStore.data;
    }
    const entityAttrs = entity.metadata ? { ...entity, metadata: tempMetadata } : entity;
    const { metadata } = entityAttrs;
    const isReplayedOfflineDeleteEvent = entityAttrs === 'group' && action === 'del' && !metadata;
    if (isReplayedOfflineDeleteEvent) {
      const { metadata: rootMetadata } = data;
      const group = this.host.models.Group.get(rawConversation.token);

      if (
        rootMetadata &&
        rootMetadata['feature_service'] === 'role' &&
        rootMetadata['meta_type'] === 'role_assignment'
      ) {
        this._rolesFriendsEventPromise = this.host.roles.reactToFriendsEventCR({
          action,
          attrs: { id: rawConversation.token, metadata: rootMetadata, organizationId },
          id: eventId,
        });
        return;
      }

      const conversation = this.getById(group.conversation.id);

      if (!group.conversation) {
        this.host.report.send({
          level: 'debug',
          message: `reactToFriendsEventCR - group.conversation is undefined or null for group.id ${group?.id}`,
        });
      }

      this.__removeFriend({
        conversation,
        entityType: entityAttrs,
        id: rawConversation.token,
        organizationId,
      });

      if (conversation) this.__clearOptimisticStatus([conversation]);

      this.emit('friends', { action, ...conversation.entity, organizationId });
      return;
    }
    if (!entityAttrs.type) return;
    const entityType = this.host.modelNameByEntityType(entityAttrs.type);

    // TODO: Remove when we get a bool typed 'is_patient_contact' from server instead of a mix of bool and string
    if (
      metadata &&
      metadata['feature_service'] === 'patient_messaging' &&
      typeof metadata['is_patient_contact'] === 'string'
    ) {
      metadata['is_patient_contact'] = metadata['is_patient_contact'] === 'true';
    }

    entityAttrs.organizationId = organizationId;

    if (!entityAttrs.event_status) {
      entityAttrs.event_status = data.event_status;
    }

    if (
      entityType === 'group' &&
      metadata &&
      metadata['feature_service'] === 'role' &&
      metadata['meta_type'] === 'role_assignment'
    ) {
      this._rolesFriendsEventPromise = this.host.roles.reactToFriendsEventCR({
        action,
        attrs: entityAttrs,
        id: eventId,
      });

      return;
    }

    const {
      conversation_id: conversationId,
      last_message: rawLastMessage,
      lowest_sort_number: lowestSortNumber,
    } = rawConversation;
    let conversation = this.getById(conversationId);

    if (action === 'add' || action === 'update') {
      if (conversation && action === 'update') {
        this.clearMessageStatuses(conversation);
      }

      let highestSortNumber =
        rawLastMessage && rawLastMessage.sort_number ? rawLastMessage.sort_number : undefined;
      let hasOnDutyRole = false;
      const entityIsTeamOrForum =
        _.get(data, 'entity.is_public') || _.get(data, 'entity.metadata.team');
      if ((highestSortNumber === undefined || entityIsTeamOrForum) && action === 'add') {
        if (entityType === 'group') {
          if (entityAttrs.proxied_members && entityAttrs.proxied_members[this.host.currentUserId]) {
            const product = this.host.models.Product.getAll()[0];

            for (const role of entityAttrs.proxied_members[this.host.currentUserId]) {
              if (product.rolesOptingIn.includes(role.token)) {
                hasOnDutyRole = true;
                break;
              }
            }
          }
          if (hasOnDutyRole) {
            highestSortNumber = 1;
          } else {
            highestSortNumber = highestSortNumber > 0 ? highestSortNumber : 0;
          }
        } else {
          highestSortNumber = conversation ? conversation.highestSortNumber : 1;
        }
      }

      const originalLastMessage = conversation && conversation.lastMessage;

      if (action === 'add') {
        const groupType =
          entityType === 'group' && this.host.groups.__extractGroupTypeFromAttrs(entityAttrs);
        if (groupType === GroupType.GROUP || groupType === GroupType.FORUM) {
          entityAttrs.members = null;
        }

        this.__injectCounterParty({
          entityAttrs,
          entityType,
          groupType,
          hasCurrentUser: true,
          organizationId,
        });
        conversation = this.__injectConversation(rawConversation, {
          highestSortNumber,
          shouldDisplay: true,
        });
        if (originalLastMessage && !rawLastMessage) {
          this.find(conversationId);
        }
        this.clearTimeline(conversation);
      } else if (action === 'update') {
        conversation = this.__injectConversation(rawConversation, { highestSortNumber });
        if (originalLastMessage && !rawLastMessage) {
          this.find(conversationId);
        }
      }

      if (lowestSortNumber) {
        this.markAsDelivered(conversation);
      }
    } else if (action === 'del') {
      this.__removeFriend({ attrs: entityAttrs, entityType, id: entityId, organizationId });
    } else {
      this.logger.error('friends action not implemented', action, data);
    }

    if (conversation) this.__clearOptimisticStatus([conversation]);
    this.emit('friends', { action, ...entityAttrs });
  }

  reactToFriendsEvent({ data, id: eventId }) {
    if (this.config.condensedReplays) {
      this.reactToFriendsEventCR({ data, eventId });
      return;
    }

    data = jsonCloneDeep(data);
    const { action, organization_id: organizationId, ...entityAttrs } = data['friend'];
    const { token: id } = entityAttrs;
    let { metadata } = entityAttrs;
    if (isEmpty(metadata)) {
      const metadataInStore = this.host.metadata.get(id, organizationId);
      if (metadataInStore && metadataInStore.data) metadata = metadataInStore.data;
    }
    const entityType = this.host.modelNameByTypeNS(entityAttrs['xmlns']);
    if (!entityType) return;

    if (
      entityType === 'group' &&
      metadata &&
      metadata['feature_service'] === 'role' &&
      metadata['meta_type'] === 'role_assignment'
    ) {
      this._rolesFriendsEventPromise = this.host.roles.reactToFriendsEvent({
        data: { ...data, friend: { ...(data?.friend || {}), metadata } },
        id: eventId,
      });
      return;
    }

    if (action === 'add') {
      const groupType =
        entityType === 'group' && this.host.groups.__extractGroupTypeFromAttrs(entityAttrs);
      const currentGroupModels = this.host.models.Group.get(id);

      if (
        (groupType === GroupType.GROUP || groupType === GroupType.FORUM) &&
        !currentGroupModels?.localGroupCreated
      ) {
        entityAttrs.members = null;
      }

      delete currentGroupModels?.localGroupCreated;

      const network = groupType === GroupType.PATIENT_MESSAGING ? 'PATIENT' : 'PROVIDER';

      const counterParty = this.__injectCounterParty({
        entityAttrs,
        entityType,
        groupType,
        hasCurrentUser: true,
        organizationId,
      });

      if (counterParty?.$placeholder) {
        delete counterParty.$placeholder;
      }

      const highestSortNumber = entityType === 'group' ? 0 : 1;

      this.ensureConversation(entityType, id, organizationId, {
        highestSortNumber,
        network,
        shouldDisplay: true,
        featureService: metadata?.feature_service,
      });
    } else if (action === 'del') {
      this.__removeFriend({ attrs: entityAttrs, entityType, id, organizationId });
    } else {
      this.logger.error('friends action not implemented', action, data);
    }

    this.emit('friends', { action, ...entityAttrs });
  }

  __injectCounterParty = ({
    conversationId,
    entityAttrs,
    entityType,
    groupType,
    hasCurrentUser = false,
    isPlaceholder = false,
    organizationId,
  }) => {
    let { id } = entityAttrs;
    const { metadata } = entityAttrs;
    if (!id) id = entityAttrs.token;
    if (!entityType && entityAttrs.type) {
      entityType = this.host.modelNameByEntityType(entityAttrs.type);
    }
    if (conversationId) entityAttrs.conversationId = conversationId;
    if (organizationId) entityAttrs.organizationId = organizationId;
    if (entityType === 'group' && !groupType) {
      groupType = this.host.groups.__extractGroupTypeFromAttrs(entityAttrs);
    }

    if (metadata && id && organizationId) {
      this.host.metadata.__injectMetadata(id, organizationId, metadata);
    }

    if (entityType === 'group') {
      if (metadata) {
        if (metadata['created_by']) {
          const { role = null, user = null } = this.__injectCreatedByEntity(
            metadata,
            organizationId,
            entityAttrs
          );
          if (role || user) {
            entityAttrs.createdByRole = role;
            entityAttrs.createdByUser = user;
          }
        } else if (entityAttrs['created_by']) {
          const user = this.host.models.User.injectPlaceholder({ id: entityAttrs['created_by'] });
          entityAttrs.createdByUser = user;
        }
        if (metadata['target_id']) {
          const { role = null, user = null } = this.__injectTargetEntity(metadata, organizationId);
          if (role || user) {
            entityAttrs.targetRole = role;
            entityAttrs.targetUser = user;
          }
        }
        if (metadata['meta_type'] === 'role_assignment') {
          const roleName = entityAttrs.display_name || entityAttrs.name;
          this.host.roles.__injectRoleAssignmentEntity(roleName, metadata, organizationId);
        }
      }

      if (groupType === GroupType.ROLE_P2P && !isPlaceholder) {
        this.host.groups._setP2PEntities(entityAttrs);
      }

      if (groupType === GroupType.FORUM && hasCurrentUser) {
        entityAttrs.hasCurrentUser = true;
      }
    } else if (entityType === 'user') {
      const autoForwardReceiverId = entityAttrs['dnd_auto_forward_receiver'];
      const user = this.host.models.User.get(entityAttrs.token);

      const currentUserDndAutoForwardEntities =
        user?.profileByOrganizationId[organizationId]?.dndAutoForwardEntities;
      if (autoForwardReceiverId && autoForwardReceiverId !== 'undefined') {
        this.host.models.User.injectPlaceholder({ id: autoForwardReceiverId });
      }

      if (currentUserDndAutoForwardEntities) {
        entityAttrs.dndAutoForwardEntities = currentUserDndAutoForwardEntities;
      }
    } else if (entityType === 'distributionList') {
      if (entityAttrs['created_by']) {
        this.host.injectPlaceholderModel(
          {
            displayName: entityAttrs['created_by_display_name'],
            id: entityAttrs['created_by'],
          },
          'user'
        );
      }

      if (entityAttrs['updated_by']) {
        this.host.injectPlaceholderModel(
          {
            displayName: entityAttrs['updated_by_display_name'],
            id: entityAttrs['updated_by'],
          },
          'user'
        );
      }
    } else if (entityType === 'messageTemplate') {
      if (entityAttrs['created_by']) {
        this.host.injectPlaceholderModel(
          {
            displayName: entityAttrs['created_by_display_name'],
            id: entityAttrs['created_by'],
          },
          'user'
        );
      }

      if (entityAttrs['updated_by']) {
        this.host.injectPlaceholderModel(
          {
            displayName: entityAttrs['updated_by_display_name'],
            id: entityAttrs['updated_by'],
          },
          'user'
        );
      }
    } else if (entityType === 'scheduledMessage') {
      if (entityAttrs['event_id']) {
        entityAttrs['scheduled_message_id'] = entityAttrs.token;
        entityAttrs['token'] = entityAttrs['event_id'];
        id = entityAttrs['event_id'];
      }

      if (entityAttrs.recipient) {
        if (entityAttrs['recipient_type'] === 'account') {
          const patientData = {};
          const patientDataKeys = [
            'feature_service',
            'is_patient_contact',
            'patient_display_name',
            'patient_dob',
            'patient_gender',
            'patient_id',
            'patient_mrn',
            'phone_number',
            'relation_name',
          ];
          for (const key of patientDataKeys) {
            patientData[key] = entityAttrs[key];
          }

          if (!patientData['patient_id'] && !patientData['is_patient_contact']) {
            patientData['patient_id'] = entityAttrs.recipient;
          }

          this.host.metadata.__injectMetadata(entityAttrs.recipient, organizationId, patientData);
          this.host.injectPlaceholderModel(
            {
              ...patientData,
              displayName: entityAttrs['recipient_display_name'],
              id: entityAttrs.recipient,
            },
            'user'
          );
        } else if (
          entityAttrs['recipient_type'] === 'distribution_list' ||
          entityAttrs['recipient_type'] === 'list'
        ) {
          this.host.injectPlaceholderModel(
            {
              id: entityAttrs.recipient,
              name: entityAttrs['recipient_display_name'],
              totalMembers: entityAttrs['total_members'],
            },
            'distributionList'
          );
        }
      }

      if (entityAttrs['sender_token']) {
        this.host.injectPlaceholderModel(
          {
            displayName: entityAttrs['sender_display_name'],
            id: entityAttrs['sender_token'],
          },
          'user'
        );
      }

      if (entityAttrs['template_id']) {
        this.host.injectPlaceholderModel(
          {
            title: entityAttrs['template_label'],
            id: entityAttrs['template_id'],
          },
          'messageTemplate'
        );
      }

      if (entityAttrs['updated_by']) {
        this.host.injectPlaceholderModel(
          {
            displayName: entityAttrs['updated_by_display_name'],
            id: entityAttrs['updated_by'],
          },
          'user'
        );
      }
    }

    if (id) {
      if (isPlaceholder) {
        return this.host.injectPlaceholderModel(entityAttrs, entityType);
      } else {
        const entity = this.host.injectModel(entityAttrs, entityType);
        delete entity.$placeholder;

        return entity;
      }
    } else {
      return null;
    }
  };

  __injectCreatedByEntity = (metadata, organizationId, groupData) => {
    const createdBy = {};

    if (metadata['created_by_tag_id'] !== undefined) {
      const owners = [];
      const replaceExisting = !!groupData;

      if (groupData && groupData.proxied_members) {
        Object.keys(groupData.proxied_members).forEach((ownerId) => {
          groupData.proxied_members[ownerId].forEach((proxy) => {
            if (proxy.token === metadata['created_by']) {
              owners.push(ownerId);
            }
          });
        });
      }

      createdBy.role = this.host.roles.__injectRoleFromMetadata({
        metadata: {
          token: metadata['created_by'],
          display_name: metadata['created_by_display_name'],
          owners: owners.join(','),
          tag_color: metadata['created_by_tag_color'],
          tag_id: metadata['created_by_tag_id'],
          tag_name: metadata['created_by_tag_name'],
        },
        organizationId,
        replaceExisting,
      });
    } else {
      const user = this.host.models.User.get(metadata['created_by']);
      if (user) {
        createdBy.user = user;
      } else {
        const injectArgs = { id: metadata['created_by'] };
        if ('created_by_display_name' in metadata) {
          injectArgs.displayName = metadata['created_by_display_name'];
        }
        createdBy.user = this.host.models.User.injectPlaceholder(injectArgs);
      }
    }

    return createdBy;
  };

  __injectTargetEntity = (metadata, organizationId) => {
    const target = {};

    if (metadata['target_tag_id'] !== undefined) {
      target.role = this.host.roles.__injectRoleFromMetadata({
        metadata: {
          display_name: metadata['target_display_name'],
          tag_color: metadata['target_tag_color'],
          tag_id: metadata['target_tag_id'],
          tag_name: metadata['target_tag_name'],
          token: metadata['target_id'],
        },
        organizationId,
        replaceExisting: false,
      });
    } else {
      const user = this.host.models.User.get(metadata['target_id']);
      if (user) {
        target.user = user;
      } else {
        const injectArgs = { id: metadata['target_id'] };
        if ('target_display_name' in metadata) {
          injectArgs.displayName = metadata['target_display_name'];
        }
        target.user = this.host.models.User.injectPlaceholder(injectArgs);
      }
    }

    return target;
  };

  __removeFriend = async ({
    attrs,
    entityType,
    id,
    organizationId,
    conversation: conversationParam,
  }) => {
    const conversation = conversationParam || this.get(entityType, id, organizationId);
    const group = entityType === 'group' ? this.host.models.Group.get(id) : null;
    const isForum = !!(group && group.groupType === GroupType.FORUM);

    let content;
    if (entityType === 'user' || this.config.condensedReplays) {
      content = conversation && conversation.content.slice();
    } else {
      content = conversation && conversation.messages.slice();
    }

    if (isForum) {
      const forum = await this.host.groups.find(id, { ignoreNotFound: true, bypassCache: true });
      if (!forum) {
        this.host.models.Group.eject(group);
      } else {
        attrs.hasCurrentUser = false;
        attrs.members = null;
        this.host.models.Group.inject(attrs);
      }
    }

    if (!this.config.keepConversationsForAllForums || !isForum) {
      if (conversation) {
        this.__ejectConversation(conversation.id);
      }

      if (group && this.config.condensedReplays) {
        this.host.models.Group.eject(group);
      }
    }

    if (Array.isArray(content)) {
      for (const item of content) {
        this.host.ejectModel(item, item.$entityType);
      }
    }
  };

  reactToUpdateTeamRequestEvent({ data }) {
    data = jsonCloneDeep(data);
    const { team_request } = data;

    if (team_request) {
      this.host.teams.__injectTeamRequest(team_request);
    }
  }

  reactToUpdateEventCR({ data }) {
    data = jsonCloneDeep(data);
    const { entity, organization_id: organizationId } = data;
    const { id, metadata, token, type } = entity;
    const groupId = token || id;
    entity.organizationId = organizationId;

    this._sanitizeGroupAttrs(entity);

    const entityType = this.host.modelNameByEntityType(type);
    const groupType = this.host.groups.__extractGroupTypeFromAttrs(entity);
    const containsARoleUpdate = metadata?.feature_service === 'role' && !metadata['target_id'];
    const isRelatedToRoleP2P =
      groupType === GroupType.GROUP ||
      (entityType === 'group' && metadata?.feature_service === 'role' && metadata['target_id']);

    if (metadata && organizationId && groupId && GROUP_TYPES_WITH_METADATA.includes(groupType)) {
      this.host.metadata.__injectMetadata(groupId, organizationId, metadata);
    }

    if (containsARoleUpdate) {
      this.host.roles.reactToUpdateEventCR({ entity });
    }

    if (entityType === 'user' && !entity['dnd_auto_forward_receiver']) {
      entity['dnd_auto_forward_receiver'] = null;
    }

    if (isRelatedToRoleP2P) {
      this.__injectCounterParty({
        entityAttrs: entity,
        entityType,
        groupType,
        organizationId,
      });
    } else {
      this.host.injectModel(entity, type);
    }
  }

  reactToUpdateEvent({ data }) {
    if (this.config.condensedReplays) {
      this.reactToUpdateEventCR({ data });
      return;
    }
    data = jsonCloneDeep(data);
    const { metadata, organization_key: organizationId, token: groupId, type } = data;
    const { ...entity } = data;

    this._sanitizeGroupAttrs(data);

    const entityType = this.host.modelNameByTypeNS(type);
    const groupType = this.host.groups.__extractGroupTypeFromAttrs(data);
    const shouldUpdateRoleOwners =
      entityType === 'group' && ![GroupType.FORUM, GroupType.ROLE_ASSIGNMENT].includes(groupType);
    const containsARoleUpdate = metadata?.feature_service === 'role' && !metadata['target_id'];
    const isRelatedToRoleP2P =
      entityType === 'group' && metadata?.feature_service === 'role' && metadata['target_id'];

    if (metadata && organizationId && groupId && GROUP_TYPES_WITH_METADATA.includes(groupType)) {
      this.host.metadata.__injectMetadata(groupId, organizationId, metadata);
    }

    if (shouldUpdateRoleOwners) {
      this.updateRolesByProxiedMembers({
        members: entity.members,
        proxiedMembers: entity.proxied_members,
        organizationId,
      });
    }

    if (isRelatedToRoleP2P) {
      this.__handleRoleP2PUpdateEvent(data);
      return;
    }

    if (containsARoleUpdate) {
      this.host.roles.reactToUpdateEvent({ data });
    }

    this.host.injectModel(entity, type);

    const conversation = organizationId
      ? this.host.conversations.get('group', groupId, organizationId)
      : null;
    if (conversation) {
      this.host.models.Conversation.inject(conversation);
    }
  }

  __handleRoleP2PUpdateEvent = (data) => {
    const { metadata, organization_key: organizationId, type } = data;
    const { ...entity } = data;

    if (metadata) {
      if (metadata['created_by']) {
        const { role = null, user = null } = this.__injectCreatedByEntity(
          metadata,
          organizationId,
          data
        );
        if (role || user) {
          entity.createdByRole = role;
          entity.createdByUser = user;
        }
      }
      if (metadata['target_id']) {
        const { role = null, user = null } = this.__injectTargetEntity(metadata, organizationId);
        if (role || user) {
          entity.targetRole = role;
          entity.targetUser = user;
        }
      }
    }

    this.host.groups._setP2PEntities(entity);

    this.host.injectModel(entity, type);
  };

  updateRolesByProxiedMembers = async ({
    groupId,
    members: m,
    proxiedMembers: pm,
    organizationId: orgId,
  }: {
    groupId?: string;
    members?: string[];
    proxiedMembers?: string[];
    organizationId?: string;
  }) => {
    let members = m;
    let proxiedMembers = pm;
    let organizationId = orgId;

    if (groupId) {
      const group = this.host.models.Group.get(groupId);
      if (!group || !group.proxiedMembers) return;
      members = group.memberIds;
      proxiedMembers = group.proxiedMembers;
      organizationId = group.organizationId;
    }

    if (!proxiedMembers || Array.isArray(proxiedMembers)) return;

    const occupiedRoleIds = Object.values(proxiedMembers)
      .flat(1)
      .map((entity) => entity.token);

    // update empty roles
    for (const id of members) {
      const userOrRole = this.host.users.getById(id);
      const notARole = userOrRole ? !userOrRole.isRoleBot : false;

      if (proxiedMembers[id] || notARole || occupiedRoleIds.includes(id)) continue;
      let role;
      try {
        role = await this.host.roles.find(id, organizationId, { ignoreNotFound: true });
        if (!role || role?.memberIds?.length === 0) continue;
      } catch (e) {
        continue;
      }
      this.host.models.Role.inject({ id: role.id, memberIds: [] });
    }

    // update occupied roles
    for (const userId in proxiedMembers) {
      if (!members.includes(userId)) continue;
      let user;
      try {
        user = this.host.users.getById(userId);
        if (!user) {
          const { user: returnedUser } = await this.host.users.__find(userId, organizationId, {
            ignoreNotFound: true,
          });
          user = returnedUser;
        }
        if (!user) continue;
      } catch (e) {
        continue;
      }
      for (const { token: roleId } of proxiedMembers[userId]) {
        if (roleId === userId || !members.includes(roleId)) continue;
        let role;
        try {
          role = await this.host.roles.find(roleId, organizationId, { ignoreNotFound: true });
          if (!role || role?.memberIds?.[0] === user.id) continue;
        } catch (e) {
          continue;
        }
        this.host.models.Role.inject({ id: role.id, memberIds: [user.id] });
      }
    }
  };

  _sanitizeGroupAttrs = (attrs) => {
    const { members } = attrs;
    const groupType = this.host.groups.__extractGroupTypeFromAttrs(attrs);

    if (groupType === GroupType.FORUM) {
      if (members?.length === 0) {
        // the server always returns `members: []` for forums; represent those as null (unfetched) instead
        delete attrs['members'];
      }
    }
  };

  _onSendingMessage = (message: Object) => {
    let conversation;
    if (this.config.condensedReplays) {
      conversation = message.conversation;
    } else {
      conversation = this.ensureConversation(
        message.counterPartyType,
        message.counterPartyId,
        message.senderOrganizationId,
        {
          ensureEntities: {
            counterParty: 'full',
            organization: 'placeholder',
            messageCounterParties: 'full',
            groupMembers: 'full',
            touchOnRelatedEntityLoad: true,
          },
        }
      );
    }

    if (!conversation) return;

    let shouldInject = false;
    if (this.host.config.condensedReplays && message && message.sortNumber) {
      if (
        conversation.highestSortNumber === undefined ||
        conversation.highestSortNumber < message.sortNumber
      ) {
        conversation.highestSortNumber = message.sortNumber;
        shouldInject = true;
      }
    }
    if (conversation.highestSortNumber === undefined) {
      conversation.highestSortNumber = this.host.messages.__getNextSortNumber(
        conversation.organizationId
      );
      shouldInject = true;
    }

    if (shouldInject) {
      this.host.models.Conversation.inject(conversation);
    }

    this.__addConversationCounterPartyToServerRosterIfShould(conversation);
  };

  _onSentMessage = (message: Object) => {
    let shouldInject = false;
    const conversation = this.ensureConversation(
      message.counterPartyType,
      message.counterPartyId,
      message.senderOrganizationId
    );

    if (!conversation) return;
    if (this.host.config.condensedReplays) {
      if (
        message &&
        message.sortNumber &&
        (conversation.highestSortNumber === undefined ||
          conversation.highestSortNumber < message.sortNumber)
      ) {
        conversation.highestSortNumber = message.sortNumber;
        shouldInject = true;
      } else if (conversation.highestSortNumber === undefined) {
        conversation.highestSortNumber = this.host.messages.__getNextSortNumber(
          conversation.organizationId
        );
        shouldInject = true;
      }
    }

    if (shouldInject) {
      this.host.models.Conversation.inject(conversation);
    }

    conversation.emit('message', message);
  };

  _onChangeMessage = (resource, message) => {
    if (!message.conversation) return;

    let flushReload = true;
    if (!this.host.isInitialMessageReplayDone) {
      if (message.messageType === MessageType.USER_SENT) {
        this.__highestHistoricalSortNumber = Math.max(
          this.__highestHistoricalSortNumber,
          message.sortNumber
        );
      }

      flushReload = false;
    } else if (!this.host.isInitialBangReplayDone) {
      if (this._isHistoricalBang(message)) {
        this.__conversationsToClear[message.conversationId] = true;
        return;
      } else if (this._isIrrelevantBang(message)) {
        return;
      }
    }

    this.__queueReload(message.conversation, { flushReload });
  };

  _onRemoveMessage = (resource, message) => {
    let { conversation } = message;
    if (!conversation) return;

    if (this.config.condensedReplays) {
      const {
        firstExpiringUnreadMessage,
        id: conversationId,
        lastMessage,
        timeline,
      } = conversation;

      conversation = this.getById(conversationId);
      if (!conversation) return;

      const isLastMessage = lastMessage === message;
      const isFirstExpiringUnreadMessage = firstExpiringUnreadMessage === message;
      const removingLastMessageInConversation = timeline.length === 1 && isLastMessage;

      if (isLastMessage || isFirstExpiringUnreadMessage) {
        this.find(conversation.id);
      }

      if (removingLastMessageInConversation) {
        conversation.lastMessage = null;
      }
    }

    this.__queueReload(conversation);
  };

  _onRemoveGroup = (resource, group) => {
    const conversationId = !group.conversationId
      ? this.getConversationKey('group', group.id, group.organizationId)
      : group.conversationId;
    this.__ejectConversation(conversationId);
  };

  _onRemoveTeam = (resource, team) => {
    const conversationId = this.getConversationKey('team', team.id, team.organizationId);
    this.__ejectConversation(conversationId);
  };

  _onChangeGroup = (resource, group) => {
    if (group.conversationId) {
      this.host.models.Conversation.touch(group.conversationId);
    }
  };

  _onChangeOrganization = (resource, organization) => {
    const { conversations } = organization;
    const previouslyEnabledIdx = this.__escalationEnabledOrganizations.indexOf(organization);
    const previouslyEnabled = previouslyEnabledIdx > -1;

    if (previouslyEnabled !== organization.canEscalateMessages) {
      if (previouslyEnabled) {
        this.__escalationEnabledOrganizations.splice(previouslyEnabledIdx, 1);
      } else {
        this.__escalationEnabledOrganizations.push(organization);
      }

      if (conversations) {
        for (const conversation of conversations) {
          this.__queueReload(conversation);
        }
      }
    }
  };

  _onChangeUser = (resource, user) => {
    if (user.$placeholder || !this._placeholderBangs[user.id]) return;

    for (const { event, message } of this._placeholderBangs[user.id]) {
      if (user.isRoleBot) {
        const { action } = event;
        event.action = action === 'ADD' || action === 'JOIN' ? 'OPT_IN' : 'OPT_OUT';
        message.body = this.__generateGroupMembershipChangeBody(event);

        this.host.models.GroupMembersChange.inject({
          id: `groupMembersChange:${event.id}`,
          action: event.action,
        });
      }

      delete message.$placeholder;
      this.host.models.Message.inject(message);
    }

    delete this._placeholderBangs[user.id];
  };

  __queueReload(conversation, { flushReload = true } = {}) {
    if (!this.__queuedConversationsToReload.includes(conversation)) {
      this.__queuedConversationsToReload.push(conversation);
    }

    if (flushReload) {
      this.__flushConversationsToReload();
    }
  }

  __flushConversationsToReload = () => {
    const liveConversations =
      this.__queuedConversationsToReload.length > 0 &&
      this.__queuedConversationsToReload.filter((conversation) => conversation.isLive);
    if (
      this.host.isInitialBangReplayDone &&
      this.host.currentlyServingOfflineMessages &&
      liveConversations.length === 0
    )
      return;

    if (this.__queuedConversationsToReload.length > 0) {
      const changedOrganizations = [];
      this.host.organizations._deferConversationChanges = true;

      for (const conversation of this.__queuedConversationsToReload) {
        const { organizationId } = conversation;
        this.__reloadConversation(conversation);

        if (!changedOrganizations.includes(organizationId)) {
          changedOrganizations.push(organizationId);
        }
      }

      this.host.organizations._deferConversationChanges = false;

      for (const organizationId of changedOrganizations) {
        const organization = this.host.organizations.getById(organizationId);
        if (organization) this.host.organizations.__reloadConversations(organization);
      }

      this.__queuedConversationsToReload.length = 0;
    }
  };

  hasPendingReloads() {
    return this.__queuedConversationsToReload && this.__queuedConversationsToReload.length > 0;
  }

  __reloadConversation(conversation, { notify = true } = {}) {
    if (this.config.condensedReplays) {
      return this.__reloadConversationCR(conversation);
    }

    const {
      content,
      higherContinuation,
      lowerContinuation,
      markableAsReadMessages,
      messages,
      timeline,
    } = conversation;

    const unreadMessages = [];
    const unreadMentionMessages = [];
    const unreadPriorityMessages = [];
    let inProgressEscalationCount = 0;
    let firstInTimeline = null;
    let lastInTimeline = null;
    let firstUnreadMessage = null;
    markableAsReadMessages.length = 0;
    messages.length = 0;
    timeline.length = 0;

    let index = -1;
    for (const message of content) {
      if (
        (!this.host.isInitialBangReplayDone || this.__conversationsToClear[conversation.id]) &&
        this._isHistoricalBang(message)
      ) {
        continue;
      }

      index++;
      const {
        escalationExecution,
        inTimeline,
        isMentioned,
        isOutgoing,
        isUnread,
        markedRecipientStatus,
        messageType,
        priority,
      } = message;

      if (inTimeline) {
        if (firstInTimeline === null) {
          firstInTimeline = index;
        }
        lastInTimeline = index;
        timeline.push(message);
      }

      if (messageType !== MessageType.USER_SENT) {
        if (BANG_MESSAGE_TYPES.includes(messageType)) {
          conversation.lastBangMessageSortNumber = message.sortNumber;
        }

        continue;
      }

      messages.push(message);

      if (!isOutgoing && isUnread && !firstUnreadMessage) {
        firstUnreadMessage = message;
      }

      if (escalationExecution && escalationExecution.status === 'IN_PROGRESS') {
        inProgressEscalationCount++;
      }

      if (NEW_OR_DELIVERED.includes(markedRecipientStatus)) {
        markableAsReadMessages.push(message);
      }

      if (!isOutgoing) {
        if (isUnread) {
          unreadMessages.push(message);

          if (priority === MessagePriority.HIGH) {
            unreadPriorityMessages.push(message);
          }

          if (this.config.allowMentions && isMentioned) {
            unreadMentionMessages.push(message);
          }
        }

        conversation.lastIncomingMessageSortNumber = message.sortNumber;
      }
    }

    lowerContinuation.itemsEstimate = firstInTimeline;
    higherContinuation.itemsEstimate = lastInTimeline === null ? null : index - lastInTimeline;

    conversation.isLive = higherContinuation.itemsEstimate === 0 || index === -1;

    const lastMessage = messages.length > 0 ? messages[messages.length - 1] : null;

    conversation.firstUnreadMessage = firstUnreadMessage;
    conversation.inProgressEscalationCount = inProgressEscalationCount;
    conversation.lastMessage = lastMessage;
    conversation.unreadCount = unreadMessages.length;
    conversation.unreadIds = unreadMessages.map((message) => message.id);
    conversation.unreadMentionMessages = unreadMentionMessages.length;
    conversation.unreadPriorityCount = unreadPriorityMessages.length;

    conversation.highestSortNumber =
      (lastMessage && lastMessage.sortNumber) || conversation.lastConversationSortNumber || 0;

    if (notify) {
      const { counterPartyType, counterPartyId, organizationId } = conversation;
      const conversationHandle = this.getConversationKey(
        counterPartyType,
        counterPartyId,
        organizationId
      );
      this._convByConvHandle[conversationHandle] = conversation;

      this.host.models.Conversation.inject(conversation);
    }
  }

  __reloadConversationCR(conversation) {
    const {
      _markingAsRead,
      _markingAsReadSortNumber,
      content,
      higherContinuation,
      messages,
      timeline,
    } = conversation;

    const unreadMessages = [];
    const unreadPriorityMessages = [];
    let firstUnreadMessage = null;
    messages.length = 0;
    timeline.length = 0;

    const messageIds = {};

    for (const message of content) {
      if (message && messageIds[message.id]) {
        this.host.report.send({
          level: 'debug',
          message: JSON.stringify({
            error: 'Duplicate message in conversation.timeline',
            conversationId: conversation.id,
            stackTrace: new Error('Duplicate Message in conversation.timeline').stack,
          }),
        });
        continue;
      } else {
        messageIds[message.id] = true;
      }

      const { inTimeline, isOutgoing, isUnread, messageType, priority, sortNumber } = message;

      if (inTimeline) timeline.push(message);

      if (messageType === MessageType.USER_SENT) {
        messages.push(message);

        if (!isOutgoing && isUnread) {
          if (!firstUnreadMessage) firstUnreadMessage = message;
          if (!(_markingAsRead && sortNumber <= _markingAsReadSortNumber)) {
            unreadMessages.push(message);
            if (priority === MessagePriority.HIGH) unreadPriorityMessages.push(message);
          }
        }
      } else {
        if (BANG_MESSAGE_TYPES.includes(messageType)) {
          conversation.lastBangMessageSortNumber = message.sortNumber;
        }
      }
    }

    conversation.firstUnreadMessage = firstUnreadMessage;
    conversation.highestSortNumber = conversation.highestSortNumber || 0;
    conversation.isLive = higherContinuation.itemsEstimate === 0 || content.length === 0;

    if (_markingAsRead) {
      conversation.unreadCount = unreadMessages.length;
      conversation.unreadPriorityCount = unreadPriorityMessages.length;
    }

    const { counterPartyType, counterPartyId, organizationId } = conversation;
    const conversationHandle = this.getConversationKey(
      counterPartyType,
      counterPartyId,
      organizationId
    );
    this._convByConvHandle[conversationHandle] = conversation;

    this.host.models.Conversation.inject(conversation);
  }

  __addConversationCounterPartyToServerRosterIfShould(conversation: Object) {
    if (!conversation) return;
    if (conversation._counterPartyAddedOnServer) return;

    const { organization } = conversation;
    if (!organization || organization.$placeholder) return;

    this.add(
      conversation.counterPartyType,
      conversation.counterPartyId,
      conversation.organizationId
    );
  }

  addMembershipChangeLog(event, { placeholder } = {}) {
    const {
      action,
      actionTime,
      actor,
      alwaysAddToTimeline,
      createdAt,
      expireIn,
      group,
      id: eventId,
      memberIds,
      members,
      removedFromGroup = false,
      sortNumber,
      ttl,
    } = event;
    const { id: actorId } = actor;
    const { id: groupId, organizationId } = group;
    const { currentUser } = this.host;
    const conversation = this.get('group', groupId, organizationId);
    let conversationId;

    if (this.config.condensedReplays) {
      if (!conversation) return;
      conversationId = conversation.id;
    } else {
      conversationId = this.getConversationKey('group', groupId, organizationId);
    }

    const groupMembersChange = this.host.models.GroupMembersChange.inject({
      id: `groupMembersChange:${eventId}`,
      action,
      actionTime,
      actor,
      memberIds,
      members,
    });

    const membershipData = {
      body: this.__generateGroupMembershipChangeBody(event),
      conversationId,
      counterPartyId: groupId,
      counterPartyType: 'group',
      createdAt,
      groupId,
      groupMembersChange,
      ...(expireIn && { expireIn }),
      id: `bang:${eventId}`,
      inTimeline:
        alwaysAddToTimeline ||
        this.__isBangInTimeline(sortNumber, conversation?.isLive, { removedFromGroup }),
      isOutgoing: false,
      messageType: MessageType.GROUP_MEMBERSHIP_CHANGE,
      recipient: currentUser,
      senderId: actorId,
      sortNumber,
      ...(ttl && { ttl }),
      ...(organizationId && {
        recipientOrganizationId: organizationId,
        senderOrganizationId: organizationId,
      }),
    };

    let message;
    if (placeholder) {
      if (!this._placeholderBangs[actorId]) {
        this._placeholderBangs[actorId] = [];
      }

      message = this.host.models.Message.injectPlaceholder(membershipData);
      this._placeholderBangs[actorId].push({
        event,
        message,
      });
    } else {
      message = this.host.models.Message.inject(membershipData);
    }
  }

  __generateGroupMembershipChangeBody(event) {
    const { action, actor, group, members } = event;
    const { groupType } = group;

    const actionText = GROUP_MEMBERSHIP_ACTION_TEXT[action] || ROLE_ACTION_TEXT[action];
    const actorNameText = actor.id === this.host.currentUserId ? 'You' : actor.displayName;
    const preposition = GROUP_MEMBERSHIP_ACTION_PREPOSITION[action] || '';

    let memberNameText = null;

    if (action in GROUP_MEMBERSHIP_ACTION_PREPOSITION || action in ROLE_ACTION_TEXT) {
      memberNameText = _initial
        .call(
          _flatten.call(
            members.map((user, i) => [
              user.id === this.host.currentUserId ? 'You' : user.displayName,
              (i === members.length - 2 ? ' and' : ',') + ' ',
            ])
          )
        )
        .join('');
    }

    if (action in ROLE_ACTION_TEXT) {
      return [memberNameText, actionText, actorNameText].filter(Boolean).join(' ');
    } else {
      return [
        actorNameText,
        actionText,
        memberNameText,
        preposition,
        'this',
        GROUP_TYPE_LABEL[groupType],
      ]
        .filter(Boolean)
        .join(' ');
    }
  }

  addCallChangeLog(event) {
    const {
      caller,
      callee = {},
      conversationId,
      createdAt,
      expireIn,
      group,
      id: eventId,
      organizationId,
      sortNumber,
      ttl,
    } = event;
    const { id: calleeId } = callee;
    const { id: groupId } = group || {};
    const isCaller = caller && caller.id === this.host.currentUser.id;

    let convId;
    if (this.config.condensedReplays) {
      convId = conversationId;
    } else {
      if (!!group) {
        convId = this.getConversationKey('group', groupId, organizationId);
      } else {
        const id = isCaller ? calleeId : caller.id;
        convId = this.getConversationKey('user', id, organizationId);
      }
    }

    this.host.models.Message.inject({
      body: this.__generateCallChangeBody(event),
      conversationId: convId,
      counterPartyId: calleeId,
      counterPartyType: 'group',
      createdAt,
      expireIn,
      groupId: calleeId,
      id: `bang:${eventId}`,
      inTimeline: this.__isBangInTimeline(sortNumber, true),
      isOutgoing: false,
      messageType: MessageType.CALL_CHANGE,
      recipient: this.host.currentUser,
      senderId: caller ? caller.id : null,
      sortNumber,
      ttl,
    });
  }

  __generateCallChangeBody(event) {
    const { action, caller, duration, group, isVideo, userStartTime } = event;
    const formattedTime = userStartTime ? moment(userStartTime).format('h:mm A') : '';
    if (action === 'CALL_CHANGE') {
      const isCaller = caller.id === this.host.currentUser.id;
      const groupText =
        group && !isCaller && !(group.groupType === 'PATIENT_MESSAGING' && group.memberCount < 3)
          ? ` from ${caller.displayName} + ${group.memberCount - 1} members`
          : '';
      return `${isCaller ? 'Outgoing' : 'Incoming'} ${
        isVideo ? 'Video Call' : 'Call'
      } (${secondsToDurationFormat(duration)})${groupText} at ${formattedTime}`;
    } else if (action === 'MISSED_CALL') {
      const groupText = group ? ` from ${caller.displayName}` : '';
      return `Missed ${isVideo ? 'Video ' : ''}Call${groupText} at ${formattedTime}`;
    }
  }

  addEscalationExecutionChangeLog(event) {
    const {
      action,
      actionTime,
      actor,
      alwaysAddToTimeline,
      createdAt,
      escalationExecution,
      expireIn,
      group,
      id: eventId,
      members,
      sortNumber,
      ttl,
    } = event;

    const { id: groupId, organizationId } = group;
    const { currentUser } = this.host;
    const conversation = this.get('group', groupId, organizationId);
    let conversationId = conversation ? conversation.id : null;

    if (!conversation) {
      if (this.config.condensedReplays) {
        return;
      } else {
        conversationId = this.getConversationKey('group', groupId, organizationId);
      }
    }

    const escalationExecutionChange = this.host.models.EscalationExecutionChange.inject({
      id: `escalationExecutionChange:${eventId}`,
      action,
      actionTime,
      actor,
      members,
    });

    this.host.models.Message.inject({
      body: this.__generateGroupEscalationChangeBody(event, escalationExecution),
      conversationId,
      counterPartyId: groupId,
      counterPartyType: 'group',
      createdAt,
      escalationExecutionChange,
      escalationExecutionId: escalationExecution ? escalationExecution.id : null,
      expireIn,
      groupId,
      id: `bang:${eventId}`,
      inTimeline: alwaysAddToTimeline || this.__isBangInTimeline(sortNumber, conversation?.isLive),
      isOutgoing: false,
      messageType: MessageType.ESCALATION_EXECUTION_CHANGE,
      recipient: currentUser,
      senderId: actor ? actor.id : null,
      sortNumber,
      ttl,
    });
  }

  __generateGroupEscalationChangeBody(event, escalationExecution) {
    const { action, actor, members } = event;
    const actionText = ESCALATION_ACTION_TEXT[action];

    let displayId = (escalationExecution && escalationExecution.displayId) || '';
    if (displayId.startsWith('#')) {
      displayId = displayId.slice(1, displayId.length);
    }
    if (displayId !== '') displayId = `${displayId} `;

    if (action === EscalationChangeAction.MEMBER_ADDED) {
      return `${actionText} ${members[0].displayName} to this escalation`;
    } else if (action !== EscalationChangeAction.INITIATED && actor && actor.displayName) {
      return `Escalation ${displayId}${actionText} by ${actor.displayName}`;
    } else {
      return `Escalation ${displayId}${actionText}`;
    }
  }

  ////////////
  /// hashes
  ////////////

  getMessageConversationKey(message: Object) {
    if (message.groupId) {
      return this.conversationHashForListEntityId(message.groupId);
    }
    if (message.distributionListId) {
      return this.conversationHashForListEntityId(message.distributionListId);
    }

    const counterPartyId =
      message.senderId === this.host.currentUserId ? message.recipientId : message.senderId;

    return this.conversationHashForUserMessage(
      counterPartyId,
      message.senderOrganizationId,
      message.recipientOrganizationId
    );
  }

  getConversationKey(
    counterPartyType: string,
    counterPartyId: string,
    senderOrganizationId: string | null | undefined,
    recipientOrganizationId: string | null | undefined = senderOrganizationId
  ) {
    switch (counterPartyType) {
      case 'user':
        return this.conversationHashForUserMessage(
          counterPartyId,
          senderOrganizationId,
          recipientOrganizationId
        );
      case 'distributionList':
      case 'group':
      case 'team':
        return this.conversationHashForListEntityId(counterPartyId);
      default:
        throw new Error(`type ${counterPartyType} not supported in conversation`);
    }
  }

  conversationHashForUserMessage(
    counterPartyId: string,
    senderOrganizationId: string,
    recipientOrganizationId: string | null | undefined = senderOrganizationId
  ) {
    const str = [counterPartyId, senderOrganizationId, recipientOrganizationId].join(':');
    return md5.b64(str);
  }

  conversationHashForListEntityId(entityId: string) {
    return md5.b64(entityId);
  }

  // aliases
  conversationHashForGroupId(...args) {
    return this.conversationHashForListEntityId(...args);
  }
  conversationHashForDistributionListId(...args) {
    return this.conversationHashForListEntityId(...args);
  }

  looksLikeConversationHandle(id: string) {
    return /^[a-zA-Z0-9/+=]{24}$/.test(id);
  }

  ////////////
  /// finders and getters
  ////////////

  async findConversationWithUser(userId: string, organizationId: string) {
    return this.get(this.host.models.User.name, userId, organizationId);
  }

  async findConversationWithGroup(groupId: string, organizationId: string) {
    return this.get(this.host.models.Group.name, groupId, organizationId);
  }

  async findAllGroupConversationsWithMembers(
    memberIds: string | string[] | Object[],
    organizationId: string,
    {
      exact = true,
      includeCurrentUser = true,
      includeForums = true,
      includeRoles = true,
      localOnly = false,
      senderId = null,
    } = {}
  ) {
    memberIds = await this.resolveMembers(arrayWrap(memberIds), organizationId);
    memberIds = memberIds.map(this._resolveModelId);

    const groups = await this.host.groups.findAllWithSpecificMembers(memberIds, organizationId, {
      exact,
      includeCurrentUser,
      includeForums,
      includeRoles,
      localOnly,
      senderId,
    });

    const conversations = [];
    for (const group of groups) {
      const conversation = this.get(this.host.models.Group.name, group.id, organizationId);
      if (conversation) conversations.push(conversation);
    }

    return conversations;
  }

  findLocalRoleP2PConversation(recipientId, senderId) {
    let conversationResult;
    let roleP2PConversations;

    recipientId = this.host.roles.__resolveRoleId(recipientId);
    senderId = this.host.roles.__resolveRoleId(senderId);

    const recipientUser = this.host.models.User.get(recipientId);
    const senderUser = this.host.models.User.get(senderId);

    if (senderUser && senderUser.isRoleBot && senderUser.botRole) {
      roleP2PConversations = this.host.roles._roleP2PConversations[senderId];
    } else if (recipientUser && recipientUser.isRoleBot && recipientUser.botRole) {
      roleP2PConversations = this.host.roles._roleP2PConversations[recipientId];
    } else {
      return null;
    }

    if (roleP2PConversations) {
      for (const conversation of roleP2PConversations) {
        const { $lastModified, counterParty } = conversation;
        const { p2pRecipient, p2pSender } = counterParty;
        if (!p2pRecipient || !p2pSender) continue;

        const p2pRecipientId = this.host.roles.__resolveRoleId(p2pRecipient.id);
        const p2pSenderId = this.host.roles.__resolveRoleId(p2pSender.id);
        const matchesNormal = p2pRecipientId === recipientId && p2pSenderId === senderId;
        const matchesReverse = p2pRecipientId === senderId && p2pSenderId === recipientId;

        if (
          (matchesNormal || matchesReverse) &&
          (!conversationResult || conversationResult.$lastModified < $lastModified)
        ) {
          conversationResult = conversation;
        }
      }
    }

    return conversationResult;
  }

  async resolveMembers(memberIds, organizationId) {
    return await Promise.all(
      memberIds.map(async (memberId) => {
        if (isEmail(memberId) || isPhone(memberId)) {
          try {
            const user = await this.host.users.find(memberId, { organizationId });

            return user.id;
          } catch (err) {
            return memberId;
          }
        }

        return memberId;
      })
    );
  }

  async selectConversation(
    conversationId: string | Object,
    {
      anchorPoint = ConversationAnchorPoints.CONVERSATION_END,
      fetchAllItems = false,
      markAsDelivered = true,
      markAsRead = true,
      maxItemsPerBatch,
      minItemsToFetch = DEFAULT_SELECT_CONVERSATION_ITEMS,
      isConversationBeingSelectedForPrintMode = false,
    } = {}
  ) {
    conversationId = this._resolveModelId(conversationId);
    const conversation = this._resolveEntity(conversationId, 'conversation');
    if (!conversation)
      throw new errors.NotFoundError(this.host.models.Conversation.name, conversationId);

    if (anchorPoint === ConversationAnchorPoints.CONTINUATION) {
      throw new Error("Cannot call selectConversation() with anchorPoint='CONTINUATION'");
    }

    if (this.config.condensedReplays) {
      const result = await this._selectConversationCR(conversationId, {
        anchorPoint,
        conversation,
        fetchAllItems,
        markAsDelivered,
        markAsRead,
        maxItemsPerBatch,
        minItemsToFetch,
      });

      return result;
    }

    this.__selectedConversations[conversation.id] = true;

    if (this.host.isInitialBangReplayDone && this.__conversationsToClear[conversationId]) {
      delete this.__conversationsToClear[conversationId];
      this.clearTimeline(conversationId);
    }

    let addedMessages = 0;

    addedMessages += await this.fetchTimeline(conversationId, {
      anchorPoint,
      markAsDelivered,
      maxItems: minItemsToFetch,
      isConversationBeingSelectedForPrintMode,
    });

    if (conversation.markableAsReadMessages.length > 0) {
      conversation.firstUnreadMessage = conversation.markableAsReadMessages[0];
    } else {
      conversation.firstUnreadMessage = null;
    }

    const { firstUnreadMessage } = conversation;
    if (markAsRead && conversation.unreadCount > 0) {
      this.markAsRead(conversationId, { localOnly: true });
    }

    addedMessages = await this._fetchContinuations(conversationId, {
      addedMessages,
      conversation,
      fetchAllItems,
      markAsDelivered,
      minItemsToFetch,
    });

    if (markAsRead) this.markAsRead(conversationId);

    if (!this.host.isInitialBangReplayDone && addedMessages > 0) {
      this.__reloadConversation(conversation);
    }

    return {
      addedMessages,
      previousFirstUnreadMessage: firstUnreadMessage,
    };
  }

  async _selectConversationCR(
    conversationId,
    {
      anchorPoint,
      conversation,
      fetchAllItems = false,
      markAsDelivered,
      markAsRead,
      minItemsToFetch,
      maxItemsPerBatch = minItemsToFetch,
    }
  ) {
    const shouldMarkAsRead = markAsRead && conversation.unreadCount > 0;
    let addedMessages = 0;

    if (shouldMarkAsRead) this.markAsRead(conversationId, { localOnly: true });

    addedMessages += await this.fetchTimeline(conversationId, {
      anchorPoint,
      fetchAllItems,
      markAsDelivered,
      maxItems: maxItemsPerBatch,
    });

    addedMessages = await this._fetchContinuations(conversationId, {
      addedMessages,
      conversation,
      fetchAllItems,
      markAsDelivered,
      maxItemsPerBatch,
      minItemsToFetch,
    });

    const { firstUnreadMessage } = conversation;
    if (shouldMarkAsRead) this.markAsRead(conversationId);
    this.__ensureEntities(conversation, { messageCounterParties: 'full' });

    return {
      addedMessages,
      previousFirstUnreadMessage: firstUnreadMessage,
    };
  }

  async _fetchContinuations(
    conversationId,
    {
      addedMessages = 0,
      conversation,
      fetchAllItems = false,
      markAsDelivered = true,
      minItemsToFetch = DEFAULT_SELECT_CONVERSATION_ITEMS,
      maxItemsPerBatch = minItemsToFetch,
    } = {}
  ) {
    if (addedMessages === 0) return 0;

    while (
      (fetchAllItems || addedMessages < minItemsToFetch) &&
      conversation.higherContinuation.itemsEstimate > 0
    ) {
      addedMessages += await this.fetchTimeline(conversationId, {
        anchorPoint: ConversationAnchorPoints.CONTINUATION,
        markAsDelivered,
        continuation: conversation.higherContinuation.continuation,
        fetchAllItems,
      });
    }

    while (
      (fetchAllItems || addedMessages < minItemsToFetch) &&
      conversation.lowerContinuation.itemsEstimate > 0
    ) {
      addedMessages += await this.fetchTimeline(conversationId, {
        anchorPoint: ConversationAnchorPoints.CONTINUATION,
        markAsDelivered,
        continuation: conversation.lowerContinuation.continuation,
        fetchAllItems,
      });
    }

    return addedMessages;
  }

  getWithUser(
    userId: string | Object,
    organizationId: string | Object,
    /*: { createIfNotFound?: ?boolean }*/
    { createIfNotFound = false } = {}
  ) {
    let conversation;
    userId = this._resolveModelId(userId);
    organizationId = this._resolveModelId(organizationId);

    if (this.config.condensedReplays) {
      const user = this.host.users.getById(userId);
      if (user && user.profileByOrganizationId && user.profileByOrganizationId[organizationId]) {
        const conversationId = user.profileByOrganizationId[organizationId].conversationId;
        if (conversationId) {
          conversation = this.getById(conversationId);
        }
      }
    } else {
      const key = this.conversationHashForUserMessage(userId, organizationId);
      conversation = this.getById(key);
    }

    if (!conversation && createIfNotFound) {
      conversation = this.ensureConversation('user', userId, organizationId);
    }

    return conversation || null;
  }

  getWithListEntity(
    entityType: string,
    entityId: string | Object,
    {
      createIfNotFound = false,
      organizationId,
      conversationId = null,
      shouldDisplay = false,
      patientContextId,
    } = {
      createIfNotFound: false,
    }
  ) {
    let conversation, conversationHash;
    entityId = this._resolveModelId(entityId);

    if (this.config.condensedReplays) {
      if (conversationId) {
        conversation = this.getById(conversationId);
      }
    } else {
      conversationHash = this.conversationHashForListEntityId(entityId);
      conversation = this.getById(conversationHash);
    }

    if (!conversation && createIfNotFound) {
      const entity = this._resolveEntity(entityId, entityType);
      if (entity) {
        organizationId = entity.organizationId;
      } else {
        throw new Error('Entity required when using createIfNotFound');
      }

      conversation = this.ensureConversation(entityType, entityId, organizationId, {
        conversationId: conversationHash || conversationId,
        shouldDisplay,
        patientContextId,
      });
      if (this.config.condensedReplays && !conversationId) {
        conversation.$localOnly = true;
      }
    }

    return conversation;
  }

  getWithGroup(groupId) {
    return this.getWithListEntity('group', groupId);
  }

  getWithDistributionList(distributionListId) {
    return this.getWithListEntity('distributionList', distributionListId);
  }

  get(counterPartyType: string, counterPartyId: string, organizationId: string) {
    let conversation;
    const conversationHandle = this.getConversationKey(
      counterPartyType,
      counterPartyId,
      organizationId
    );

    if (this.config.condensedReplays) {
      conversation = this._convByConvHandle[conversationHandle];
    } else {
      conversation = this.host.models.Conversation.get(conversationHandle);
    }

    return conversation || null;
  }

  getAll() {
    return this.host.models.Conversation.getAll();
  }
  getById(id: string) {
    return this.host.models.Conversation.get(id);
  }
  getMultiById(ids: Array<string>) {
    return this.host.models.Conversation.getMulti(ids);
  }

  getAllForOrganization(organizationId) {
    const organization = this._resolveEntity(organizationId, 'organization');
    if (!organization) return null;

    return organization.conversations;
  }

  // ensures all related entities are loaded/placeholders
  async __ensureEntities(conversation: Object, options = {}) {
    let promises = [];
    let counterPartyPromise = Promise.resolve();

    if (options.counterParty) {
      counterPartyPromise = this.__ensureCounterParty(conversation, options);
      promises.push(counterPartyPromise);
    }
    if (options.escalationExecution) {
      promises.push(this.__ensureEscalationExecution(conversation, counterPartyPromise, options));
    }
    if (options.messageCounterParties) {
      promises.push(this.__ensureMessageCounterParties(conversation, options));
    }
    if (options.organization) {
      promises.push(this.__ensureOrganization(conversation, options));
    }
    if (options.patient) {
      promises.push(this.__ensurePatient(conversation, counterPartyPromise, options));
    }
    if (options.calculateRoleOwnership) {
      this.updateRolesByProxiedMembers({ groupId: conversation.counterPartyId });
    }

    promises = promises.filter(Boolean);
    if (promises.length === 0) return;

    try {
      await Promise.all(promises);

      options.onLoad && options.onLoad();

      if (this.host.models.Conversation.get(conversation.id)) {
        this.host.models.Conversation.inject(conversation);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async __ensureOrganization(conversation: Object, options = {}) {
    await this.host.organizations._findAll();

    if (
      !this.host.models.Organization.shouldEnsure(
        conversation.organization,
        options.organization !== 'full'
      )
    ) {
      return null;
    }

    await new Promise((resolve, reject) => {
      this.host.models.Organization.ensureEntity(conversation.organizationId, {
        onlyPlaceholder: options.organization === 'placeholder',

        onMissing: () => {
          this.logger.info(`org ${conversation.organizationId} not found, created placeholder org`);
          conversation.$loadingOrganization = true;
          // we rely on organizations to come in from the organizations.findAll() call and do not
          // usually attempt to manually load a single organization, because it can cause unexpected
          // conversations to appear in Messenger, so we consider a missing org to be "done"
          resolve();
        },

        onLoad: () => {
          this.logger.info(`org ${conversation.organizationId} loaded`);
          conversation.$loadingOrganization = false;
          options.touchOnRelatedEntityLoad && this.host.models.Conversation.inject(conversation);
        },

        onFinish: resolve,
      });
    });
  }

  async __ensureCounterParty(conversation: Object, options = {}) {
    options = { ...options };

    const model = this.host.modelsByEntityType[conversation.counterPartyType];

    // do we really need to ensure this counter party?
    // in case of groups - check also if we need full members or not
    const shouldEnsure = model.shouldEnsure(
      conversation.counterParty,
      options.counterParty !== 'full',
      {
        groupMembersOnlyPlaceholder: options.groupMembers !== 'full',
      }
    );
    if (!shouldEnsure) return null;

    const counterPartyAttrs = {};

    switch (conversation.counterPartyType) {
      case 'user':
        break;
      case 'group':
      case 'distributionList':
        counterPartyAttrs.organizationId = conversation.organizationId;
        break;
      default:
        throw new Error('unsupported counterPartyType', conversation.counterPartyType);
    }

    const groupEnsureMembers = (foundEntity) => {
      // special case for groups - need to load members too
      if (
        model.name === 'group' &&
        options.counterParty !== 'placeholder' &&
        options.groupMembers
      ) {
        return this.host.groups.ensureMembers(foundEntity, {
          onlyPlaceholder: options.groupMembers === 'placeholder',
        });
      }
    };

    return new Promise((resolve, reject) => {
      model.ensureEntity(conversation.counterPartyId, {
        attrs: counterPartyAttrs,

        onlyPlaceholder: options.counterParty === 'placeholder',

        onMissing: () => {
          this.logger.info(
            `entry ${conversation.counterPartyId} not found, created placeholder` +
              ` on org ${conversation.organizationId}`
          );
          conversation.$loadingCounterParty = true;
        },

        onLoad: () => {
          conversation.$loadingCounterParty = false;
          if (options.touchOnRelatedEntityLoad) this.host.models.Conversation.inject(conversation);
        },

        onFinish: (foundEntity) => {
          if (foundEntity) groupEnsureMembers(foundEntity);
          resolve();
        },
      });
    });
  }

  async __ensureEscalationExecution(conversation: Object, counterPartyPromise, options = {}) {
    await counterPartyPromise;

    const { counterPartyType, counterParty } = conversation;
    if (!counterParty || counterPartyType !== 'group') return;

    const { escalationExecution, groupType } = counterParty;
    if (groupType !== GroupType.ESCALATION || !escalationExecution) return;

    await this.host.escalations.ensureEscalationExecution(escalationExecution);
  }

  __ensureMessageCounterParties(conversation: Object, options = {}) {
    const allSenderIds = _without.call(
      _uniq.call(_map.call(conversation.messages, 'senderId').filter(Boolean)),
      this.host.currentUserId,
      conversation.counterPartyId
    );

    return Promise.all(
      allSenderIds.map((senderId) =>
        this.__ensureMessageCounterParty(conversation, senderId, options)
      )
    );
  }

  __ensureMessageCounterParty(conversation, messageOtherUserId: string, options = {}) {
    const sender = this.host.models.User.get(messageOtherUserId);

    const onLoad = () => {
      if (conversation && options.touchOnRelatedEntityLoad) {
        this.host.models.Conversation.inject(conversation);
      }
    };

    const shouldEnsure = this.host.models.User.shouldEnsure(
      sender,
      options.messageCounterParties !== 'full',
      {
        subcribeToReload: true,
        onLoad,
      }
    );

    if (!shouldEnsure) return null;

    return new Promise((resolve, reject) => {
      this.host.models.User.ensureEntity(messageOtherUserId, {
        subcribeToReload: true,
        onlyPlaceholder: options.messageCounterParties === 'placeholder',

        onMissing: () => {
          const msg = conversation
            ? `entry ${conversation.counterPartyId} not found, ` +
              `created placeholder on org ${conversation.organizationId}`
            : `counterParty ${messageOtherUserId} not found`;

          this.logger.info(msg);
        },

        onLoad,

        onFinish: resolve,
      });
    });
  }

  async __ensurePatient(conversation, counterPartyPromise, options = {}) {
    await counterPartyPromise;

    const { counterPartyType, counterParty, organizationId } = conversation;
    if (!counterParty || counterPartyType !== 'group') return;

    const { groupType, patientDetails } = counterParty;
    if (groupType !== GroupType.PATIENT_MESSAGING || !patientDetails?.id) return;

    const { id } = patientDetails;
    if (!this.host.models.User.shouldEnsure(id, options.patient !== 'full')) return;

    await this.host.users.ensureUsers([id], organizationId);
  }

  _setAllowedSenders(conversation) {
    const { counterParty, counterPartyType, organization } = conversation;
    const currentUser = this.host.currentUser;
    const defaultSenders = [currentUser];

    if (!organization) {
      conversation.allowedSenders = [];
      conversation.allowedSendersReason = Reason.ORGANIZATION_DOES_NOT_EXIST;
      return;
    } else if (currentUser && currentUser.contactsMessagingBlocked && organization.isContacts) {
      conversation.allowedSenders = [];
      conversation.allowedSendersReason = Reason.CONTACTS_MESSAGING_BLOCKED_BY_ADMIN;
      return;
    } else if (!counterPartyType) {
      conversation.allowedSenders = defaultSenders;
      conversation.allowedSendersReason = Reason.CONVERSATION_NOT_FULLY_LOADED;
      return;
    } else if (!counterParty) {
      conversation.allowedSenders = [];
      conversation.allowedSendersReason = Reason.NO_COUNTER_PARTY;
      return;
    }

    switch (counterPartyType) {
      case 'user':
        if (counterParty.$placeholder) {
          conversation.allowedSenders = [];
          conversation.allowedSendersReason = Reason.COUNTER_PARTY_NOT_FULLY_LOADED;
          return;
        } else if (counterParty.displayName === 'TigerPage') {
          conversation.allowedSenders = [];
          conversation.allowedSendersReason = Reason.COUNTER_PARTY_IS_TIGERPAGE;
          return;
        } else {
          conversation.allowedSenders = defaultSenders;
          conversation.allowedSendersReason = Reason.NONE;
          return;
        }

      case 'distributionList':
        conversation.allowedSenders = defaultSenders;
        conversation.allowedSendersReason = Reason.NONE;
        return;

      case 'group':
        const {
          createdByRole,
          createdByUser,
          groupType,
          p2pRecipient,
          p2pSender,
          targetRole,
          targetUser,
        } = counterParty;
        let { members, proxiedMembers } = counterParty;
        const { allowedSenders: orgAllowedSenders } = organization;

        if (groupType === GroupType.FORUM) {
          conversation.allowedSenders = defaultSenders;
          conversation.allowedSendersReason = Reason.NONE;
          return;
        } else if (groupType === GroupType.ROLE_ASSIGNMENT) {
          conversation.allowedSenders = [];
          conversation.allowedSendersReason = Reason.IS_ROLE_ASSIGNMENT_GROUP;
          return;
        } else if (groupType === GroupType.ROLE_P2P && p2pRecipient && p2pSender) {
          const extraMembers = [];
          const createdByBotUser = createdByRole && createdByRole.botUser;
          const targetBotUser = targetRole && targetRole.botUser;

          if (createdByBotUser && !(members && members.includes(createdByBotUser))) {
            extraMembers.push(createdByBotUser);
          } else if (createdByUser && !(members && members.includes(createdByUser))) {
            extraMembers.push(createdByUser);
          }
          if (targetBotUser && !(members && members.includes(targetBotUser))) {
            extraMembers.push(targetBotUser);
          } else if (targetUser && !(members && members.includes(targetUser))) {
            extraMembers.push(targetUser);
          }
          if (extraMembers.length > 0) members = [...(members || []), ...extraMembers];
        } else if (counterParty.$placeholder) {
          conversation.allowedSenders = [];
          conversation.allowedSendersReason = Reason.COUNTER_PARTY_NOT_FULLY_LOADED;
          return;
        }

        if (!members) {
          conversation.allowedSenders = [];
          conversation.allowedSendersReason = Reason.GROUP_MEMBERS_NOT_LOADED;
          return;
        } else if (members.length === 0) {
          conversation.allowedSenders = [];
          conversation.allowedSendersReason = Reason.GROUP_HAS_NO_MEMBERS;
          return;
        }

        const allowedSenders = [];

        for (const entity of orgAllowedSenders) {
          const userEntity = entity.$entityType === 'role' ? entity.botUser : entity;
          if (!members.includes(userEntity)) continue;

          const { id: userId } = userEntity;
          const proxiedEntries = proxiedMembers && proxiedMembers[userId];
          if (proxiedEntries) {
            let inGroupAsSelf = false;
            for (const { token } of proxiedEntries) {
              if (token === userId) inGroupAsSelf = true;
            }
            if (!inGroupAsSelf) continue;
          }

          allowedSenders.push(entity);
        }

        if (allowedSenders.length === 0) {
          conversation.allowedSenders = allowedSenders;
          conversation.allowedSendersReason = Reason.USER_IS_NOT_MEMBER_OF_GROUP;
          return;
        } else if (groupType !== GroupType.ROLE_P2P) {
          conversation.allowedSenders = allowedSenders;
          conversation.allowedSendersReason = Reason.NONE;
          return;
        }

        if (!p2pSender || !allowedSenders.includes(p2pSender)) {
          conversation.allowedSenders = [];
          conversation.allowedSendersReason = Reason.USER_IS_NOT_IN_THIS_ROLE_CONVERSATION;
          return;
        }
        if (
          (p2pRecipient === currentUser ||
            (currentUser && currentUser.roles.includes(p2pRecipient))) &&
          !p2pSender?.isRoleTransitionEnabled
        ) {
          conversation.allowedSenders = [];
          conversation.allowedSendersReason = Reason.CONVERSATION_WITH_ROLE_YOU_ARE_CURRENTLY_IN;
          return;
        } else {
          conversation.allowedSenders = [p2pSender];
          conversation.allowedSendersReason = Reason.NONE;
          return;
        }

      default:
        conversation.allowedSenders = [];
        conversation.allowedSendersReason = Reason.COUNTER_PARTY_TYPE_NOT_SUPPORTED;
        return;
    }
  }

  reactToProcessingStart({ data }) {
    data = jsonCloneDeep(data);
    const {
      action_type,
      conversation_id: conversationId,
      entity,
      organization_id: organizationId,
    } = data;
    const entityId = entity.token || entity.id;

    const actionType = ProcessingActionTypes.resolve(action_type);

    this.host.products._addProcessingEvent({
      actionType,
      entity: { id: entityId, ...entity },
    });

    if (actionType === ProcessingActionTypes.ROLE_OPT_OUT) {
      this._roleConversationsProcessingEvent[entityId] =
        this.host.roles._roleConversations[entityId];
    } else if (
      actionType === ProcessingActionTypes.ORGANIZATION_MESSAGE_STATUS ||
      actionType === ProcessingActionTypes.ORGANIZATION_REPLAY
    ) {
      const organization = this.host.organizations.getById(organizationId);
      if (!organization) return;
      if (organization._markingAsReadConversations) {
        this.__clearOptimisticStatus(organization._markingAsReadConversations);
      }
    } else if (actionType === ProcessingActionTypes.GROUP_REPLAY) {
      let conversation;
      if (conversationId) {
        conversation = this.getById(conversationId);
      }
      if (!conversation) return;
      this.__clearOptimisticStatus([conversation]);
    } else if (
      actionType === ProcessingActionTypes.ROLE_OPT_IN ||
      actionType === ProcessingActionTypes.ROLE_OPT_OUT
    ) {
      const roleConversations = this.host.roles._roleConversations[entityId];
      if (!roleConversations) return;
      this.__clearOptimisticStatus(roleConversations);
    }
  }

  reactToProcessingStop({ data }) {
    data = jsonCloneDeep(data);
    let {
      action_type,
      conversation_id: conversationId,
      entity,
      organization_id: organizationId,
    } = data;

    if (!conversationId && entity.conversation_id) {
      conversationId = entity.conversation_id;
      delete entity.conversation_id;
    }

    if (!organizationId && entity.organization_key) {
      organizationId = entity.organization_key;
      delete entity.organization_key;
    }

    const entityId = entity.token || entity.id;
    const actionType = ProcessingActionTypes.resolve(action_type);

    if (actionType === ProcessingActionTypes.GROUP_REPLAY) {
      let conversation;

      if (conversationId) {
        conversation = this.getById(conversationId);
      }

      if (!conversation) return;

      this.clearTimeline(conversation);
      this.__clearOptimisticStatus([conversation]);
    } else if (
      actionType === ProcessingActionTypes.ROLE_OPT_IN ||
      actionType === ProcessingActionTypes.ROLE_OPT_OUT
    ) {
      this.clearTimelineForAllRoleConversations(actionType, entityId);
      const roleConversations = this.host.roles._roleConversations[entityId];
      if (!roleConversations) {
        this.host.products._removeProcessingEvent({
          actionType,
          entity: { id: entityId, ...entity },
        });
        return;
      }
      this.__clearOptimisticStatus(roleConversations);
    } else if (actionType === ProcessingActionTypes.ORGANIZATION_MESSAGE_STATUS) {
      this.clearMessageStatusesForOrganization(organizationId);
    } else if (actionType === ProcessingActionTypes.CONVERSATION_MESSAGE_STATUS) {
      let conversation;

      if (conversationId) {
        conversation = this.getById(conversationId);
      }

      if (!conversation) return;

      this.clearMessageStatuses(conversation);
    }

    if (
      actionType === ProcessingActionTypes.ORGANIZATION_MESSAGE_STATUS ||
      actionType === ProcessingActionTypes.ORGANIZATION_REPLAY
    ) {
      const organization = this.host.organizations.getById(organizationId);
      if (!organization) return;
      if (organization._markingAsReadConversations) {
        this.__clearOptimisticStatus(organization._markingAsReadConversations);
      }
    }

    this.host.products._removeProcessingEvent({
      actionType,
      entity: { id: entityId, ...entity },
    });
  }

  __clearOptimisticStatus(conversations) {
    for (const conversation of conversations) {
      if (!conversation._markingAsRead) continue;

      conversation._markingAsRead = false;
      conversation._markingAsReadSortNumber = null;
      conversation._markingAsReadExpiration = null;

      // if the conversation was ejected, don't reinstate it into the store
      if (this.host.models.Conversation.get(conversation.id)) {
        this.host.models.Conversation.inject(conversation);
      }
    }
  }

  clearTimelineForAllRoleConversations(actionType, roleId) {
    let conversations;

    if (actionType === ProcessingActionTypes.ROLE_OPT_OUT) {
      conversations = this._roleConversationsProcessingEvent[roleId];
    } else {
      conversations = this.host.roles._roleConversations[roleId];
    }

    if (!conversations) return;

    for (const conversation of conversations) {
      this.clearTimeline(conversation);
    }
  }

  clearMessageStatusesForOrganization(organizationId: string) {
    const organization = this.host.organizations.getById(organizationId);
    if (!organization) return;

    const { conversations } = organization;
    if (!conversations) return;

    for (const conversation of conversations) {
      this.clearMessageStatuses(conversation);
    }
  }

  clearMessageStatuses(conversation, { emit = true } = {}) {
    const { timeline } = conversation;

    for (const message of timeline) {
      message.shouldEnsureRecipientStatus = true;
      this.host.models.Message.inject(message);
    }

    setTimeout(() => {
      for (const message of timeline) {
        if (!this.host.messages._pendingFindRecipientStatuses) return;

        const cancel = this.host.messages._pendingFindRecipientStatuses[message.id];
        if (cancel) cancel();
      }
    }, 0);

    if (emit) this.emit('clearMessageStatuses', timeline);

    return timeline.length;
  }

  async mute(conversationId: string | Object, durationInMinutes: number) {
    await this.host.mute.muteConversation(conversationId, durationInMinutes);
  }

  async unmute(conversationId: string | Object) {
    await this.host.mute.unmuteConversation(conversationId);
  }

  async unmuteAll() {
    await this.host.mute.unmuteAll();
  }
}

// @ts-nocheck
import { decorator as reusePromise } from 'reuse-promise';
import * as errors from '../errors';
import { downloadFile } from '../utils/file';
import BaseService from './BaseService';

const SEARCH_TERMS = [
  'sender_display_name',
  'recipient_display_name',
  'patient_mrn',
  'template_label',
].join(',');

export default class ScheduledMessagesService extends BaseService {
  async batchDelete({ ids, organizationId }) {
    if (!Array.isArray(ids)) {
      throw new errors.ValidationError('ids', 'required', 'ids must be an array');
    }
    if (!organizationId) {
      throw new errors.ValidationError('organizationId', 'required');
    }

    await this.host.api.scheduledMessages.batch({
      ids,
      operation: 'destroy',
      organizationId,
    });

    const scheduledMessages = ids.map((id) =>
      this.host.models.ScheduledMessage.inject({
        $deleted: true,
        id,
      })
    );

    return scheduledMessages;
  }

  async create({
    attachment,
    body,
    deliveryMethod,
    endDate,
    network = 'patient',
    noReply = false,
    organizationId,
    recipients,
    repeating,
    sendNow = false,
    startDate,
    startTime,
    templateId,
    timezoneName,
  }) {
    this._validate({
      body,
      endDate,
      organizationId,
      recipients,
      repeating,
      sendNow,
      shouldCheckId: false,
      startDate,
      startTime,
    });
    if (['patient'].includes(network) === false) {
      throw new errors.ValidationError('network', 'invalid', 'network must be "patient"');
    }

    const scheduledMessages = await this.host.api.scheduledMessages.create({
      attachment,
      body,
      delivery_method: deliveryMethod,
      end_date: endDate,
      network,
      no_reply: noReply,
      organizationId,
      recipients,
      repeating,
      send_now: sendNow,
      start_date: startDate,
      start_time: startTime,
      template_id: templateId,
      timezone_name: timezoneName,
    });

    return scheduledMessages.map((scheduleMessage) => {
      scheduleMessage['sender_id'] = this.host.currentUserId;
      return this.host.conversations.__injectCounterParty({
        entityAttrs: scheduleMessage,
        entityType: 'scheduledMessage',
        organizationId,
      });
    });
  }

  async delete(id, organizationId) {
    this._validate({ id, organizationId, shouldCheckInvalid: false });

    await this.host.api.scheduledMessages.delete(id, organizationId);

    const scheduledMessage = this.host.models.ScheduledMessage.inject({
      $deleted: true,
      id,
    });

    return scheduledMessage;
  }

  @reusePromise()
  async downloadScheduledMessageFile({ scheduleId, fileName, organizationId }) {
    const blob = await this.host.api.scheduledMessages.downloadScheduledMessageFile({
      scheduleId,
      fileName,
      organizationId,
    });
    await downloadFile({ attachmentContentType: blob.type, blob, fileName });
  }

  @reusePromise()
  async fetchHits(feed, organizationId) {
    const promises = ['history', 'queue'].map((category) =>
      this.search({
        category,
        feed,
        organizationId,
        pageSize: 0,
      })
    );

    const [historyRes, queueRes] = await Promise.all(promises);

    return {
      history: historyRes.metadata.totalHits,
      queue: queueRes.metadata.totalHits,
    };
  }

  @reusePromise()
  async find({ id, organizationId, category }) {
    this._validate({ id, organizationId, shouldCheckInvalid: false });
    const response = await this.host.api.scheduledMessages.find({
      id,
      organizationId,
      isHistoryCategory: category === 'history',
    });

    const scheduledMessage = this.host.models.ScheduledMessage.inject(response);
    return scheduledMessage;
  }

  @reusePromise()
  async findCoarseTimezone(timezone, organizationId) {
    const { abbr, name } = await this.host.api.scheduledMessages.findCoarseTimezone(
      timezone,
      organizationId
    );

    return { abbr, name };
  }

  getAll() {
    return this.host.models.ScheduledMessage.getAll();
  }

  getById(id: string) {
    return this.host.models.ScheduledMessage.get(id);
  }

  @reusePromise()
  async search({
    category,
    continuation,
    feed,
    network = 'patient',
    organizationId,
    pageSize,
    query = '',
    range,
    sortBy = 'scheduled_time',
    sortOrder = 'asc',
  }) {
    const response = await this.host.search.query({
      version: this.config.allowSearchParity ? 'SEARCH_PARITY' : 'LEGACY',
      query: {
        [SEARCH_TERMS]: query,
        ...(range ? { range } : null),
        schedule_message_category: category,
      },
      continuation,
      feedLevel: feed,
      network,
      organizationId,
      pageSize,
      returnFields: [],
      sort: { [sortBy]: sortOrder },
      types: ['scheduledMessage'],
    });

    const results = this._processSearchResults(response.results);

    return {
      metadata: response.metadata,
      results,
    };
  }

  async update({
    attachment,
    body,
    endDate,
    id,
    organizationId,
    repeating,
    startDate,
    startTime,
    templateId,
    timezoneName,
  }) {
    this._validate({
      body,
      endDate,
      id,
      organizationId,
      repeating,
      startDate,
      startTime,
    });

    const response = await this.host.api.scheduledMessages.update({
      attachment,
      body,
      end_date: endDate,
      id,
      organizationId,
      repeating,
      start_date: startDate,
      start_time: startTime,
      template_id: templateId,
      timezone_name: timezoneName,
    });

    const scheduledMessage = this.host.models.ScheduledMessage.inject(response);

    return scheduledMessage;
  }

  _processSearchResults(results) {
    return results.map(({ entity }) => entity).filter(({ $deleted }) => !$deleted);
  }

  _validate({
    body,
    endDate,
    id,
    organizationId,
    recipients,
    repeating,
    sendNow,
    shouldCheckId = true,
    shouldCheckInvalid = true,
    startDate,
    startTime,
  }) {
    if (shouldCheckId && !id) {
      throw new errors.ValidationError('id', 'required');
    }
    if (!organizationId) {
      throw new errors.ValidationError('organizationId', 'required');
    }
    if (shouldCheckInvalid) {
      if (!body || body.trim().length === 0) {
        throw new errors.ValidationError('body', 'invalid', 'body must be at least 1 character');
      }
      if (
        !shouldCheckId &&
        !(Array.isArray(recipients) && recipients.length > 0 && recipients.length <= 5)
      ) {
        throw new errors.ValidationError(
          'recipients',
          'invalid',
          'recipients must be an array of ids between 1 and 5 elements'
        );
      }

      if (!sendNow) {
        const dateStart = Date.parse(`${startDate}T${startTime}`);
        if (isNaN(dateStart)) {
          throw new errors.ValidationError(
            'startDate',
            'invalid',
            'start date and time must be the correct format'
          );
        }

        if (repeating) {
          if (['daily', 'weekly', 'biweekly', 'monthly'].includes(repeating) === false) {
            throw new errors.ValidationError(
              'repeating',
              'invalid',
              'repeating must be either "daily", "weekly", "biweekly", or "monthly"'
            );
          }

          const dateEnd = Date.parse(`${endDate}`);
          if (isNaN(dateEnd)) {
            throw new errors.ValidationError(
              'endDate',
              'invalid',
              'endDate must be the correct format'
            );
          }
        }
      }
    }
  }
}

import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import BEM from '../../bem';
import { ReactComponent as CheckSvg } from '../../images/selected-check.svg';
import { generateValidTimezones } from '../../utils';
import { MenuItemList, MenuItem } from '../ContextMenu';
import { KEYMAP } from 'common/constants';

const classes = BEM.with('TimezoneContextMenu');

const TimezoneContextMenu = ({
  onTimezoneSelected,
  selectedTime,
  selectedTimezone,
  theme,
  accessibilityMode,
  buttonRef,
}) => {
  const validTimezones = selectedTime
    ? generateValidTimezones(selectedTime)
    : generateValidTimezones();
  const selectedOptionIndex =
    validTimezones.findIndex(({ timezone }) => timezone === selectedTimezone) ?? 0;

  return (
    <MenuItemList
      className={classes({ theme })}
      accessibilityMode={accessibilityMode}
      selectedOptionIndex={selectedOptionIndex}
    >
      {validTimezones.map(({ name, abbr, timezone }) => {
        const isSelected = selectedTimezone === timezone;

        return (
          <MenuItem
            className={classes('menu-item', {
              selected: isSelected,
            })}
            onClick={() => onTimezoneSelected(timezone)}
            key={timezone}
            onKeyDown={(e) => {
              if (e.key === KEYMAP.ESCAPE || e.key === KEYMAP.TAB) {
                if (accessibilityMode) buttonRef?.focus();
              }
            }}
          >
            <p>{`${abbr} - ${name}`}</p>
            {isSelected && <CheckSvg className={classes('check')} />}
          </MenuItem>
        );
      })}
    </MenuItemList>
  );
};

TimezoneContextMenu.propTypes = {
  onTimezoneSelected: PropTypes.func.isRequired,
  selectedTime: momentPropTypes.momentObj,
  selectedTimezone: PropTypes.string.isRequired,
  theme: PropTypes.string,
  accessibilityMode: PropTypes.bool,
  buttonRef: PropTypes.any,
};

export default observer(TimezoneContextMenu);

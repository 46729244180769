import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import classNames from 'classnames';
import TextareaAutoSize from 'tt-react-textarea-autosize';
import shouldRunCommandBot from '../../utils/shouldRunCommandBot';
import { handlePaste } from '../../utils/handlePaste';
import FormattingButtons from './FormattingButtons';
import BEM from 'common/bem';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

const { setMessageBodyInputFocus } = actions;
const classes = BEM.with('MessageBodyInput');

function MessageBodyInput(props, ref) {
  const dispatch = useAppDispatch();
  const { accessibilityMode, messageBodyInputFocus } = useAppSelector(({ ui }) => ({
    accessibilityMode: ui.accessibilityMode,
    messageBodyInputFocus: ui.messageBodyInputFocus,
  }));
  const { addAttachments, body, borderColor, className, onClick, richTextFormat, tabIndex } = props;

  const [cursorPosition, setCursorPosition] = useState(null);
  const textAreaRef = useRef(null);

  function _setTextArea(ref) {
    textAreaRef.current = ref;
  }

  useEffect(() => {
    function _focus() {
      dispatch(setMessageBodyInputFocus(true));
    }
    function _blur() {
      dispatch(setMessageBodyInputFocus(false));
    }
    textAreaRef.current?.addEventListener('focus', _focus);
    textAreaRef.current?.addEventListener('blur', _blur);
    return () => {
      textAreaRef.current?.removeEventListener('focus', _focus);
      textAreaRef.current?.removeEventListener('blur', _blur);
    };
  }, [textAreaRef, dispatch]);

  useEffect(() => {
    if (accessibilityMode) return;
    if (messageBodyInputFocus && document.activeElement !== textAreaRef.current) {
      textAreaRef.current?.focus();
    } else if (!messageBodyInputFocus && document.activeElement === textAreaRef.current) {
      textAreaRef.current?.blur();
    }
  }, [accessibilityMode, messageBodyInputFocus, dispatch]);

  useEffect(() => {
    textAreaRef.current?.addEventListener('paste', (event) =>
      handlePaste(addAttachments, event, window)
    );
    return () => {
      textAreaRef.current?.removeEventListener('paste', (event) =>
        handlePaste(addAttachments, event, window)
      );
    };
  }, [textAreaRef, addAttachments]);

  useEffect(() => {
    if (textAreaRef.current && cursorPosition !== null) {
      textAreaRef.current.selectionStart = cursorPosition;
      textAreaRef.current.selectionEnd = cursorPosition;
      setCursorPosition(null);
    }
  }, [textAreaRef, cursorPosition]);

  function _handleBodyChange(e) {
    const {
      setBody,
      onTyping,
      isGiphyBotEnabled,
      isCommandBotEnabled,
      isCommandEditorOpen,
      setCommandInfo,
      toggleCommandEditor,
      onBodyChange,
    } = props;
    const body = e.target.value;
    setBody(body);
    if (body) {
      onBodyChange?.();
      onTyping?.();
      if (isCommandBotEnabled || isGiphyBotEnabled) {
        shouldRunCommandBot({
          bodyText: body,
          isGiphyBotEnabled,
          isCommandBotEnabled,
          isCommandEditorOpen,
          setState: setCommandInfo,
          toggleCommandEditor,
        });
      }
    } else {
      if (isCommandEditorOpen) toggleCommandEditor();
    }
  }

  function _handleHeightChange() {
    const { onHeightChange } = props;
    onHeightChange();
  }

  function _handleKeyDown(event) {
    const { altKey, keyCode, shiftKey } = event;
    const { onShiftTab, onSubmit } = props;

    if (keyCode === 13 && !(shiftKey || altKey)) {
      // Return
      onSubmit(event);
    } else if (keyCode === 9 && shiftKey) {
      // TAB
      onShiftTab && onShiftTab(event);
    }
  }

  const insertAtCursor = (text) => {
    const { selectionStart, selectionEnd } = textAreaRef.current;
    const { body, setBody } = props;

    const newBody = [
      body.substring(0, selectionStart),
      text,
      body.substring(selectionEnd, body.length),
    ].join('');

    setCursorPosition(selectionStart + text.length);
    setBody(newBody);
    dispatch(setMessageBodyInputFocus(true));
  };

  useImperativeHandle(ref, () => ({
    insertAtCursor,
  }));

  return (
    <div className={classNames(classes(), className)}>
      {richTextFormat && (
        <FormattingButtons
          textArea={textAreaRef}
          setCursorPosition={setCursorPosition}
          getSelection={() => textAreaRef.current || {}}
        />
      )}
      <TextareaAutoSize
        aria-label="Message Input Box"
        autoComplete="off"
        className={classes('input', {
          body: body.length > 240 ? 'long' : 'short',
          isAccessible: accessibilityMode,
        })}
        inputRef={_setTextArea}
        maxRows={10}
        name="body"
        onChange={_handleBodyChange}
        onClick={onClick}
        onHeightChange={_handleHeightChange}
        onKeyDown={_handleKeyDown}
        placeholder="Type message here"
        style={{ borderColor }}
        tabIndex={tabIndex}
        value={body}
        onKeyPress={() => {}}
      />
    </div>
  );
}

/**
 * NOTE: We avoid using mobxInjectSelect here because it makes it difficult to access this component's
 * instance methods from a parent component. We instead pass actions and observables down as props.
 */
export default forwardRef(MessageBodyInput);

// @ts-nocheck
/*eslint no-restricted-globals: [0, "self"]*/
function getGlobals() {
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw new Error('unable to locate global object');
}

module.exports = {
  getGlobals,
};

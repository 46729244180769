// @ts-nocheck
import _ from 'lodash';
import _omitBy from 'lodash-bound/omitBy';
import { decorator as reusePromise } from 'reuse-promise';
import * as errors from '../errors';
import { PROFILE_ATTRIBUTES_PER_ORG } from '../models/User';
import { isEmail } from '../utils/email';
import { isPhone, normalizePhoneUS } from '../utils/phone';
import Camelizer from '../utils/Camelizer';
import { SearchType } from '../models/enums';
import BaseService from './BaseService';

export default class UsersService extends BaseService {
  static PROFILE_ATTRIBUTES_PER_ORG = PROFILE_ATTRIBUTES_PER_ORG;

  PROFILE_ATTRIBUTES_PER_ORG = PROFILE_ATTRIBUTES_PER_ORG;

  mounted() {
    this._defaultOrganizationId = null;
    this._roleMemberIds = {};
    this.host.models.Metadata.on('afterInject', this._onChangeMetadata);
    this.host.models.Metadata.on('afterEject', this._onChangeMetadata);
    this.host.models.Role.on('afterInject', this._onChangeRole);
    this.host.models.Role.on('afterEject', this._onRemoveRole);
  }

  dispose() {
    this._defaultOrganizationId = null;
    this._roleMemberIds = {};
    this.host.models.Metadata.removeListener('afterInject', this._onChangeMetadata);
    this.host.models.Metadata.removeListener('afterEject', this._onChangeMetadata);
    this.host.models.Role.removeListener('afterInject', this._onChangeRole);
    this.host.models.Role.removeListener('afterEject', this._onRemoveRole);
  }

  /**
   * Sign a user in with a magic token
   * @param {string} magicToken Magic token returned from SAML authentication
   * @param  {String} params.udid User's device ID
   * @return {Promise<Object>} A promise that resolves with an object with two keys - user and auth
   */
  signInWithMagicToken(magicToken: string, params = {}) {
    const headers = {
      Authorization: `magic-token ${magicToken}`,
    };

    return this._signInWithHeaders(headers, params);
  }

  /**
   * Sign a user in with an API key and secret
   * @param {string} apiKey User API key
   * @param {string} apiSecret User API secret
   * @param  {String} params.udid User's device ID
   * @return {Promise<Object>} A promise that resolves with an object with two keys - user and auth
   */
  signInWithApiKeyAndSecret(apiKey: string, apiSecret: string, params = {}) {
    const headers = this.httpClient.getAuthHeaders({ key: apiKey, secret: apiSecret });

    return this._signInWithHeaders(headers, params);
  }

  /**
   * Sign a user in with a JWT token
   * @param {string} jwtToken JWT token returned from IDP authentication
   * @param  {String} params.udid User's device ID
   * @return {Promise<Object>} A promise that resolves with an object with two keys - user and auth
   */
  signInWithJwtToken(jwtToken: string, params = {}) {
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
    };

    return this._signInWithHeaders(headers, params);
  }

  /**
   * Sign a user in
   * @param  {string} userId   User Token/Email/Phone number
   * @param  {string} password User password
   * @param  {Object} params
   * @param  {String} params.udid User's device ID
   * @return {Promise<Object>} A promise that resolves with an object with two keys - user and auth
   */
  signIn(userId: string, password: string, params = {}) {
    if (isPhone(userId)) {
      userId = normalizePhoneUS(userId);
    }

    const headers = {
      Authorization: this.getSignInAuthHeader(userId, password),
    };

    return this._signInWithHeaders(headers, params);
  }

  _checkPartnerName() {
    if (!this.config.partnerName) {
      throw new Error(
        '`partnerName` must be supplied as a config option for `new TigerConnect.Client()`'
      );
    }
  }

  async _signInWithHeaders(headers, params = {}) {
    this._checkPartnerName();

    const res = await this.host.api.users.signIn(headers, params);

    const user = this.host.models.User.inject(res.data.user);
    const auth = res.data.auth;

    return { auth, user };
  }

  async checkLogin(userId: string, params = { udid: undefined }) {
    this._checkPartnerName();

    const res = await this.host.api.users.checkLogin(userId, params);

    return res.data;
  }

  /**
   * Sign a user up
   * @param  {Object} params User's properties
   * @return {Promise<User>} a promise that resolves with a user
   */
  async signUp(
    params = {
      udid: undefined,
      email: undefined,
      password: undefined,
      countryCode: undefined,
      version: undefined,
    }
  ) {
    const res = await this.host.api.users.signUp(params);

    const user = this.host.models.User.inject(res.data.user);
    const auth = res.data.auth;

    const ret = { user, auth };

    this.host.emit('signUp', ret);

    return ret;
  }

  @reusePromise({ serializeArguments: (args) => '' })
  async signOut({ clearStore = true, fromServer = false, resetClient = true } = {}) {
    if (!this.host.isSignedIn) return false;

    let signedOut = false;
    let didErr = false;
    this.host.emit('signingOut', { fromServer, resetClient });

    let res;
    try {
      res = await this.host.api.users.signOut();
      signedOut = true;
    } catch (err) {
      didErr = true;
      if (this.httpClient.isUnauthorizedError(err)) {
        signedOut = true;
      } else {
        return Promise.reject(err);
      }
    }

    if (signedOut) {
      this.host._setCurrentUser(null);
      this.host._removeAuth();
    }

    this.host.emit('signedOut', {
      clearStore,
      externalLogoutUrl: didErr ? null : res.data.external_logout_url,
      fromServer,
      resetClient: resetClient && signedOut,
      signedOut,
    });

    return signedOut;
  }

  /**
   * Create QR Code for mobile login
   * @return {Promise<Object>} A promise that resolves with a QR code string
   */
  async createQRCode() {
    const qrCode = await this.host.api.qrCode.create();
    return qrCode;
  }

  /**
   * Attempts to find a user and creates one if they does not exist
   * @param  {string} id User Token / Email Address / Phone Number
   * @param  {string} options.organizationId User's organization id
   * @return {Promise<User>} A promise that resolves with the user
   */
  @reusePromise()
  async findOrCreate(
    id: string,
    options: { organizationId?: string | null | undefined } | null | undefined = {}
  ) {
    const { organizationId } = options;

    let isContacts = false;
    if (organizationId) {
      await this.host.organizations._findAll();
      const currentOrg = this.host.models.Organization.get(organizationId);
      isContacts = currentOrg ? currentOrg.isContacts : false;
    }

    const isPotentialMessageAnyoneUser = isContacts && (isEmail(id) || isPhone(id));
    let user;
    if (!isPotentialMessageAnyoneUser || id === this.host.currentUserId || this.getById(id)) {
      user = await this.find(id, { organizationId, ignoreNotFound: true });
    }

    if (!user && isPotentialMessageAnyoneUser) {
      const response = await this.host.api.users.lookup(id);
      if (!response) return null;

      if (!this.config.condensedReplays) {
        user = await this.find(response.token, { organizationId });
      } else {
        const rawUser = response.entity;
        rawUser.conversationId = response.conversation_id;
        rawUser.organizationId = response.organization_id;
        user = this.host.models.User.inject(rawUser);
      }
    }

    return user;
  }

  /**
   * Signs the user out of all devices
   */
  @reusePromise()
  async signOutAllDevices() {
    const res = await this.host.api.users.wipe(this.host.currentUserId);

    return res;
  }

  /**
   * Gets current user
   */
  @reusePromise()
  async findMe(options = {}) {
    options = _.defaults(options, { ignoreNotFound: false });
    this.host.requireUser();

    let data = await this.host.api.users.findMe();

    if (data) {
      if (data.entity) {
        data.entity['organization_token'] = data['organization_id'];
        data = data.entity;
      }

      const { metadata, organization_token: organizationId } = data;
      this._defaultOrganizationId = organizationId;
      const user = this.host.models.User.inject(data);
      if (metadata) this.host.metadata.__injectMetadata(user.id, organizationId, metadata);
      return user;
    } else if (!options.ignoreNotFound) {
      throw new errors.NotFoundError(this.host.models.User.name, 'me');
    }
  }

  @reusePromise()
  async findMeByOrganizationId({ organizationId }: {}) {
    this.host.requireUser();

    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    return await this.host.api.users.findMe({ organizationId });
  }

  async findDefaultOrganizationId() {
    if (!this._defaultOrganizationId) {
      await this.findMe();
    }
    return this._defaultOrganizationId;
  }

  // TODO test
  @reusePromise()
  async findMyProfilesForAllOrganizations(options = {}) {
    const organizationIds = (await this.host.organizations.findAll()).map((o) => o.id);
    return this.findProfileForOrganizations(this.host.currentUserId, organizationIds);
  }

  /**
   * Finds a user
   */
  @reusePromise()
  async find(
    id: string,
    options:
      | {
          organizationId?: string | null | undefined;
          organizationIds?: string[] | null | undefined;
        }
      | null
      | undefined = {}
  ) {
    options = _.defaults(options, { bypassCache: false, ignoreNotFound: false });
    this.host.requireUser();

    let user = this.host.models.User.get(id);

    let organizationIds;
    let callerSuppliedOrganizationIds = true;
    if (options.organizationIds) {
      organizationIds = options.organizationIds;
    } else if (options.organizationId) {
      organizationIds = [options.organizationId];
    } else {
      callerSuppliedOrganizationIds = false;
      organizationIds = user ? [...user.organizationIds] : [];
      const contactsOrgId = await this.findDefaultOrganizationId();
      if (!organizationIds.includes(contactsOrgId)) organizationIds.push(contactsOrgId);
    }

    const singleCallOptions = {
      ...options,
      ignoreNotFound: true,
    };

    const cpr = this.host.conversations._conversationsPendingCounterPartyReload[id];
    if (cpr) {
      for (const organizationId of Object.keys(cpr)) {
        if (!organizationIds.includes(organizationId)) {
          organizationIds.push(organizationId);
        }
      }
    }

    const promises = [];
    for (const organizationId of organizationIds) {
      promises.push(this.__find(id, organizationId, singleCallOptions));
    }

    const responses = await Promise.all(promises);
    const data = responses.map(({ data }) => data).find(Boolean);
    const fetchedAny = !!responses.find(({ cached }) => !cached);
    if (data && data.id) user = this.host.models.User.get(data.id);
    if (data && id !== data.id && !callerSuppliedOrganizationIds) {
      // if id was an email address or phone number, there might be other organizations we know about that
      // haven't been fetched yet, so go get these now that we have the real id from Contacts org
      const unfetchedOrganizationIds = _.difference(user.organizationIds, organizationIds);
      if (unfetchedOrganizationIds.length > 0) {
        await Promise.all(
          unfetchedOrganizationIds.map((organizationId) =>
            this.__find(id, organizationId, singleCallOptions)
          )
        );
      }
    }

    this.host.models.User.removePlaceholder({ entity: user, attrs: data });

    if (fetchedAny && !options.ignoreNotFound && !data) {
      throw new errors.NotFoundError(this.host.models.User.name, id);
    }

    return user;
  }

  @reusePromise()
  async __find(id: string, organizationId: string, options = {}) {
    options = _.defaults(options, { bypassCache: false, ignoreNotFound: false });
    const isExtendedAfOptionsEnabled = this.config.extendAutoForwardOptions;
    // local first
    let user = this.host.models.User.get(id);

    if (!options.bypassCache) {
      if (
        user &&
        !user.$placeholder &&
        user.profileByOrganizationId &&
        user.profileByOrganizationId[organizationId]
      ) {
        await this.host.metadata.find(id, organizationId, options);
        this.host.models.User.touch(id);
        return { cached: true, user };
      }
    }

    let data = await this.host.api.users.find(id, { organizationId });

    if (data) {
      if (data.entity) {
        data.entity['organization_token'] = data['organization_id'];
        data.entity['conversation_id'] = data['conversation_id'];
        data = data.entity;
      }

      if (data.token) {
        data.id = data.token;
        delete data.token;
      }

      const {
        dnd_auto_forward_receivers: autoForwardUsers = [],
        dnd_auto_forward_entities: extendAutoForwardUsers = [],
        metadata,
      } = data;
      if (isExtendedAfOptionsEnabled) {
        extendAutoForwardUsers.forEach((autoUser) =>
          this.host.models.User.injectPlaceholder(autoUser)
        );
        data['dnd_auto_forward_entities'] = extendAutoForwardUsers;
      } else {
        autoForwardUsers.forEach((autoUser) => this.host.models.User.injectPlaceholder(autoUser));
        data['dnd_auto_forward_receivers'] = autoForwardUsers;
      }
      user = this.host.models.User.inject(data);
      this.host.models.User.removePlaceholder({ entity: user, attrs: data });

      if (metadata) {
        if (metadata['feature_service'] === 'role') {
          this.host.roles.__injectSearchResult({ entity: user, metadata, organizationId });
        } else {
          this.host.metadata.__injectMetadata(user.id, organizationId, metadata);
        }
      }

      this.host.conversations._reloadConversationsPendingCounterPartyReload(
        user.id,
        organizationId
      );
    }

    if (!options.ignoreNotFound && !data) {
      throw new errors.NotFoundError(this.host.models.User.name, id);
    }

    return { cached: false, data, user };
  }

  async findProfileForOrganizations(
    id: string,
    organizationIds: string[] | Object[],
    options = {}
  ) {
    options = _.defaults(options, { bypassCache: false, ignoreNotFound: false });

    const user = await this.find(id, { ...options, organizationIds });

    return user ? user.profileByOrganizationId : user;
  }

  async findProfileForOrganization(id: string, organizationId: string | Object, options = {}) {
    options = _.defaults(options, { bypassCache: false, ignoreNotFound: true });
    if (typeof organizationId !== 'string') organizationId = organizationId.id;

    const user = await this.find(id, { ...options, organizationId });
    const profile =
      user && user.profileByOrganizationId && user.profileByOrganizationId[organizationId];

    return profile || null;
  }

  async ensureUsers(ids: Array<string | Object>, organizationId: string | Object) {
    this.host.requireUser();
    if (typeof organizationId !== 'string') organizationId = organizationId.id;

    const promises = [];
    for (const id of ids) {
      promises.push(this.find(id, { organizationId, ignoreNotFound: true }));
    }

    const users = await Promise.all(promises);
    return users;
  }

  @reusePromise()
  async refresh(id) {
    const { title, department, displayName } = this.host.currentUser;
    const user = await this.find(id, { ignoreNotFound: true, bypassCache: true });
    if (
      title !== user.title ||
      department !== user.department ||
      displayName !== user.displayName
    ) {
      const data = {
        visitor: {
          'User Display Name': displayName,
          'User Department': department,
          'User Title': title,
        },
      };

      this.host.emit('change:account:data', data);
    }
    return user;
  }

  async update({
    avatarFile,
    displayName,
    dnd,
    dndText,
    dndExpireAt,
    firstName,
    incomingCallNotification,
    incomingCallSound,
    lastName,
    removeAvatar,
    status,
  }: {
    avatarFile?: Object | null | undefined;
    displayName?: string | null | undefined;
    dnd?: boolean | null | undefined;
    dndText?: string | null | undefined;
    dndExpireAt?: string | null | undefined;
    firstName?: string | null | undefined;
    incomingCallNotification?: boolean | null | undefined;
    incomingCallSound?: boolean | null | undefined;
    lastName?: string | null | undefined;
    removeAvatar?: boolean | null | undefined;
    status?: string | null | undefined;
  }) {
    this.host.requireUser();

    const data = _omitBy.call(
      {
        avatarFile,
        displayName,
        dnd,
        dndText,
        dndExpireAt,
        firstName,
        incomingCallNotification,
        incomingCallSound,
        lastName,
        removeAvatar,
        status,
      },
      (v, k) => typeof v === 'undefined'
    );

    const res = await this.host.api.users.update(this.host.currentUserId, data);

    // server doesn't return the new avatar URL. so reload the user.
    // await this.findMe({ bypassCache: true })

    const newAttrs = _.omit(data, 'removeAvatar', 'avatarFile');

    if (removeAvatar) newAttrs.avatarUrl = null;
    else if (typeof res.avatarUrl !== 'undefined') newAttrs.avatarUrl = res.avatarUrl;

    const user = this.host.models.User.inject({ id: this.host.currentUserId, ...newAttrs });

    return user;
  }

  async updatePassword(currentPassword: string, newPassword: string) {
    this.host.requireUser();
    const res = this.host.api.users.updatePassword(
      this.host.currentUserId,
      currentPassword,
      newPassword,
      this.config.productKey
    );

    return res;
  }

  async setAutoForward(organizationId: string, receiverId: string, entitiesIds?: string[]) {
    this.host.requireUser();
    const isExtendedAfOptionsEnabled = this.config.extendAutoForwardOptions;
    const { dnd } = this.host.currentUser;

    await this.host.api.users.setAutoForward(this.host.currentUserId, organizationId, {
      dnd,
      ...(isExtendedAfOptionsEnabled ? { entitiesIds } : { receiverId }),
    });

    return true;
  }

  async removeAutoForward(organizationId: string) {
    const isExtendedAfOptionsEnabled = this.config.extendAutoForwardOptions;
    this.host.requireUser();
    if (isExtendedAfOptionsEnabled) {
      await this.host.api.users.removeExtendedAutoForwardOptions(
        this.host.currentUserId,
        organizationId
      );
    } else {
      await this.host.api.users.removeAutoForward(this.host.currentUserId, organizationId);
    }
    return true;
  }

  async setEulaAccepted(organizationId: string, accept: boolean) {
    this.host.requireUser();
    const res = await this.host.api.users.setEulaAccepted(
      this.host.currentUserId,
      organizationId,
      accept
    );

    return res;
  }

  async setAwayMessage(
    organizationId: string,
    options: { dnd: boolean; dndText?: string | null | undefined } | null | undefined = {}
  ) {
    const { dnd, dndText = '' } = options;
    this.host.requireUser();
    await this.host.api.users.setAwayMessage(this.host.currentUserId, organizationId, {
      dnd,
      dndText,
    });
  }

  getSignInAuthHeader(userId: string, password: string) {
    return this.host.api.users.getSignInAuthHeader(this.config.productKey, userId, password);
  }

  getAll() {
    return this.host.models.User.getAll();
  }
  getById(id: string) {
    return this.host.models.User.get(id);
  }
  getMultiById(ids: Array<string>) {
    return this.host.models.User.getMulti(ids);
  }

  _setMembersOnEntity = ({
    entity,
    fromField = 'memberIds',
    toField = 'members',
    placeholder = false,
  }: {
    entity: Object;
    fromField?: 'alertRecipientIds' | 'autoForwardReceiverIds' | 'memberIds';
    toField?: 'alertRecipientIds' | 'autoForwardReceiverIds' | 'memberIds';
    placeholder?: boolean;
  }) => {
    let { [fromField]: memberIds, [toField]: members } = entity;

    if (memberIds) {
      members = [];
      for (const memberId of memberIds) {
        const user = this.host.models.User.get(memberId);
        if (user) {
          members.push(user);
        } else if (placeholder) {
          members.push(this.host.models.User.injectPlaceholder({ id: memberId }));
        }
      }
    } else {
      members = null;
    }

    entity[toField] = members;
  };

  _injectPatientEntity = (patient, userId, organizationId) => {
    const patientId = `patient:${userId}`;

    patient.id = patientId;
    patient.organizationId = organizationId;
    patient.userId = userId;
    this.host.models.Patient.inject(patient);

    if (patient.patient_contacts) {
      for (const contact of patient.patient_contacts) {
        const user = contact.entity ? contact.entity : contact;
        const userId = user.token || user.id;

        user.id = userId;
        user.patientId = patientId;

        this.host.models.User.inject(user);
      }
    }
  };

  _injectPatientContactEntity = (patientContact, organizationId) => {
    if (patientContact.entity) {
      patientContact = patientContact.entity;
    }

    const patientAttrs = patientContact.metadata;
    const patientContactAttrs = {
      editVersion: patientAttrs.edit_version,
      id: `patientContact:${patientContact.id}`,
      organizationId,
      patientDob: patientAttrs.patient_dob,
      patientGender: patientAttrs.patient_gender,
      patientId: `patient:${patientAttrs.patient_id}`,
      patientMrn: patientAttrs.patient_mrn,
      phoneNumber: patientAttrs.phone_number,
      relationName: patientAttrs.relation_name,
      smsOptedOut: patientAttrs.sms_opted_out,
      userId: patientContact.id,
    };

    this.host.models.PatientContact.inject(patientContactAttrs);
  };

  _onChangeMetadata = (resource, metadata) => {
    const { entityId } = metadata;
    const user = this.host.models.User.get(entityId);
    if (!user) return;

    this.host.models.User.inject(user);
  };

  _onChangeRole = (resource, role) => {
    const { currentUserId } = this.host;
    let { botUserId, memberIds } = role;
    if (!memberIds) memberIds = [];
    if (botUserId) {
      this.host.models.User.injectPlaceholder({ id: botUserId, botRole: role });
    }

    const toInject = {};
    const lastRoleMemberIds = this._roleMemberIds[role.id];

    if (lastRoleMemberIds) {
      for (const memberId of lastRoleMemberIds) {
        const user = this.host.models.User.get(memberId);
        if (user && !memberIds.includes(memberId)) {
          const { roles } = user;
          const idx = roles.indexOf(role);
          if (idx > -1) {
            roles.splice(idx, 1);
            toInject[memberId] = roles;
          }
        }
      }
    }

    this._roleMemberIds[role.id] = memberIds;

    for (const memberId of memberIds) {
      const user = this.host.models.User.get(memberId);
      const roles = user ? user.roles : [];

      if (!roles.includes(role)) {
        roles.push(role);
        toInject[memberId] = roles;
      }
    }

    for (const [id, roles] of Object.entries(toInject)) {
      this.host.models.User.injectPlaceholder({ id, roles });
      if (id === currentUserId) {
        this._onChangeCurrentUserRole(role);
      }
    }
  };

  _onChangeCurrentUserRole(role) {
    const { botUser } = role;
    if (!botUser) return;

    const roleConversations = this.host.roles._roleConversations[botUser.id];
    if (!roleConversations) return;

    for (const conversation of roleConversations) {
      let changedAnyStatus = false;

      for (const message of conversation.content) {
        message.shouldEnsureRecipientStatus = true;
        this.host.models.Message.inject(message);
        changedAnyStatus = true;
      }

      if (!changedAnyStatus) {
        this.host.models.Conversation.inject(conversation);
      }

      if (
        conversation.featureService === 'group_alerts' &&
        conversation?.counterParty?.alertMessageId
      ) {
        this.host.models.Message.touch({ id: conversation.counterParty.alertMessageId });
      }
    }
  }

  _onRemoveRole = (resource, role) => {
    const { botUserId } = role;
    if (botUserId) {
      this.host.models.User.injectPlaceholder({ id: botUserId, botRole: null });
    }

    const lastRoleMemberIds = this._roleMemberIds[role.id];

    if (lastRoleMemberIds) {
      for (const memberId of lastRoleMemberIds) {
        const user = this.host.models.User.get(memberId);
        if (user) {
          const { roles } = user;
          const idx = roles.indexOf(role);
          if (idx > -1) {
            roles.splice(idx, 1);
            this.host.models.User.inject(user);
          }
        }
      }

      delete this._roleMemberIds[role.id];
    }
  };

  getPreferences = async (userId = this.host.currentUserId) => {
    if (!userId) throw new errors.ValidationError('userId', 'required');

    return await this.host.api.users.getPreferences(userId);
  };

  editPreferences = async ({
    quickReplies = undefined,
    dndTextTemplates = undefined,
    organizationId,
    userId = this.host.currentUserId,
  }) => {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!userId) throw new errors.ValidationError('userId', 'required');

    return await this.host.api.users.editPreferences({
      organizationId,
      quickReplies,
      dndTextTemplates,
      userId,
    });
  };

  reactToUserPreferencesEvent(event) {
    this.emit('preferences:change', Camelizer.camelizeObject(event));
  }

  @reusePromise()
  async adminFindUsersToAddToForum(organizationId, { query = '' }) {
    const { results } = await this.host.search.query({
      version: this.config.allowSearchParity ? 'SEARCH_PARITY' : 'LEGACY',
      types: this.config.allowSearchParity ? [SearchType.INDIVIDUAL] : [SearchType.USER],
      organizationId,
      query: { name: query },
      excludeRoles: true,
    });

    const users = results.filter((entity) => entity.entityType !== 'role');

    return users;
  }
}

// @ts-nocheck
import _ from 'lodash';
import PresenceStatus from './enums/PresenceStatus';
import { cloudFrontUrlTransform } from './extensions/cloudFrontTransform';
import addEnsureEntity from './extensions/ensureEntity';

export const PROFILE_ATTRIBUTES_PER_ORG = [
  'autoForwardReceiverIds',
  'conversationId',
  'department',
  'dndAutoForwardEntities',
  'title',
];

export default function (store, { host }) {
  const User = store.defineModel('user', {
    replaceKeys: {
      avatar: 'avatarUrl',
      blockedPersonal: 'contactsMessagingBlockedByOrganizationIds',
      organizationKey: 'organizationId',
      organizationToken: 'organizationId',
      timeToLive: 'messagesTTL',
    },

    defaultValues: {
      botRole: null,
      contactsMessagingBlockedByOrganizationIds: [],
      isPatient: false,
      isPatientContact: false,
      organizationIds: [],
      presenceStatus: PresenceStatus.UNKNOWN,
      profileByOrganizationId: {},
      removedFromOrg: false,
      mostRecentOrgStatusBangSortNumber: 0,
      roles: [],
    },

    skipCamelizationForKeys: ['metadata'],

    parseAttrs(attrs) {
      const organizationId = attrs.organizationId;

      if (Array.isArray(attrs.dndAutoForwardEntities) && attrs.dndAutoForwardEntities.length > 0) {
        attrs.dndAutoForwardEntities = attrs.dndAutoForwardEntities.map((entity) => entity);
      } else if (
        attrs.dndAutoForwardEntities === '' ||
        attrs.dndAutoForwardEntities === undefined ||
        attrs.dndAutoForwardEntities === null ||
        !Array.isArray(attrs.dndAutoForwardEntities)
      ) {
        attrs.dndAutoForwardEntities = [];
      }

      if (attrs.dndAutoForwardReceivers) {
        attrs.autoForwardReceiverIds = attrs.dndAutoForwardReceivers.map(({ token }) => token);
      } else if (
        attrs.dndAutoForwardReceiver === '' ||
        attrs.dndAutoForwardReceiver === 'undefined' ||
        attrs.dndAutoForwardReceiver === null
      ) {
        attrs.autoForwardReceiverIds = [];
      } else if (attrs.dndAutoForwardReceiver !== undefined) {
        attrs.autoForwardReceiverIds = [attrs.dndAutoForwardReceiver];
      }
      delete attrs.dndAutoForwardReceiver;
      delete attrs.dndAutoForwardReceivers;

      if (organizationId) {
        delete attrs.organizationId;
        if (!attrs.organizationIds) attrs.organizationIds = [];
        if (!attrs.organizationIds.includes(organizationId)) {
          attrs.organizationIds.push(organizationId);
        }
        const profileAttrs = {
          ..._.pick(attrs, PROFILE_ATTRIBUTES_PER_ORG),
          userId: attrs.id,
          organizationId,
        };
        profileAttrs.dndAutoForwardEntities = attrs.dndAutoForwardEntities;
        delete attrs.dndAutoForwardEntities;
        delete attrs.autoForwardReceiverIds;
        delete attrs.conversationId;

        if (!attrs.profileByOrganizationId) attrs.profileByOrganizationId = {};
        attrs.profileByOrganizationId[organizationId] = profileAttrs;
      }

      if ('patientId' in attrs && !attrs.patientId.startsWith('patient:')) {
        const patientId = `patient:${attrs.patientId}`;
        const patientUserId = attrs.patientId;
        attrs.isPatient = !attrs.isPatientContact;
        attrs.patientId = patientId;

        attrs.patientBroadcastListDetails = {
          addedByDisplayName: attrs.addedByDisplayName,
          addedOn: attrs.addedOn,
        };

        const patientData = {
          id: patientId,
          dob: attrs.patientDob,
          gender: attrs.patientGender,
          mrn: attrs.patientMrn,
        };

        if (attrs.isPatient) {
          patientData.userId = attrs.id;
        } else if (attrs.isPatientContact) {
          const patientContactId = `patientContact:${attrs.id}`;
          attrs.patientContactId = patientContactId;

          host.models.User.injectPlaceholder({
            id: patientUserId,
            displayName: attrs.patientDisplayName,
            patientId,
          });

          host.models.PatientContact.injectPlaceholder({
            id: patientContactId,
            relation: attrs.relationName,
            userId: attrs.id,
            patientId,
          });

          patientData.userId = patientUserId;
        }

        host.models.Patient.injectPlaceholder(patientData);

        delete attrs.addedByDisplayName;
        delete attrs.addedOn;
        delete attrs.patientDisplayName;
        delete attrs.patientDob;
        delete attrs.patientGender;
        delete attrs.patientMrn;
        delete attrs.relationName;
      }

      if (attrs.presenceStatus) {
        attrs.presenceStatus = PresenceStatus.resolve(attrs.presenceStatus);
      }

      if ('metadata' in attrs) {
        if (
          'feature_service' in attrs.metadata &&
          attrs.metadata.feature_service === 'patient_messaging'
        ) {
          const patientData = attrs.metadata;
          const {
            is_patient_contact: isPatientContact,
            patient_contact_name: patientContactName,
            patient_name: patientName,
          } = patientData;

          if (isPatientContact) {
            attrs.patientContactId = `patientContact:${attrs.id}`;
            attrs.isPatientContact = true;

            if (!attrs.displayName && patientContactName) {
              attrs.displayName = patientContactName;
            }

            host.users._injectPatientContactEntity(attrs, organizationId);
          } else {
            attrs.patientId = `patient:${attrs.id}`;
            attrs.isPatient = true;

            if (!attrs.displayName && patientName) {
              attrs.displayName = patientName;
            }

            host.users._injectPatientEntity(patientData, attrs.id, organizationId);
          }
        }
        if (
          'feature_service' in attrs.metadata &&
          attrs.metadata.feature_service === 'role' &&
          organizationId
        ) {
          host.roles.__injectRoleFromMetadata({
            metadata: {
              ...attrs.metadata,
              display_name: attrs.display_name,
              token: attrs.id,
            },
            organizationId,
          });
        }
        delete attrs['metadata'];
      }

      if (attrs.avatarUrl) {
        attrs.avatarUrl = cloudFrontUrlTransform(attrs.avatarUrl, host.config);
      }

      if (!attrs.displayName && attrs.name) {
        attrs.displayName = attrs.name;
      }

      if (attrs.dnd !== undefined) {
        attrs.dnd = [true, 'true'].includes(attrs.dnd);
      }

      return attrs;
    },

    beforeInject(attrs, existingEntity) {
      if (existingEntity) {
        if (attrs.organizationIds) {
          attrs.organizationIds = _.union(existingEntity.organizationIds, attrs.organizationIds);
        }
        if (attrs.profileByOrganizationId) {
          const newProfiles = {};
          if (existingEntity.profileByOrganizationId) {
            for (const [key, entry] of Object.entries(existingEntity.profileByOrganizationId)) {
              newProfiles[key] = {};
              Object.assign(newProfiles[key], entry);
            }
          }
          for (const [key, entry] of Object.entries(attrs.profileByOrganizationId)) {
            if (!newProfiles[key]) newProfiles[key] = {};
            Object.assign(newProfiles[key], entry);
          }
          attrs.profileByOrganizationId = newProfiles;
        }
      }
    },

    afterAssignment(entity) {
      const { profileByOrganizationId } = entity;

      entity.contactsMessagingBlocked = entity.contactsMessagingBlockedByOrganizationIds.length > 0;
      entity.isAlertsUser = entity.metadata.some(
        ({ data }) => data['feature_service'] === 'alerts'
      );
      entity.dndAutoForwardEntities = entity.dndAutoForwardEntities || [];
      entity.isWorkflowBot = entity.metadata.some(({ data }) => data['feature_service'] === 'cph');
      entity.isRoleBot = entity.metadata.some(({ data }) => data['feature_service'] === 'role');
      entity.isVisitor = entity.metadata.some(({ data }) => data['feature_service'] === 'vwr');
      entity.shouldDisplay = !entity.metadata.some(({ data }) => data['invisible'] === 'true');

      for (const profile of Object.values(profileByOrganizationId)) {
        if (!entity.dnd) {
          profile.autoForwardReceiverIds = [];
          profile.dndAutoForwardEntities = [];
        }

        host.users._setMembersOnEntity({
          entity: profile,
          fromField: 'autoForwardReceiverIds',
          toField: 'autoForwardReceivers',
          placeholder: true,
        });
      }

      if (
        entity.removedFromOrg &&
        (!entity.displayName || entity.$$loading || entity.$$loadingScheduled || entity.$notFound)
      ) {
        entity.displayName = 'Removed User';
      }
    },

    relations: {
      belongsTo: {
        patient: { type: 'patient', foreignKey: 'patientId' },
        patientContact: { type: 'patientContact', foreignKey: 'patientContactId' },
      },
      hasMany: {
        metadata: {
          type: 'metadata',
          foreignKey: 'entityId',
        },
      },
    },
  });

  addEnsureEntity(User, {
    finder: (...args) => host.users.find(...args),
    placeholderEntityDelayBeforeRefreshing() {
      return host.config.placeholderEntityDelayBeforeRefreshing;
    },
    placeholderEntityAllowLoading() {
      return host.config.placeholderEntityAllowLoading;
    },

    defaultPlaceholderAttrs: { displayName: 'Loading...' },
    defaultNotFoundAttrs: { displayName: '<User Not Found>' },
  });

  return User;
}

import { debounce } from 'lodash';
import uuid from 'uuid';
import BaseService from './BaseService';

export const ReportLevels = {
  DEBUG: 'debug',
  ERROR: 'error',
  INFO: 'info',
  WARN: 'warn',
} as const;

export type ReportLevel = typeof ReportLevels[keyof typeof ReportLevels];

export type SendReportType = {
  correlationId?: string;
  desktopAppVersion?: string;
  desktopClientType?: string;
  flushImmediately?: boolean;
  isEpicLaunchRequest?: boolean;
  level?: ReportLevel;
  message: string;
  organizationId?: string;
  payload: Record<string, unknown>;
};

export const REPORT_BATCH_COUNT = 100;
const REPORT_DEBOUNCE_TIME = 30000;

const VALID_PRODUCTS = ['wrut', 'pfma'];

export default class ReportService extends BaseService {
  __customFields: Record<string, string>;
  __firstCorrelationId: string;
  __reportsToSend: Record<string, unknown>[];

  constructor(host: Record<string, unknown>, options: Record<string, string>) {
    super(host, options);

    this.__customFields = {
      sessionId: uuid.v4(),
    };
    this.__firstCorrelationId = uuid.v4();
    this.__reportsToSend = [];
  }

  __getCustomParams() {
    const importantConfigKeys = ['apiEnv', 'baseUrl', 'condensedReplays', 'product', 'version'];

    return importantConfigKeys.reduce(
      (importantConfig, key) => {
        importantConfig[key] = this.host.config[key];
        return importantConfig;
      },
      {
        ...this.__customFields,
        currentUserId: this.host.currentUserId,
      } as Record<string, string>
    );
  }

  async __sendToService__() {
    if (this.__reportsToSend.length === 0) return;

    const firstReports = this.__reportsToSend.splice(0, REPORT_BATCH_COUNT);

    if (this.__reportsToSend.length) {
      firstReports.unshift({
        ...this.__getCustomParams(),
        amountTruncated: this.__reportsToSend.length,
        correlationId: this.__firstCorrelationId,
        message: 'truncated reports',
      });
      this.__reportsToSend.length = 0;
    }

    try {
      await this.host.api.report.send(firstReports);
    } catch (error) {
      firstReports.unshift({
        ...this.__getCustomParams(),
        correlationId: this.__firstCorrelationId,
        errorMessage: error.message,
        errorStatus: error.status,
        message: 'error sending reports',
      });
      this.__reportsToSend.unshift(...firstReports);
      this.__sendToService();
    }
  }

  __sendToService = debounce(this.__sendToService__, REPORT_DEBOUNCE_TIME);

  addCustomParams(params: Record<string, string>) {
    Object.assign(this.__customFields, params);
  }

  generateCorrelationId() {
    return uuid.v4();
  }

  send({
    correlationId = this.__firstCorrelationId,
    desktopAppVersion,
    desktopClientType,
    flushImmediately = false,
    isEpicLaunchRequest = false,
    level = ReportLevels.DEBUG,
    message,
    organizationId,
    payload,
  }: SendReportType) {
    if (!VALID_PRODUCTS.includes(this.host.config.product)) {
      throw new Error('Invalid config');
    }

    const report = {
      ...payload,
      ...this.__getCustomParams(),
      correlationId,
      desktopAppVersion,
      desktopClientType,
      isEpicLaunchRequest,
      level,
      message,
      organizationId,
      representativeOrgId: this.host.config.representativeOrgId,
      ...(this.host._auth && this.host._auth.resource && { resource: this.host._auth.resource }),
      ts: new Date().toISOString(),
    };

    this.__reportsToSend.push(report);

    if (!__DEV__ || this.config.shouldSendReports) {
      this.__sendToService();

      if (flushImmediately) {
        this.__sendToService.flush();
      }
    }
    return;
  }
}

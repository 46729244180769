import EventEmitter from 'events';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowequal from 'shallowequal';
import isEmpty from 'lodash/isEmpty';

import { ALL_SEARCH_TYPES } from '../../models/enums/SearchTypes';
import { RecipientPicker } from '../components/';
import propTypes from '../propTypes';
import { FROZEN_EMPTY_ARRAY, mobxInjectSelect } from '../utils';
import { filterSearchResults } from '../utils/searchResults';

class RecipientSearchPicker extends Component {
  static propTypes = {
    ariaLabelItem: PropTypes.string,
    ariaLabelSelect: PropTypes.string,
    arrowRenderer: PropTypes.func,
    centeredOuterMenu: PropTypes.bool,
    className: PropTypes.string,
    currentUserId: PropTypes.string.isRequired,
    events: PropTypes.instanceOf(EventEmitter).isRequired,
    excludeBroadcastsWhenSenderIsRole: PropTypes.bool,
    excludeIds: PropTypes.arrayOf(propTypes.idType),
    excludeIntraTeams: PropTypes.bool,
    excludeMyRoles: PropTypes.bool,
    excludeRoles: PropTypes.bool,
    excludeSelf: PropTypes.bool,
    excludeTeams: PropTypes.bool,
    hotkeyOptions: PropTypes.shape({
      component: PropTypes.string,
      isFlex: PropTypes.bool,
    }),
    isAutoSized: PropTypes.bool,
    isOpenOnFocus: PropTypes.bool,
    isSearching: PropTypes.bool.isRequired,
    isSingleRecipient: PropTypes.bool,
    multi: PropTypes.bool,
    noResults: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onTab: PropTypes.func,
    openPlaceholder: PropTypes.string,
    organization: propTypes.organization,
    placeholder: PropTypes.string,
    reposition: PropTypes.bool,
    selectedPatientReferenceId: PropTypes.string,
    search: PropTypes.func.isRequired,
    searchResults: propTypes.searchResultArray.isRequired,
    searchTypes: PropTypes.arrayOf(PropTypes.oneOf(ALL_SEARCH_TYPES).isRequired),
    selected: PropTypes.oneOfType([propTypes.counterPartyArray, PropTypes.object]),
    sender: propTypes.counterParty,
    shouldAutoBlur: PropTypes.bool,
    shouldShowSelectedValues: PropTypes.bool,
    showCloseButton: PropTypes.bool,
    showLoadingSpinner: PropTypes.bool,
    stopCurrentAction: PropTypes.func.isRequired,
    tabIndex: PropTypes.number,
    userRolesInOrg: PropTypes.arrayOf(PropTypes.string),
    openOnType: PropTypes.bool,
    isEntityInConversationCheck: PropTypes.bool,
  };

  static defaultProps = {
    centeredOuterMenu: false,
    excludeBroadcastsWhenSenderIsRole: false,
    excludeIds: FROZEN_EMPTY_ARRAY,
    excludeIntraTeams: false,
    excludeMyRoles: false,
    excludeRoles: false,
    excludeSelf: true,
    excludeTeams: false,
    isAutoSized: false,
    isOpenOnFocus: true,
    multi: false,
    searchTypes: ALL_SEARCH_TYPES,
    shouldShowSelectedValues: true,
    shouldAutoBlur: false,
  };

  _lastSelected = null;
  _lastSender = null;

  get selected() {
    const { selected } = this.props;
    if (!isEmpty(selected)) return selected;
    if (this.props.multi) {
      return FROZEN_EMPTY_ARRAY;
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps) {
    const {
      excludeBroadcastsWhenSenderIsRole,
      excludeIds,
      excludeIntraTeams,
      excludeMyRoles,
      excludeRoles,
      excludeTeams,
      multi,
      myRoleIds,
      onChange,
      selectedPatientReferenceId,
      organization: { id: organizationId },
      sender,
      searchTypes,
    } = this.props;
    let selected = this.selected;
    if (!multi) {
      selected = selected ? [selected] : [];
    }
    selected = selected.slice();

    if (selectedPatientReferenceId !== prevProps.selectedPatientReferenceId) {
      this._search('');
    }

    if (organizationId !== prevProps.organization.id) {
      onChange(multi ? selected || [] : null);
    } else if (
      sender !== this._lastSender ||
      !shallowequal(excludeIds, prevProps.excludeIds) ||
      !shallowequal(selected, this._lastSelected)
    ) {
      const excludeBroadcasts = excludeBroadcastsWhenSenderIsRole && !!sender?.isRoleBot;
      const rolesToExclude = excludeMyRoles && selected.length === 1 ? myRoleIds : [];

      if (
        excludeMyRoles &&
        sender &&
        sender.isRoleBot &&
        sender.botRoleId &&
        selected.length === 1 &&
        sender.botRole &&
        sender.botRole.members.length === 1 &&
        sender.botRole.members[0].id === selected[0].id
      ) {
        rolesToExclude.push(sender.botRole.members[0].id);
      }

      const newSelected = selected.filter(
        filterSearchResults([...excludeIds, ...rolesToExclude], {
          excludeBroadcasts,
          excludeIntraTeams,
          excludeRoles,
          excludeTeams,
        })
      );

      if (!shallowequal(selected, newSelected)) {
        onChange(newSelected);
      }
    }

    if (
      !shallowequal(prevProps.searchTypes, searchTypes) ||
      !shallowequal(excludeIds, prevProps.excludeIds) ||
      organizationId !== prevProps.organization.id
    ) {
      this._search('');
    }

    this._lastSelected = selected;
    this._lastSender = sender;
  }

  render() {
    const {
      ariaLabelItem,
      ariaLabelSelect,
      arrowRenderer,
      autoFocus,
      centeredOuterMenu,
      className,
      events,
      excludeIds,
      hotkeyOptions,
      isAutoSized,
      isOpenOnFocus,
      isSearching,
      isSingleRecipient,
      input,
      multi,
      noResults,
      onBlur,
      onChange,
      onFocus,
      onTab,
      openPlaceholder,
      openOnType,
      organization: { id: organizationId },
      placeholder,
      reposition,
      searchTypes,
      searchResults,
      searchErrorOccurred,
      shouldAutoBlur,
      shouldShowSelectedValues,
      showCloseButton,
      showLoadingSpinner,
      stopCurrentAction,
      tabIndex,
      isEntityInConversationCheck,
    } = this.props;
    const selected = this.selected;

    return (
      <RecipientPicker
        {...{
          ariaLabelItem,
          ariaLabelSelect,
          arrowRenderer,
          autoFocus,
          centeredOuterMenu,
          className,
          events,
          excludeIds,
          isAutoSized,
          isOpenOnFocus,
          isSearching,
          isSingleRecipient,
          input,
          multi,
          noResults,
          onBlur,
          onChange,
          onFocus,
          onTab,
          openPlaceholder,
          openOnType,
          organizationId,
          placeholder,
          reposition,
          searchResults,
          searchTypes,
          selected,
          showCloseButton,
          showLoadingSpinner,
          stopCurrentAction,
          tabIndex,
          hotkeyOptions,
          shouldShowSelectedValues,
          shouldAutoBlur,
          searchErrorOccurred,
          isEntityInConversationCheck,
        }}
        onSearch={this._search}
        ref={this._setPicker}
      />
    );
  }

  _search = (text) => {
    const {
      currentUserId,
      enabledCapabilities,
      excludeBroadcastsWhenSenderIsRole,
      excludeIds,
      excludeIntraTeams,
      excludeMyRoles,
      excludeRoles,
      excludeTeams,
      organization,
      search,
      searchTypes,
      selected,
      selectedPatientReferenceId,
      sender,
      userRolesInOrg,
    } = this.props;
    let { excludeSelf } = this.props;

    if (sender && selected.length > 0 && sender.id !== currentUserId) {
      excludeSelf = false;
    }

    return search({
      enabledCapabilities,
      excludeBroadcastsWhenSenderIsRole,
      excludeIds,
      excludeIntraTeams,
      excludeMyRoles,
      excludeRoles,
      excludeSelf,
      excludeTeams,
      organization,
      searchTypes,
      selected,
      selectedPatientReferenceId,
      sender,
      text,
      userRolesInOrg,
    });
  };

  _setPicker = (ref) => {
    this._picker = ref;
  };

  _isOpen = () => {
    return this._picker ? this._picker.isOpen() : false;
  };
}

export default mobxInjectSelect({
  messengerStore: ['stopCurrentAction'],
  recipientPickerStore: [
    'events',
    'isSearching',
    'searchErrorOccurred',
    'search',
    'searchResults',
    'noResults',
    'input',
  ],
  roleStore: ['myRoleIds'],
  sessionStore: ['currentUserId'],
})(RecipientSearchPicker);
